import { ReactNode } from 'react'
import { TooltipProps } from '@chakra-ui/react'
import { IProjectNameResponse } from 'API/project/constants'
import { capitalize } from 'lodash'
import { ILeaveRequestByProjects } from 'types/leaveRequest'
import { IOption } from 'constants/common'
import { EUserPartnerCategory, EWorkingHourPeriods } from 'constants/enum'
import { ETooltipType } from './enums'
export interface ICalendarFilterFormData {
  period: IOption<EWorkingHourPeriods | 'month-to-date'>
  date: Date
  selectedProject?: IOption<string>[]
}

export interface ICalendarFilterFormProps {
  projectNameList: IProjectNameResponse[]
  viewCalendarHandler?: (data: ICalendarFilterFormData) => void
  isLoading?: boolean
  setIsLoading?: (isLoading: boolean) => void
}
export interface ILeaveCalendarProps {
  period: IPeriod
  selectedDate: Date
  isSupervisor?: boolean
  leaveRequestsByProjects: ILeaveRequestByProjects[]
}
export interface IPeriod {
  value: EWorkingHourPeriods
}

export interface IUserLeaveInfo {
  id: number
  title: string
  remainingDays: number
  partnerCategory: EUserPartnerCategory
  totalLeaveRequests?: number
}

export interface ITooltipContent {
  name?: string
  title?: string
  type?: string
  status?: string
  approvalAuthority?: string
  remainingDays?: number
  partnerCategory?: EUserPartnerCategory
  totalLeaveRequests?: number
}

export interface ICustomTooltipProps extends Partial<TooltipProps> {
  content: ITooltipContent
  children: ReactNode
  tooltipType?: ETooltipType
}

export const workingPeriodOptions: IOption<EWorkingHourPeriods | 'month-to-date'>[] = [
  { label: capitalize(EWorkingHourPeriods.MONTH) as EWorkingHourPeriods, value: EWorkingHourPeriods.MONTH },
  { label: capitalize(EWorkingHourPeriods.WEEK) as EWorkingHourPeriods, value: EWorkingHourPeriods.WEEK },
]
