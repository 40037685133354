import { useState, useEffect } from 'react'
import { Container, useMediaQuery, VStack } from '@chakra-ui/react'
import { maxTabletSize } from 'constants/common'
import { ERoleOfUser } from 'constants/enum'
import { handleScrollLogic } from 'utils/scrollUtils'
import MenuItem from './MenuItem'
import { EItemLink, EItemText } from './MenuItem/constants'

function MenuDetail() {
  const [activeSection, setActiveSection] = useState<string>(EItemLink.USER_PROFILE_DETAIL)
  const [isMobile] = useMediaQuery(maxTabletSize)
  const [isManualClick, setIsManualClick] = useState(false)
  const [targetSection, setTargetSection] = useState<string | null>(null)
  const isAdmin = window.localStorage.getItem('userRole') === ERoleOfUser.ADMIN

  const menuItems = Object.entries(EItemLink).filter(([key]) => {
    if (isAdmin) {
      return true
    }
    return key === 'USER_PROFILE_DETAIL' || key === 'SKILL_ANALYSIS'
  })

  useEffect(() => {
    function throttledScroll() {
      if (!isManualClick) {
        const lastItemHref = menuItems[menuItems.length - 1][1]
        handleScrollLogic(isManualClick, targetSection, activeSection, setActiveSection, lastItemHref)
      }
    }

    window.addEventListener('scroll', throttledScroll)

    return function cleanup() {
      window.removeEventListener('scroll', throttledScroll)
    }
  }, [isManualClick, targetSection, activeSection])

  function handleLinkClick(text: string, href: string, e: React.MouseEvent) {
    e.preventDefault()

    const targetElement = document.getElementById(href.slice(1))
    if (!targetElement) {
      return
    }

    setIsManualClick(true)
    setTargetSection(href)
    setActiveSection(href)

    const navbarHeight = 90
    const additionalOffset = 12
    const elementTop = targetElement.offsetTop - navbarHeight - additionalOffset

    window.scrollTo({
      top: elementTop,
      behavior: 'smooth',
    })

    let lastKnownScrollPosition = window.scrollY
    function checkScrollEnd() {
      if (window.scrollY === lastKnownScrollPosition) {
        setIsManualClick(false)
        setTargetSection(null)
      } else {
        lastKnownScrollPosition = window.scrollY
        setTimeout(checkScrollEnd, 100)
      }
    }

    setTimeout(checkScrollEnd, 500)
  }

  if (isMobile) {
    return null
  }

  return (
    <Container background="white" width="full" maxWidth={{ base: 'unset', xl: '300px' }} padding={0} borderRadius={8}>
      <VStack backgroundColor="inherit" borderRadius="8px" padding="20px">
        {menuItems.map(([key, href]) => (
          <MenuItem
            key={href}
            href={href}
            text={EItemText[key]}
            isActive={activeSection === href}
            handleLinkClick={(e) => handleLinkClick(EItemText[key], href, e)}
          />
        ))}
      </VStack>
    </Container>
  )
}

export default MenuDetail
