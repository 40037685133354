/* eslint-disable max-lines */
import { useState, useEffect } from 'react'
import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  useMediaQuery,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Tooltip,
  Button,
} from '@chakra-ui/react'
import { getListCreatableOptionsAPI } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import { getPartnersAPI } from 'API/partner'
import { IPartnerListResponse } from 'API/partner/constants'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import Table from 'components/Table'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import dayjs from 'dayjs'
import { History } from 'history'
import capitalize from 'lodash/capitalize'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { FiDownload, FiFilter } from 'react-icons/fi'
import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { backgroundBlueSecondary, backgroundGrey200 } from 'theme/globalStyles'
import { ECreatableOptionScope, EDocumentStatusLabel } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import { IInternalDocument, IPartner } from 'constants/schema'
import { getArrayValueFromParsedQuery, getValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { getValueFromOption } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { actionOnDataTable, Messages } from '../../../constants'
import { maxMobileSize } from '../../../constants/common'
import FilterForm from '../FilterForm'
import { getHeaderList, IDocumentFilter, initialValues } from './constant'
import styles from './internalDocumentList.module.scss'

const InternalDocumentList = () => {
  const history: History = useHistory()
  const { adminInternalDocumentStore } = useStores()
  const { count, internalDocumentList, isLoadingInternalDocumentList } = adminInternalDocumentStore
  const [doctypes, setDoctypes] = useState([])
  const [partners, setPartners] = useState<IPartner[]>([])
  const [selectedInternalDocument, setSelectedInternalDocument] = useState<IInternalDocument | null>(null)
  const [isOpenFilterForm, setIsOpenFilterForm] = useState<boolean>(false)
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState<boolean>(false)
  const [exportNow, setExportNow] = useState<boolean>(false)
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)

  const URLSearchParams = history.location.search
  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })

  if (!URLSearchParams) {
    initialFilter.status = EDocumentStatusLabel.PUBLISHED
  }
  initialFilter.partnerId = getArrayValueFromParsedQuery(initialFilter, 'partnerId')
  initialFilter.typeId = getArrayValueFromParsedQuery(initialFilter, 'typeId')
  initialFilter.year && set(initialFilter, 'year', Number(initialFilter.year))
  initialFilter.month && set(initialFilter, 'month', Number(initialFilter.month))

  const [filter, setFilter] = useState<IDocumentFilter>(initialFilter ?? {})

  function toggleOpenFilterForm() {
    setIsOpenFilterForm(!isOpenFilterForm)
  }
  function toggleOpenArchivedForm() {
    setIsOpenArchiveModal(!isOpenArchiveModal)
  }
  function toggleOpenDeleteModal() {
    setIsOpenDeleteModal(!isOpenDeleteModal)
  }

  useEffect(() => {
    adminInternalDocumentStore.getListInternalDocumentsHandler(history, filter)
    getListPartnersHandler()
    getListDoctypesHandler()
  }, [])
  useEffect(() => {
    adminInternalDocumentStore.getListInternalDocumentsHandler(history, filter)
  }, [filter, history])

  function filterSubmitHandler(value: IDocumentFilter) {
    const formattedValue = {
      ...value,
      partnerId: getValueFromOption(value.partnerId),
      typeId: getValueFromOption(value.typeId),
      scope: getValueFromOption(value.scope),
    }
    value.year && set(formattedValue, 'year', Number(dayjs(value.year).format('YYYY')))
    value.month && set(formattedValue, 'month', Number(value.month))
    filter.number && set(formattedValue, 'number', value.number)
    setFilter(formattedValue)
    history.push({ search: queryString.stringify(formattedValue) })
    setIsOpenFilterForm((prev) => !prev)
  }

  function handleCreate() {
    history.push(frontendRoutes.internalDocument.create.value)
  }
  function toggleExport() {
    setExportNow(!exportNow)
  }
  async function getListPartnersHandler() {
    try {
      const response: ISuccessResponse<IPartnerListResponse> = await getPartnersAPI({ filter: { isDeleted: false } })
      setPartners(response?.data?.partners || [])
    } catch (error) {
      handleError(error, 'containers/InternalDocument/InternalDocumentList', 'getListPartnersHandler')
    }
  }
  async function getListDoctypesHandler() {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptionsAPI({
        filter: { scope: ECreatableOptionScope.INTERNAL_DOCUMENT },
      })
      setDoctypes(response?.data?.creatableOptions || [])
    } catch (error) {
      handleError(error, 'containers/InternalDocument/InternalDocumentList', 'getListDoctypesHandler')
    }
  }

  function handleClickOKConfirmedArchive() {
    setIsOpenArchiveModal(!isOpenArchiveModal)
  }

  function handleClickOKConfirmedUnArchived() {
    setIsOpenUnarchivedModal(!isOpenUnarchivedModal)
  }

  async function handleClickOKConfirmedDelete(id: string) {
    await adminInternalDocumentStore.deleteInternalDocument(id)
    toggleOpenDeleteModal()
    adminInternalDocumentStore.getListInternalDocumentsHandler(history, filter)
    toast.success(Messages.deleteInternalDocumentSuccess)
  }
  const [isOpenUnarchivedModal, setIsOpenUnarchivedModal] = useState<boolean>(false)
  function toggleOpenUnarchivedModal() {
    setIsOpenUnarchivedModal(!isOpenUnarchivedModal)
  }
  function toggleOpenArchiveModal() {
    setIsOpenArchiveModal(!isOpenArchiveModal)
  }
  function callOpenDialogConfirm(internalDocument: IInternalDocument, nameOfEvent: string) {
    set(internalDocument, 'shortName', internalDocument?.number ?? '')

    switch (nameOfEvent) {
      case actionOnDataTable.DELETE:
        setSelectedInternalDocument(internalDocument)
        toggleOpenDeleteModal()
        break
      case actionOnDataTable.ARCHIVE:
        setSelectedInternalDocument(internalDocument)
        if (internalDocument?.isArchived) {
          toggleOpenUnarchivedModal()
          break
        }
        toggleOpenArchiveModal()
        break
      case actionOnDataTable.UNARCHIVED:
        setSelectedInternalDocument(internalDocument)
        toggleOpenUnarchivedModal()
        break
      default:
        toast.error('Action not found')
    }
  }

  function createActions(internalDocument) {
    const actions = []
    const isDeleted = internalDocument?.isDeleted ?? false
    const isArchived = internalDocument?.isArchived ?? false

    if (!isDeleted && !internalDocument?.isDefault) {
      actions.push(
        {
          name: isArchived ? 'Unarchive' : 'Archive',
          handler: function () {
            callOpenDialogConfirm(internalDocument, actionOnDataTable.ARCHIVE)
          },
        },
        {
          name: 'Delete',
          customClassNames: ['isDanger'],
          handler: function () {
            callOpenDialogConfirm(internalDocument, actionOnDataTable.DELETE)
          },
        }
      )
    }
    return actions
  }
  function createActionDetailHandler(internalDocument: IInternalDocument) {
    const isDeleted = internalDocument?.isDeleted ?? false
    const pathname = generatePath(frontendRoutes.internalDocument.detail.id.value, {
      id: internalDocument?._id,
      isDeleted: isDeleted,
    })
    return function () {
      history.push(pathname)
    }
  }
  const pageIndex = Number(get(history, 'location.state.page', 1)) || 1
  const pagination = {
    includePagination: true,
    pageIndex,
    tableLength: count,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page: page }
      history.push({
        pathname: frontendRoutes.internalDocument.list.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })
      adminInternalDocumentStore.getListInternalDocumentsHandler(history, filter)
    },
  }
  const changeName = debounce((event: { target: { value: string } }) => {
    const updatedFilter = {
      ...filter,
      number: event.target.value || '',
    }
    setFilter(updatedFilter)
    history.push({
      search: queryString.stringify(updatedFilter),
      state: { page: pageIndex, filter: updatedFilter },
    })
  }, 1000)
  function createActionsTablet(internalDocument) {
    const actionsTablet = []
    const isDeleted = internalDocument?.isDeleted ?? false
    const isArchived = internalDocument?.isArchived ?? false

    if (!isDeleted) {
      actionsTablet.push(
        {
          name: 'Detail',
          handler: createActionDetailHandler(internalDocument),
        },
        {
          name: isArchived ? 'Unarchive' : 'Archive',
          handler: function () {
            callOpenDialogConfirm(internalDocument, actionOnDataTable.ARCHIVE)
          },
        },
        {
          name: 'Delete',
          customClassNames: ['isDanger'],
          handler: function () {
            callOpenDialogConfirm(internalDocument, actionOnDataTable.DELETE)
          },
        }
      )
    }
    return actionsTablet
  }
  const dataInTable = getValidArray<IInternalDocument>(internalDocumentList).map((internalDocument) => {
    const partnerInfo: string = `${get(internalDocument, 'partner.fullName', '')} | ${capitalize(
      get(internalDocument, 'partner.category', '')
    )}`
    const typeData = get(internalDocument, 'typeData.value', '')
    const type = get(internalDocument, 'type', '')
    return {
      ...internalDocument,
      partnerInfo: partnerInfo !== ' | ' ? partnerInfo : '',
      typeId: typeData || type,
      publishDate: getFormattedDateWith_MMMDDYYYY(internalDocument?.publishDate ?? ''),
      status: internalDocument?.isDeleted ? 'Deleted' : capitalize(internalDocument?.status ?? ''),
      actions: (
        <MoreDropdown
          isDetail={true}
          detailActionHandler={createActionDetailHandler(internalDocument)}
          actions={createActions(internalDocument)}
        />
      ),
      actionsTablet: createActionsTablet(internalDocument),
    }
  })

  return (
    <Box
      className={styles.internalDocumentContainer}
      background="white"
      padding={6}
      borderRadius="6px"
      border={`1px solid ${backgroundGrey200}`}>
      <Flex justifyContent="space-between" flexWrap="wrap">
        <HStack marginBottom={6} width={{ base: '100%', md: 'auto' }}>
          <InputGroup
            border={`1px solid ${backgroundGrey200}`}
            borderRadius="6px"
            width={{ base: '100%', lg: '540px' }}
            background="white">
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.400" />
            </InputLeftElement>
            <Input type="search" placeholder="Search Document by name or number..." onChange={changeName} />
          </InputGroup>
          <Button
            cursor="pointer"
            paddingY="10px"
            leftIcon={<FiFilter />}
            variant="outline"
            background="white"
            onClick={toggleOpenFilterForm}
            display={{ base: 'none', md: 'inline-flex' }}>
            Filter
          </Button>
          {isOpenFilterForm && (
            <FilterForm
              partners={partners}
              doctypes={doctypes}
              openModalFilterForm={isOpenFilterForm}
              setOpenFilterForm={toggleOpenFilterForm}
              filterSubmit={filterSubmitHandler}
            />
          )}
        </HStack>
        <HStack marginBottom={6} width={{ base: '100%', md: 'auto' }} spacing={4}>
          <Tooltip
            label="Feature coming soon"
            hasArrow={true}
            sx={{
              fontSize: 'md',
              px: 4,
              py: 2,
              borderRadius: 'md',
              boxShadow: 'lg',
            }}>
            <Box>
              <Button
                cursor="pointer"
                leftIcon={<FiDownload />}
                onClick={() => {}}
                background="white"
                marginLeft={{ md: '0 !important' }}
                isDisabled={true}
                variant="outline">
                Export
              </Button>
            </Box>
          </Tooltip>
          <Button
            cursor="pointer"
            paddingY="10px"
            fontWeight={500}
            leftIcon={<AddIcon width={3} />}
            background={backgroundBlueSecondary}
            _hover={{
              background: backgroundBlueSecondary,
            }}
            onClick={handleCreate}>
            Create Document
          </Button>
        </HStack>
      </Flex>
      <Table
        headerList={getHeaderList(isMobile)}
        isLoading={isLoadingInternalDocumentList}
        tableData={dataInTable}
        isStriped
        pagination={pagination}
        exportNow={exportNow}
        toggleExport={toggleExport}
      />
      <ConfirmModal
        data={selectedInternalDocument}
        isOpen={isOpenDeleteModal}
        closeHandler={toggleOpenDeleteModal}
        OKClickHandler={handleClickOKConfirmedDelete}
        title={'Internal Documents'}
        content={'Number'}
        type={EModalType.DELETE}
      />
      <ConfirmModal
        data={selectedInternalDocument}
        isOpen={isOpenArchiveModal}
        closeHandler={toggleOpenArchivedForm}
        OKClickHandler={handleClickOKConfirmedArchive}
        title={'Internal Documents'}
        content={'Number'}
        type={EModalType.ARCHIVE}
      />
      <ConfirmModal
        data={selectedInternalDocument}
        isOpen={isOpenUnarchivedModal}
        closeHandler={toggleOpenUnarchivedModal}
        OKClickHandler={handleClickOKConfirmedUnArchived}
        title={'Internal Documents'}
        content={'Number'}
        type={EModalType.UNARCHIVED}
      />
    </Box>
  )
}
export default observer(InternalDocumentList)
