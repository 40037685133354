import { Tooltip } from '@chakra-ui/react'
import { backgroundGrey700, textWhite } from 'theme/globalStyles'
import { EUserPartnerCategory } from 'constants/enum'
import { ETooltipType } from '../../enums'
import { ICustomTooltipProps } from '../../interfaces'

const CustomTooltip = ({
  content,
  children,
  tooltipType = ETooltipType.REMAINING_DAYS,
  ...props
}: ICustomTooltipProps) => {
  function getTooltipContent() {
    if (tooltipType === ETooltipType.LEAVE_REQUEST) {
      return (
        <div>
          <div>{content.name}</div>
          <div>Type: {content.type}</div>
          <div>Status: {content.status}</div>
          {content.approvalAuthority && <div>Approval Authority: {content.approvalAuthority}</div>}
        </div>
      )
    }

    return (
      <div>
        <div>{content.title}</div>
        {content.remainingDays !== undefined && (
          <div>
            {content.partnerCategory === EUserPartnerCategory.EMPLOYEE
              ? `Remaining leave days: ${content.remainingDays}`
              : `Total number of days off this year: ${content.totalLeaveRequests}`}
          </div>
        )}
      </div>
    )
  }

  return (
    <Tooltip
      label={getTooltipContent()}
      bg={backgroundGrey700}
      color={textWhite}
      borderRadius="md"
      padding="2"
      placement="top"
      hasArrow
      arrowSize={10}
      {...props}>
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
