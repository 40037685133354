import { Column } from 'react-table'
import { ITableHeader } from 'types/table'

export enum EGlobalConfigTab {
  GLOBAL_CONFIG = 'Global Config',
  TECHNOLOGY = 'Technology',
  PROJECT_TYPE = 'Project Type',
  TITLE = 'Title',
  LEVEL = 'Level',
  CURRENCY = 'Currency',
  COUNTRY = 'Country',
  NOTIFICATION = 'Notification',
  TASK_TYPE = 'Task Type',
  DOCUMENT_TYPE = 'Document Type',
  LEAVE_TYPE = 'Leave Type',
}

export const globalConfigTabs = [
  {
    label: EGlobalConfigTab.TECHNOLOGY,
    index: 0,
  },
  {
    label: EGlobalConfigTab.PROJECT_TYPE,
    value: 1,
  },
  {
    label: EGlobalConfigTab.TITLE,
    value: 2,
  },
  {
    label: EGlobalConfigTab.LEVEL,
    value: 3,
  },
  {
    label: EGlobalConfigTab.CURRENCY,
    value: 4,
  },
  {
    label: EGlobalConfigTab.COUNTRY,
    value: 5,
  },
  {
    label: EGlobalConfigTab.NOTIFICATION,
    value: 6,
  },
  {
    label: EGlobalConfigTab.TASK_TYPE,
    value: 7,
  },
  {
    label: EGlobalConfigTab.DOCUMENT_TYPE,
    value: 8,
  },
  {
    label: EGlobalConfigTab.LEAVE_TYPE,
    value: 9,
  },
]

export function getTechnologyHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'TECHNOLOGY NAME',
      accessor: 'value',
    },
    {
      Header: 'TAG COLOR',
      accessor: 'tagColor',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getProjectTypeHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'PROJECT TYPE',
      accessor: 'value',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getTitleHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Title',
      accessor: 'value',
    },
    {
      Header: 'BADGE IMAGE',
      accessor: 'badgeImage',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getLevelHeaderList() {
  const headers: Column<any>[] = [
    {
      Header: 'Level',
      accessor: 'value',
    },
    {
      Header: 'BADGE IMAGE',
      accessor: 'badgeImage',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getDocumentTypeHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'Document Type',
      accessor: 'value',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getTaskTypeHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'TASK TYPE',
      accessor: 'value',
    },
    {
      Header: 'KEYWORDS',
      accessor: 'keywords',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getCountryHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'COUNTRY',
      accessor: 'value',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getCurrencyHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'CURRENCY',
      accessor: 'value',
    },
    {
      Header: 'RATE EXCHANGE',
      accessor: 'rateExchange',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}

export function getLeaveTypeHeaderList() {
  const headers: ITableHeader[] = [
    {
      Header: 'LEAVE TYPE',
      accessor: 'name',
    },
    {
      Header: 'TAG COLOR',
      accessor: 'tagColor',
    },
    {
      Header: '',
      accessor: 'actions',
    },
  ]

  return headers
}
