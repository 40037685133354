import { useEffect } from 'react'
import { Modal, ModalOverlay } from '@chakra-ui/modal'
import { ModalBody, ModalContent } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'
import { ELeaveRequestStatus, ELeaveType } from 'constants/enum'
import { Messages } from 'constants/index'
import { formatTimeToHourMinute, stripHtmlTags } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import LeaveRequestForm from '../LeaveRequestForm'
import { ILeaveRequestModalProps } from './interface'
import LeaveRequestModalFooter from './LeaveRequestModalFooter'
import LeaveRequestModalHeader from './LeaveRequestModalHeader'

const LeaveRequestModal = (props: ILeaveRequestModalProps) => {
  const { isEdit = false, isOpen, closeHandler, saveHandler, selectedLeaveRequest, isLoading, leaveRequestList } = props
  const { leaveTypeStore } = useStores()
  const { userLeaveTypeList } = leaveTypeStore
  const leaveTypeOptions = createOptionsOfReactSelectFromDB(userLeaveTypeList)
  const methods = useForm()
  const { handleSubmit, setError, reset } = methods

  useEffect(() => {
    if (isEdit && selectedLeaveRequest?.startTime && selectedLeaveRequest?.endTime) {
      reset({
        type: {
          value: selectedLeaveRequest?.leaveTypeId,
          label: leaveTypeOptions.find((item) => item.value === selectedLeaveRequest?.leaveTypeId)?.label || '',
        },
        commitTime: selectedLeaveRequest?.commitTime,
        startDate: new Date(selectedLeaveRequest?.startTime),
        startTime: {
          value: dayjs(selectedLeaveRequest?.startTime).format('HH:mm'),
          label: dayjs(selectedLeaveRequest?.startTime).format('HH:mm'),
        },
        endDate: new Date(selectedLeaveRequest?.endTime),
        endTime: {
          value: dayjs(selectedLeaveRequest?.endTime).format('HH:mm'),
          label: dayjs(selectedLeaveRequest?.endTime).format('HH:mm'),
        },
        content: selectedLeaveRequest?.reason,
      })
    } else {
      reset()
    }
  }, [isOpen, selectedLeaveRequest, isEdit, reset])

  const onSubmit = async (formData) => {
    const { startDate, startTime, endDate, endTime, commitTime } = formData

    if (!startDate || !startTime || !endDate || !endTime) {
      if (!startDate) setError('startDate', { type: 'manual', message: Messages.requireStartDate })
      if (!startTime) setError('startTime', { type: 'manual', message: Messages.requireStartTime })
      if (!endDate) setError('endDate', { type: 'manual', message: Messages.requireEndDate })
      if (!endTime) setError('endTime', { type: 'manual', message: Messages.requireEndTime })
      return
    }

    if (formData.type?.label === ELeaveType.REMOTE && commitTime <= 0) {
      setError('commitTime', { type: 'manual', message: Messages.invalidCommitTime })
      return
    }

    const formattedEndTime = formatTimeToHourMinute(formData.endTime.value)
    const formattedStartTime = formatTimeToHourMinute(formData.startTime.value)

    const [startHours, startMinutes] = formattedStartTime.split(':').map(Number)
    const [endHours, endMinutes] = formattedEndTime.split(':').map(Number)

    const startDateTime = new Date(startDate)
    startDateTime.setHours(startHours, startMinutes, 0, 0)
    const endDateTime = new Date(endDate)
    endDateTime.setHours(endHours, endMinutes, 0, 0)

    if (startDateTime < new Date()) {
      setError('startDate', { type: 'manual', message: Messages.invalidStartDate })
      return
    }

    if (startDateTime > endDateTime) {
      setError('endDate', { type: 'manual', message: Messages.requireStartEndDate })
      setError('endTime', { type: 'manual', message: Messages.requireStartEndTime })
      return
    }

    if (startDateTime.toDateString() === endDateTime.toDateString() && startDateTime >= endDateTime) {
      setError('endTime', { type: 'manual', message: Messages.requireStartEndTime })
      return
    }

    const hasOverlap = leaveRequestList?.some((leave) => {
      if (leave.status !== ELeaveRequestStatus.PENDING && leave.status !== ELeaveRequestStatus.APPROVED) return false
      if (isEdit && leave.id === selectedLeaveRequest?.id) return false

      const leaveStart = new Date(leave.startTime)
      const leaveEnd = new Date(leave.endTime)

      return startDateTime < leaveEnd && endDateTime > leaveStart
    })

    if (hasOverlap) {
      setError('startDate', {
        type: 'manual',
        message: Messages.leaveRequestOverlap,
      })
      setError('endDate', {
        type: 'manual',
        message: Messages.leaveRequestOverlap,
      })
      return
    }

    const processedData = {
      commitTime: Number(formData.commitTime) || 0,
      reason: stripHtmlTags(formData.content),
      startTime: startDateTime,
      endTime: endDateTime,
      leaveTypeId: formData.type?.value,
    }

    try {
      await saveHandler(processedData)
      reset()
      closeHandler()
    } catch (error) {
      console.error('Error saving leave request:', error)
    }
  }

  function handleClose() {
    reset()
    closeHandler()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" motionPreset="slideInBottom" closeOnOverlayClick={false}>
      <ModalOverlay />

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent position="fixed" maxHeight="800px" borderRadius="6px" maxWidth={'600px'}>
            <LeaveRequestModalHeader onClose={handleClose} isEdit={isEdit} />
            <ModalBody overflowY="auto" padding={0}>
              <LeaveRequestForm
                methods={methods}
                selectedLeaveRequest={selectedLeaveRequest}
                leaveTypeOptions={leaveTypeOptions}
              />
            </ModalBody>
            <LeaveRequestModalFooter isEdit={isEdit} isLoading={isLoading} onCancel={handleClose} />
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default LeaveRequestModal
