import { handleError } from 'API/error'
import {
  getInternalDocumentsAPI,
  createInternalDocumentAPI,
  updateInternalDocumentAPI,
  getInternalDocumentDetailAPI,
  deleteInternalDocumentAPI,
} from 'API/internalDocument'
import { IInternalDocumentFilter } from 'API/internalDocument/constants'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import queryString from 'query-string'
import { THistory } from 'types/common'
import { IInternalDocument } from 'constants/schema'
import { limitItemPerPage } from '../../constants'
import RootStore from '../rootStore'
import { IInternalDocuments } from './../../API/internalDocument/constants'

class AdminInternalDocumentStore {
  rootStore: RootStore
  currentPage: number = 1
  count: number = 0
  internalDocumentDetail
  creatableOptionList
  isLoadingInternalDocumentDetail: boolean = false
  isLoadingInternalDocumentList: boolean = false
  internalDocumentList: IInternalDocument[]

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }
  public async getListInternalDocumentsHandler(history?: THistory, filter?: IInternalDocumentFilter): Promise<void> {
    try {
      this.isLoadingInternalDocumentList = true
      const searchString = history.location.search
      const parsedQuery = queryString.parse(searchString)
      const currentPage: number = Number(get(parsedQuery, 'page', 1))

      const response: IInternalDocuments = await getInternalDocumentsAPI({
        currentPage,
        perPage: limitItemPerPage,
        filter,
      })

      if (response?.internalDocuments) {
        this.internalDocumentList = response?.internalDocuments
        this.count = response?.count
      }
    } catch (error) {
      handleError(error, 'src/store/admin/internalDocumentStore.ts', 'getListInternalDocumentsHandler')
    } finally {
      this.isLoadingInternalDocumentList = false
    }
  }
  public async createInternalDocument(payload: IInternalDocument) {
    try {
      await createInternalDocumentAPI(payload)
    } catch (error) {
      handleError(error, 'src/store/admin/internalDocumentStore.ts', 'createInternalDocument')
    }
  }
  public async updateInternalDocument(payload: IInternalDocument, internalDocumentId: string) {
    try {
      await updateInternalDocumentAPI(internalDocumentId, payload)
    } catch (error) {
      handleError(error, 'src/store/admin/internalDocumentStore.ts', 'updateInternalDocument')
    }
  }
  public async getInternalDocumentDetail(internalDocumentId: string) {
    try {
      this.isLoadingInternalDocumentDetail = true
      const response = await getInternalDocumentDetailAPI(internalDocumentId)
      this.internalDocumentDetail = response.internalDocument
    } catch (error) {
      handleError(error, 'src/store/admin/internalDocumentStore.ts', 'getInternalDocumentDetail')
    } finally {
      this.isLoadingInternalDocumentDetail = false
    }
  }
  public async deleteInternalDocument(internalDocumentId: string) {
    try {
      this.isLoadingInternalDocumentList = true
      await deleteInternalDocumentAPI(internalDocumentId)
    } catch (error) {
      handleError(error, 'src/store/admin/internalDocumentStore.ts', 'deleteInternalDocument')
    } finally {
      this.isLoadingInternalDocumentList = false
    }
  }
}

export default AdminInternalDocumentStore
