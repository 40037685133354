import { ITableHeader } from 'types/table'

export const headerListMobile: ITableHeader[] = [
  {
    Header: 'Full Name',
    accessor: 'fullName',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export const headerListDesktop: ITableHeader[] = [
  {
    Header: 'Full Name',
    accessor: 'fullName',
  },
  {
    Header: 'Role',
    accessor: 'role',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone Number',
    accessor: 'phone',
  },
  {
    Header: 'Title',
    accessor: 'titleId',
  },
  {
    Header: 'Technology',
    accessor: 'technology',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export function getHeaderList(isMobile: boolean): ITableHeader[] {
  return isMobile ? headerListMobile : headerListDesktop
}

export const tabletHeaderList = {
  fullName: 'Full Name',
  role: 'Role',
  email: 'Email',
  actionsTablet: '',
}
