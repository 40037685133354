import { useEffect, useState } from 'react'
import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { HStack } from '@chakra-ui/layout'
import { GroupBase } from 'chakra-react-select'
import Button from 'components/Button'
import { useFormContext, Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import { textGrey500, textGrey700 } from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { isValidArray } from 'utils/commonUtils'
import Dropdown from './components/Dropdown'
import DropdownIndicator from './components/DropdownIndicator'
import { selectStyles } from './styles'

export interface IMultipleSelectProps {
  name: string
  label?: string
  paramKey?: string
  selectedEntityName?: string
  placeholder?: string
  isRequired?: boolean
  allowSelectAll?: boolean
  options: IOption[]
  isDisabled?: boolean
  menuPlacement?: 'bottom' | 'top'
  containerStyles?: Record<string, unknown>
  controlStyles?: Record<string, unknown>
  optionStyles?: Record<string, unknown>
  menuStyles?: Record<string, unknown>
  menuListStyles?: Record<string, unknown>
  selectHandler?: (selectedOptions: IOption[] | null) => void
}

const MultipleSelect = (props: IMultipleSelectProps) => {
  const {
    name,
    label,
    paramKey,
    selectedEntityName,
    options,
    isRequired = false,
    placeholder = 'Select ...',
    isDisabled,
    allowSelectAll = true,
    menuPlacement = 'bottom',
    containerStyles,
    controlStyles,
    optionStyles,
    menuStyles,
    menuListStyles,
    selectHandler,
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { control, watch, setValue } = useFormContext()
  const value = watch(name)
  const location = useLocation()
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    function updatePlaceholder() {
      if (!value || value.length === 0) {
        setShowPlaceHolder(placeholder)
      } else if (value.length === options.length) {
        setShowPlaceHolder(`Selected All ${selectedEntityName}`)
      } else {
        setShowPlaceHolder(`Selected ${value.length} ${selectedEntityName}`)
      }
    }
    updatePlaceholder()
  }, [value, options.length, selectedEntityName, placeholder])

  useEffect(() => {
    function initializeValuesFromURL() {
      if (options.length > 0 && !isInitialized) {
        const params = new URLSearchParams(location.search)
        const paramValues = params.getAll(paramKey)
        if (paramValues.length > 0) {
          const selectedOptions = options.filter((option) => {
            return paramValues.includes(option.value)
          })
          if (selectedOptions.length > 0) {
            setValue(name, selectedOptions)
          }
        }
        setIsInitialized(true)
      }
    }
    initializeValuesFromURL()
  }, [options.length, paramKey, location.search, isInitialized])

  const [showPlaceHolder, setShowPlaceHolder] = useState(placeholder)
  let formattedOptions: IOption[] = []
  if (isValidArray(options)) {
    if (allowSelectAll) {
      formattedOptions = [{ value: 'all', label: 'Select All' }, ...options]
    } else {
      formattedOptions = [...options]
    }
  }

  function handleChange(selectedOptions: IOption[] | null, onChange: (options: IOption[]) => void) {
    let newPlaceHolder: string = placeholder

    if (
      selectedOptions !== null &&
      selectedOptions.length > 0 &&
      selectedOptions[selectedOptions.length - 1].value === 'all'
    ) {
      if (value?.length === options.length) {
        setValue(name, [])
        newPlaceHolder = `Selected 0 ${selectedEntityName}`
        onChange([])
        if (selectHandler) {
          selectHandler([])
        }
      } else {
        setValue(name, options)
        newPlaceHolder = `Selected All ${selectedEntityName}`
        onChange(options)
        if (selectHandler) {
          selectHandler(options)
        }
      }
    } else {
      setValue(name, selectedOptions)
      newPlaceHolder = `Selected ${selectedOptions.length} ${selectedEntityName}`
      onChange(selectedOptions)
      if (selectHandler) {
        selectHandler(selectedOptions)
      }
    }
    setShowPlaceHolder(newPlaceHolder)
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: isRequired }}
      render={({ field: { name, value, ref, onChange }, fieldState: { error } }) => (
        <FormControl id={name} width="full" isInvalid={!!error}>
          {label && (
            <HStack spacing="14px" maxHeight={6} marginBottom={2}>
              <FormLabel htmlFor={name} fontWeight={'normal'} mb={2} color={textGrey700}>
                {label}
              </FormLabel>
            </HStack>
          )}

          <Dropdown
            menuPlacement={menuPlacement}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            target={
              <Button
                rightIcon="arrow-down-s-line.svg"
                width="full"
                onClick={() => setIsOpen((prev) => !prev)}
                variant="outline"
                color={textGrey500}
                disabled={isDisabled}
                customStyles={{ justifyContent: 'space-between', fontWeight: 400 }}>
                {showPlaceHolder}
              </Button>
            }>
            <Select<IOption, true, GroupBase<IOption>>
              ref={ref}
              menuPlacement={menuPlacement}
              autoFocus
              backspaceRemovesValue={false}
              components={{ DropdownIndicator, IndicatorSeparator: null }}
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
              isClearable={true}
              menuIsOpen
              options={formattedOptions}
              placeholder="Search..."
              styles={selectStyles(containerStyles, controlStyles, optionStyles, menuStyles, menuListStyles)}
              tabSelectsValue={false}
              value={value}
              isMulti
              onChange={(options) => {
                handleChange(options as IOption[], onChange)
              }}
            />
          </Dropdown>
        </FormControl>
      )}
    />
  )
}

export default MultipleSelect
