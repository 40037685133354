import { Search2Icon } from '@chakra-ui/icons'
import { Flex, HStack, Input, InputGroup, InputLeftElement, useMediaQuery } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'
import Button from 'components/Button'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize, tabletSize } from 'constants/common'

export interface IHeaderSectionProps {
  openFilterForm: () => void
  handleCreate: () => void
  handleExport: () => void
  changeName: (e: React.ChangeEvent<HTMLInputElement>) => void
  isExporting: boolean
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const history = useHistory()
  const { openFilterForm, handleCreate, handleExport, changeName, isExporting } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const [isMobileTablet]: boolean[] = useMediaQuery(tabletSize)
  const initialFilter = queryString.parse(history.location.search)
  const name = initialFilter?.name

  return (
    <Flex justifyContent="space-between" flexWrap="wrap" paddingX={isMobile ? 4 : 0} paddingTop={isMobile ? 4 : 0}>
      <HStack marginBottom={isMobile ? 4 : 6}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          background="white"
          width={isMobile ? '160px' : 'auto'}
          minWidth={isMobile ? 0 : '300px'}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input
            type="search"
            defaultValue={name}
            placeholder={isMobileTablet ? 'Search' : 'Search Projects by name...'}
            onChange={changeName}
            border="none"
          />
        </InputGroup>
        <Button variant="outline" leftIcon="filter.svg" onClick={openFilterForm}>
          {!isMobile && 'Filter'}
        </Button>
      </HStack>
      <HStack marginBottom={isMobile ? 4 : 6}>
        <Box>
          <Button
            variant="outline"
            disabled={isExporting}
            isLoading={isExporting}
            onClick={handleExport}
            leftIcon="export.svg">
            {!isMobile && 'Export'}
          </Button>
        </Box>
        <Button variant="solid" leftIcon="add-white.svg" onClick={handleCreate}>
          {!isMobile && 'Add Project'}
        </Button>
      </HStack>
    </Flex>
  )
}

export default HeaderSection
