import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Spinner,
} from '@chakra-ui/react'
import Button from 'components/Button'

export interface IConfirmYesNoDeleteModal {
  itemId: string
  isOpen?: boolean
  title?: string
  closeHandler?
  OKClickHandler?: (id: string) => void
  isLoading?: boolean
}

const ConfirmYesNoDeleteModal = (props: IConfirmYesNoDeleteModal) => {
  const { isOpen, title, closeHandler, OKClickHandler, itemId, isLoading } = props

  return (
    <Modal isOpen={isOpen} onClose={closeHandler} isCentered>
      <ModalOverlay />
      <ModalContent maxHeight="196px" marginLeft={4} marginRight={4}>
        <ModalHeader>Delete {title}</ModalHeader>
        <ModalCloseButton color={'gray'} background="none" cursor="pointer" />
        <ModalBody paddingY={0}>
          <Text marginY={0}>Are you sure? You can’t undo this action afterwards.</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            variant="outline"
            customStyles={{ marginRight: '16px' }}
            onClick={closeHandler}>
            Cancel
          </Button>
          <Button disabled={isLoading} isLoading={isLoading} onClick={() => OKClickHandler(itemId)} colorScheme="red">
            {isLoading ? <Spinner /> : 'Delete'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmYesNoDeleteModal
