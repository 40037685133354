import { useEffect } from 'react'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import RadioButton from 'components/RadioButton/RadioButtonWithoutFormik'
import { observer } from 'mobx-react'
import { ETimesheetSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { useStores } from 'utils/hooks/useStores'
import { Filters, Mode } from './constant'
import ProjectFilterForm from './Projects/FilterForm'
import ProjectTable from './Projects/Table'
import UserFilterForm from './Users/FilterForm'
import UserTable from './Users/Table'

const History = () => {
  const { layoutStore } = useStores()
  const { adminTimesheetHistoryStore } = useStores()
  const { selectedMode, filters, projectHistoryData, userHistoryData, isReportLoading, projects, users } =
    adminTimesheetHistoryStore

  useEffect(() => {
    adminTimesheetHistoryStore.getListProjects()
    adminTimesheetHistoryStore.getListUsers()
    initFromQueryParams()
  }, [])

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(ETimesheetSubTitle.HISTORY)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  function initFromQueryParams(): void {
    const params = new URLSearchParams(window.location.search)
    const mode = params.get('selectedMode') || Mode.PROJECT
    const newFilters: Filters = {}

    if (mode === Mode.PROJECT) {
      const project = params.get('project')
      if (project) {
        newFilters.project = project
      }
      adminTimesheetHistoryStore.setSelectedMode(Mode.PROJECT)
      adminTimesheetHistoryStore.setFilters(newFilters)
      if (Object.keys(newFilters).length > 0) {
        adminTimesheetHistoryStore.generateProjectHistory({ project: newFilters.project })
      }
    } else {
      const user = params.get('user')
      if (user) {
        newFilters.user = user
      }
      adminTimesheetHistoryStore.setSelectedMode(Mode.USER)
      adminTimesheetHistoryStore.setFilters(newFilters)
      if (Object.keys(newFilters).length > 0) {
        adminTimesheetHistoryStore.generateUserHistory({ user: newFilters.user })
      }
    }
  }

  function switchMode(): void {
    const nextMode = selectedMode === Mode.USER ? Mode.PROJECT : Mode.USER
    adminTimesheetHistoryStore.setSelectedMode(nextMode)
    adminTimesheetHistoryStore.setFilters({})
    adminTimesheetHistoryStore.projectHistoryData = undefined
    adminTimesheetHistoryStore.userHistoryData = []
    adminTimesheetHistoryStore.updateQueryString(nextMode, {})
  }

  const renderOptionFilter = (): JSX.Element => {
    if (selectedMode === Mode.PROJECT) {
      return (
        <ProjectFilterForm
          isReportLoading={isReportLoading}
          projects={projects}
          filterSubmitHandler={adminTimesheetHistoryStore.generateProjectHistory}
          initialValues={filters}
        />
      )
    }
    return (
      <UserFilterForm
        isReportLoading={isReportLoading}
        users={users}
        filterSubmitHandler={adminTimesheetHistoryStore.generateUserHistory}
        initialValues={filters}
      />
    )
  }

  const renderBody = (): JSX.Element => {
    const content =
      selectedMode === Mode.PROJECT ? <ProjectTable data={projectHistoryData} /> : <UserTable data={userHistoryData} />

    return (
      <div className="timesheet-content">
        <div className="container-fluid">
          <div className="box box-default">
            <div className="box-header">Summary</div>
            <div className="box-divider" />
            <div className={`box-body`}>{content}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="timesheet-page">
      <div className="options-group">
        <RadioButton label="Choose Mode User" value={selectedMode === Mode.USER} clickHandler={switchMode} />
        {renderOptionFilter()}
      </div>
      <LoadingChakra isLoading={isReportLoading}>
        <div className="body">
          {!projectHistoryData && !userHistoryData?.length ? (
            <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
          ) : (
            renderBody()
          )}
        </div>
      </LoadingChakra>
    </div>
  )
}

export default observer(History)
