export const globalBackground = '#E5E5E5'

// BACKGROUND
export const backgroundPrimary = '#08C8F6'
export const backgroundBlueSecondary = '#4D5DFB'
export const backgroundBlue50 = '#EBF8FF'
export const backgroundBlue500 = '#4299E1'
export const backgroundBlue600 = '#2B6CB0'
export const backgroundBlue800 = '#2A4365'
export const backgroundWhite = '#fff'
export const backgroundGrey100 = '#EDF2F7'
export const backgroundGrey50 = '#F7FAFC'
export const backgroundGrey200 = '#E2E8F0'
export const backgroundGrey600 = 'rgba(0, 0, 0, 0.06)'
export const backgroundGrey700 = '#2D3748'
export const backgroundBlack100 = 'rgba(0, 0, 0, 0.1)'
export const backgroundYellow = '#ffc107'
export const backgroundYellow300 = '#f6e05e'
export const backgroundCyan = '#17a2b8'
export const backgroundRed500 = '#e53e3e'
export const backgroundGreen500 = '#68d490'

// TEXT
export const textSecondary = '#4D5DFB'
export const textGrey500 = '#718096'
export const textGrey600 = '#4A5568'
export const textGrey700 = '#2D3748'
export const textGrey800 = '#1A202C'
export const textBlue500 = '#3182CE'
export const textBlue600 = '#2B6CB0'
export const textRed500 = '#e53e3e'
export const textRed600 = '#C53030'
export const textWhite = '#fff'
export const textGreen500 = '#38A169'
export const textYellow500 = '#D69E2E'

// OTHER
export const submitButtonBackground = '#4D5DFB'
export const boxShadow = '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
export const transparent = 'transparent'

// BORDER
export const borderGray200 = '#E2E8F0'
export const borderGray500 = 'gray.500'
export const borderGray300 = 'gray.300'
export const borderBlue500 = '#3182CE'
export const borderSecondary = '#4D5DFB'
export const borderRed500 = 'red.500'
export const borderRed600 = '#C53030'
