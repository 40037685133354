import { useEffect, useRef, useState } from 'react'
import { Avatar, Flex, HStack, Text, chakra } from '@chakra-ui/react'
import { logoutAPI } from 'API/auth'
import { EStatusCode } from 'API/constants'
import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import { readAllNotificationAPI } from 'API/notification'
import Button from 'components/Button'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { backgroundWhite } from 'theme/globalStyles'
import { INotification } from 'types/notification'
import { EPathName, ERoleOfUser, EActionType, EFirstPathElement } from 'constants/enum'
import { ELocalStorageKeys } from 'constants/localStorageKeys'
import { frontendRoutes } from 'constants/routes'
import { beforeLogoutHandler, getValidArray, getDisplayName } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { generateImageURL } from 'utils/imageUtils'
import { ReactComponent as ArrowRight } from '../../assets/images/svg/arrow-right-s-line.svg'
import { ReactComponent as CollapseICon } from '../../assets/images/svg/collapse.svg'
import { ReactComponent as ExpandIcon } from '../../assets/images/svg/hamburger.svg'
import { EventName } from '../../constants'
import { IHeaderProps } from './constants'

import './index.scss'

const Header = (props: IHeaderProps) => {
  const {
    notifications,
    history,
    toggleCollapsedSidebarHandler,
    isCollapsed,
    triggerToggleUserPanelHandler,
    isOpenUserPanel,
    isActiveUserPanel,
    getNotificationsList,
  } = props
  const headerRef = useRef(null)
  const { layoutStore } = useStores()
  const { pageTitle, subPageTitle } = layoutStore
  const [isOpenNotificationDropDown, setIsOpenNotificationDropDown] = useState<boolean>(false)
  const { location } = history
  const { search, pathname } = location

  const unReadNotifications: INotification[] = getValidArray(notifications).filter(
    (item: INotification) => !item.isRead
  )

  const fullName: string = window.localStorage.getItem(ELocalStorageKeys.FULL_NAME)
  const displayName: string = window.localStorage.getItem(ELocalStorageKeys.DISPLAY_NAME)
  const email: string = window.localStorage.getItem(ELocalStorageKeys.EMAIL)
  const avatar: string = window.localStorage.getItem(ELocalStorageKeys.AVATAR)
  const role: string = window.localStorage.getItem(ELocalStorageKeys.USER_ROLE)

  const user = {
    displayName,
    fullName,
  }

  async function logoutHandler(): Promise<void> {
    try {
      const response: ISuccessResponse<object> = await logoutAPI()
      if (response?.status === EStatusCode.SUCCESS) {
        beforeLogoutHandler()
      }
    } catch (error) {
      handleError(error, 'src/components/Header/index.tsx', 'logoutHandler')
    }
  }

  function linkToProfile(): void {
    history.push(frontendRoutes.profilePage.value)
  }

  function triggerToggleNotificationDropDown(): void {
    setIsOpenNotificationDropDown(!isOpenNotificationDropDown)
  }

  function handleClickOutside(event: Event): void {
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      if (isOpenUserPanel) {
        triggerToggleUserPanelHandler()
      }
      if (isOpenNotificationDropDown) {
        triggerToggleNotificationDropDown()
      }
    }
  }

  async function clickReadAllHandler(): Promise<void> {
    try {
      await readAllNotificationAPI()
      getNotificationsList()
    } catch (error) {
      handleError(error, 'src/components/Header/index.tsx', 'clickReadAllHandler')
    }
  }

  function goBack(): void {
    if (subPageTitle) {
      if (
        !pathname.includes(EPathName.DETAIL) &&
        !pathname.includes(EPathName.NEW) &&
        !pathname.includes(EPathName.PROJECT) &&
        !pathname.includes(EActionType.EDIT.toLowerCase()) &&
        !pathname.includes(EActionType.CREATE.toLowerCase()) &&
        (search.includes(EPathName.INDEX) || pathname.split('/').length >= 3)
      ) {
        history.replace(`${pathname}`)
      } else {
        if (pathname.includes(EPathName.PROJECT)) {
          const previousPath =
            role === ERoleOfUser.ADMIN
              ? frontendRoutes.projectPage.projectAdmin.list.value
              : frontendRoutes.projectPage.projectUser.list.value
          history.replace(`${previousPath}`)
        } else if (pathname.includes(EPathName.PROFILE)) {
          const pageTitlePathname = pageTitle.replaceAll(' ', '-').toLowerCase()
          history.replace(`/${pageTitlePathname}`)
        } else if (pathname.includes(EFirstPathElement.USER_DASHBOARD)) {
          history.replace(`${pathname}`)
        } else if (pathname.includes(EFirstPathElement.LEAVE_MANAGEMENT)) {
          history.replace(`${pathname}`)
        } else {
          const pageTitlePathname = pageTitle.replaceAll(' ', '-').toLowerCase()
          history.replace(`/${pageTitlePathname}/list`)
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener(EventName.mousedown, handleClickOutside)

    return () => {
      document.removeEventListener(EventName.mousedown, handleClickOutside)
    }
  }, [isOpenNotificationDropDown, isOpenUserPanel])

  function truncateStringHeader(headerString: string, num: number) {
    if (headerString.length <= num) {
      return headerString
    }
    return headerString.slice(0, num) + '...'
  }

  return (
    <div className="app-header" ref={headerRef}>
      <nav className={isCollapsed ? 'collapsed myNavbar' : 'myNavbar'}>
        <Flex marginLeft={{ base: 4, md: 6 }}>
          <chakra.span
            className="sidebar-toogle"
            onClick={toggleCollapsedSidebarHandler}
            marginRight={{ base: 4, md: 6 }}
            color="gray.700">
            {isCollapsed ? (
              <ExpandIcon onClick={toggleCollapsedSidebarHandler} />
            ) : (
              <CollapseICon onClick={toggleCollapsedSidebarHandler} />
            )}
          </chakra.span>
          <HStack spacing={4}>
            <Text
              fontSize="lg"
              fontWeight="bold"
              marginTop={5}
              display="inline-block"
              placeSelf="center"
              onClick={goBack}
              textDecoration="unset"
              cursor={subPageTitle ? 'pointer' : 'unset'}>
              {subPageTitle ? truncateStringHeader(pageTitle, 8) : pageTitle}
            </Text>
            {subPageTitle && (
              <>
                <ArrowRight />
                <Text
                  fontSize="lg"
                  fontWeight="500"
                  marginTop={5}
                  display="inline-block"
                  placeSelf="center"
                  color="gray.500">
                  {subPageTitle}
                </Text>
              </>
            )}
          </HStack>
        </Flex>
        <div className="myNavbar-custom-menu">
          <ul className="myNavbar-nav">
            {/*
            //*INFO: Implement in the future
            <li className="alert-menu" onClick={triggerToggleNotificationDropDown}>
              <Flex
                width={12}
                height={12}
                title="Notification"
                justify="center"
                align="center"
                marginRight={4}
                borderRadius={6}
                background={isOpenNotificationDropDown ? 'gray.100' : 'none'}
                _hover={{
                  bg: 'gray.100',
                  cursor: 'pointer',
                }}>
                {unReadNotifications?.length > 0 && (
                  <Icon viewBox="0 0 200 200" color="red.500" position="absolute" padding="0 0 15px 15px">
                    <path fill="currentColor" d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0" />
                  </Icon>
                )}
                {isOpenNotificationDropDown ? <BlackNotificationIcon /> : <GrayNotificationIcon />}
              </Flex>
              {isOpenNotificationDropDown && (
                <NotificationDropDown
                  data={formattedNotificationData(notifications, role)}
                  clickReadAllHandler={clickReadAllHandler}
                />
              )}
            </li> */}
            <li className="user-menu">
              <div
                title="Your information"
                className={`user-menu-wrapper ${isActiveUserPanel ? 'active' : ''}`}
                onClick={triggerToggleUserPanelHandler}>
                <HStack
                  spacing={3}
                  background={backgroundWhite}
                  _hover={{ background: 'gray.50' }}
                  borderRadius="6px"
                  padding="4px 12px"
                  marginRight={3}>
                  <Avatar
                    name={getDisplayName(user)}
                    src={generateImageURL(avatar, ELocalStorageKeys.AVATAR)}
                    size="sm"
                  />
                  <Flex direction="column" display={{ base: 'none', md: 'flex' }} gap={1}>
                    <Text
                      width={{ md: '100px', lg: 'fit-content' }}
                      fontSize="sm"
                      fontWeight="400"
                      lineHeight="5"
                      margin={0}
                      color="gray.800">
                      {getDisplayName(user)}
                    </Text>
                    <Text fontSize="xs" color="gray.500" margin={0} lineHeight={4}>
                      {email}
                    </Text>
                  </Flex>
                </HStack>
                {isOpenUserPanel && (
                  <ul className="dropdown">
                    <li className="profile" onClick={linkToProfile}>
                      <div className="profile-content">
                        <Link to={frontendRoutes.profilePage.value}>
                          <Button
                            variant="ghost"
                            leftIcon="user-profile.svg"
                            customStyles={{ width: '100%', justifyContent: 'left' }}>
                            My Profile
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li className="logout">
                      <Button
                        variant="ghost"
                        leftIcon="logout-red.svg"
                        customStyles={{ width: '100%', color: 'red', justifyContent: 'left' }}
                        onClick={logoutHandler}>
                        Logout
                      </Button>
                    </li>
                  </ul>
                )}
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default observer(Header)
