import { useEffect } from 'react'
import { Text, VStack, SimpleGrid, Container, FormLabel, FormControl } from '@chakra-ui/react'
import CheckboxFieldWithLabel from 'components/CheckboxFieldWithLabel'
import DatePicker from 'components/DatePicker'
import FormInput from 'components/FormInput'
import GroupChips from 'components/GroupChips'
import GroupRadio from 'components/GroupRadio'
import MultipleSelect from 'components/MultipleSelect'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { PaymentCategories, PaymentCategoriesFromClient } from 'constants/defaultOptionList'
import { EPaymentCategoryFromClientOfProject, EPaymentCategoryOfProject } from 'constants/enum'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { TitlePages } from '../../../../constants'
import styles from './index.module.scss'

const CreateProjectForm = (props) => {
  const { partnerList, projectTypes, countryList, technologyList, currencyList } = props
  const { control } = useFormContext()
  const { layoutStore } = useStores()
  const partnersOptions = createOptionsOfReactSelectFromDB(partnerList)
  const projectTypesOptions = createOptionsOfReactSelectFromDB(projectTypes)
  const countryListOptions = createOptionsOfReactSelectFromDB(countryList)
  const technologyListOptions = createOptionsOfReactSelectFromDB(technologyList)
  const currencyOptions = createOptionsOfReactSelectFromDB(currencyList)
  const watchPaymentCategoryFromClient = useWatch({ name: 'paymentCategoryFromClient', control })

  function renderPaymentCategoryFromClientInputField(
    paymentType: string = EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL
  ) {
    switch (paymentType) {
      case EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL:
        return <FormInput name="clientRate" label="Client Rate" placeholder="Enter Client Rate" key={'clientRate'} />

      case EPaymentCategoryFromClientOfProject.MONTHLY_FIXED_SCOPE:
        return (
          <FormInput
            name="billableMonthlyAmount"
            label="Billable Monthly Amount"
            placeholder="Enter Client Rate"
            key="billableMonthlyAmount"
          />
        )

      default:
        return ''
    }
  }

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(TitlePages.PROJECT_NEW)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack
        background="white"
        padding={{ base: 6, lg: 6 }}
        // spacing={{ base: 6, lg: 8 }}
        // width="full"
        borderRadius="8px">
        <Text alignSelf={'flex-start'} className={styles.headerBox}>
          General Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="name" label="Project Name" isRequired placeholder="Enter Project name" />
          <FormInput name="companyName" label="Company Name (Optional)" placeholder="Enter Company name" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput
            name="companyWebsite"
            label="Company Website (Optional)"
            isWebsite
            placeholder="example.com"
            type="tel"
          />
          <FormInput name="companyPhone" label="Company Phone Number (Optional)" type="tel" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <SingleSelect
            name="country"
            label="Country"
            placeHolder="- Select country -"
            optionsData={countryListOptions}
            isRequire
          />
          <FormInput name="address" label="Address Detail (Optional)" placeholder="Enter Address detail" />
        </SimpleGrid>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Customer Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="customer" label="Customer Name" placeholder="Enter Customer name" isRequired />
          <FormInput name="customerEmail" label="Customer Email (Optional)" placeholder="Enter Customer email" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="customerPhone" label="Customer Phone Number (Optional)" />
        </SimpleGrid>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Payment From Client
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
          <GroupRadio
            name={'paymentCategoryFromClient'}
            isEdit
            data={PaymentCategoriesFromClient}
            label="Category of Payment from Client"
            defaultValue={EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL}
          />
          {renderPaymentCategoryFromClientInputField(watchPaymentCategoryFromClient)}
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ sm: 4, md: 6, lg: 8 }} width="full">
          <SingleSelect
            name="currency"
            label="Currency"
            placeHolder={'- Select Currency - '}
            optionsData={currencyOptions}
            isRequire={true}
          />
          <FormInput name="paymentPeriod" label="Payment Period (days)" placeholder="Enter Payment Period" isRequired />
        </SimpleGrid>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Payment For Staff
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <GroupRadio
            name={'paymentCategory'}
            data={PaymentCategories}
            defaultValue={EPaymentCategoryOfProject.HOURLY}
            label="Category of Payment"
          />
        </SimpleGrid>
        <Text alignSelf={'flex-start'} className={`${styles.headerBox} ${styles.border}`}>
          Working Information
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormControl>
            <FormLabel fontWeight="400">Start Date</FormLabel>
            <Controller
              name="startedDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                  onChange={(date) => handleDatePickerChange(field, date)}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel fontWeight="400">End Date (Optional)</FormLabel>
            <Controller
              name="endedDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                  onChange={(date) => handleDatePickerChange(field, date)}
                />
              )}
            />
          </FormControl>
          <CheckboxFieldWithLabel name="untilNow" label="Until Now" />
        </SimpleGrid>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 2 }}
          spacingY="10px"
          gap={{ base: 6, lg: 8 }}
          width="full"></SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput
            name="commitedHours"
            label="Commited Hours (hrs/week) (Optional)"
            placeholder="Enter Commited hours"
          />
          <VStack>
            <MultipleSelect
              name="technology"
              label="Technology (Optional)"
              options={technologyListOptions}
              placeholder="Select Technology"
              selectedEntityName="Technology"
            />
            <VStack alignSelf={'flex-start'}>
              <GroupChips fieldName="technology" />
            </VStack>
          </VStack>
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <FormInput name="neededDeveloperNumber" label="Needed Developer Number (Optional)" />
        </SimpleGrid>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
          <SingleSelect
            name="partnerId"
            label="Belongs To Partner"
            optionsData={partnersOptions}
            placeHolder={'- Select Partner -'}
            isRequire
          />
          <VStack>
            <MultipleSelect
              label="Project Type (Optional)"
              name="type"
              options={projectTypesOptions}
              placeholder="Select Project Type"
              menuPlacement="top"
              selectedEntityName="Project Types"
            />
            <VStack alignSelf={'flex-start'}>
              <GroupChips fieldName="type" defaultColor="gray" />
            </VStack>
          </VStack>
        </SimpleGrid>
      </VStack>
    </Container>
  )
}
export default CreateProjectForm
