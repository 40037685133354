import { useEffect, useRef } from 'react'
import { Box } from '@chakra-ui/react'
import dayjs from 'dayjs'
import Timeline, { DateHeader, TimelineHeaders } from 'react-calendar-timeline'
import { backgroundBlue500, backgroundGreen500, textSecondary } from 'theme/globalStyles'
import { ELeaveRequestStatus, EWorkingHourPeriods } from 'constants/enum'
import { ETooltipType } from '../../enums'
import { ILeaveCalendarProps, IUserLeaveInfo } from '../../interfaces'
import { adjustTimeRange, preventScrollEvents, transformDataToGroupsAndItems } from '../../utils'
import CustomTooltip from '../CustomTooltip'
import styles from './leaveCalendar.module.scss'
import 'react-calendar-timeline/lib/Timeline.css'

const Wallchart = (props: ILeaveCalendarProps) => {
  const { selectedDate, period, leaveRequestsByProjects } = props
  const { groups, items } = transformDataToGroupsAndItems(leaveRequestsByProjects)
  const calendarRef = useRef<HTMLDivElement>(null)
  const timelineRef = useRef<HTMLDivElement>(null)

  const adjustedItems = items.map((item) => {
    const { startTime, endTime } = adjustTimeRange(item.startTime, item.endTime)
    return { ...item, start_time: startTime, end_time: endTime }
  })

  function renderSidebarItem({ group }: { group: IUserLeaveInfo }) {
    return (
      <CustomTooltip
        content={{
          title: group.title,
          remainingDays: group.remainingDays,
          partnerCategory: group.partnerCategory,
          totalLeaveRequests: group.totalLeaveRequests,
        }}
        tooltipType={ETooltipType.REMAINING_DAYS}>
        <Box style={{ padding: '0 4px' }} cursor="pointer" _hover={{ color: textSecondary, fontWeight: 'bold' }}>
          {group.title}
        </Box>
      </CustomTooltip>
    )
  }

  function renderItemWithHover({ item, getItemProps }) {
    const tipData = JSON.parse(item.itemProps['data-tip'])
    return (
      <CustomTooltip
        content={{
          name: tipData.name,
          type: tipData.type,
          status: tipData.status,
          approvalAuthority: tipData.approvalAuthority,
        }}
        tooltipType={ETooltipType.LEAVE_REQUEST}>
        <div
          {...getItemProps({
            style: {
              background: tipData.status === ELeaveRequestStatus.PENDING ? backgroundBlue500 : backgroundGreen500,
              border: '1px solid #ccc',
              borderRadius: '4px',
              padding: '4px',
            },
          })}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              padding: '0 4px',
            }}
          />
        </div>
      </CustomTooltip>
    )
  }

  function getTimeRange() {
    if (period?.value === EWorkingHourPeriods.MONTH) {
      return {
        start: dayjs(selectedDate).startOf('month'),
        end: dayjs(selectedDate).endOf('month'),
      }
    }
    return {
      start: dayjs(selectedDate).startOf('week'),
      end: dayjs(selectedDate).endOf('week'),
    }
  }

  const { start, end } = getTimeRange()

  useEffect(() => {
    preventScrollEvents(calendarRef.current)
    preventScrollEvents(timelineRef.current)
  }, [])

  return (
    <div ref={calendarRef} tabIndex={0} className={styles.container} style={{ width: '100%', height: '500px' }}>
      <div ref={timelineRef}>
        <Timeline
          groups={groups}
          items={adjustedItems}
          defaultTimeStart={start}
          defaultTimeEnd={end}
          canMove={false}
          canResize={false}
          canChangeGroup={false}
          minZoom={1000 * 60 * 60 * 24}
          maxZoom={1000 * 60 * 60 * 24 * 31}
          moveResizeValidator={() => false}
          onTimeChange={() => false}
          lineHeight={50}
          itemHeightRatio={0.75}
          itemRenderer={renderItemWithHover}
          groupRenderer={props.isSupervisor ? renderSidebarItem : undefined}
          sidebarWidth={200}
          timeSteps={{
            second: 0,
            minute: 0,
            hour: 12,
            day: 1,
            month: 1,
            year: 1,
          }}
          visibleTimeStart={dayjs(start).startOf('day').valueOf()}
          visibleTimeEnd={dayjs(end).endOf('day').valueOf()}>
          <TimelineHeaders className="header-container">
            <DateHeader className="day-header" unit="day" labelFormat="ddd" />
            <DateHeader className="date-header" unit="day" labelFormat="D" />
          </TimelineHeaders>
        </Timeline>
      </div>
    </div>
  )
}

export default Wallchart
