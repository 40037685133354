import { useState, useEffect } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { generateProjectOptions } from 'utils/user'
import OverviewCalendar from '../components/OverviewCalendar'
import OverviewFilterForm from '../components/OverviewFitlerForm'
import OverviewHistory from '../components/OverviewHistory'
import OverviewReport from '../components/OverviewReport'
import TaskTypeAnalytics from '../components/TaskTypeAnalytics'
import { IOverviewFilterFormData, workingPeriodOptions } from '../constants'

const TimesheetOverview = () => {
  const { layoutStore } = useStores()
  const { adminProjectStore, adminUserStore, adminReportStore } = useStores()
  const { projectNameList } = adminProjectStore
  const { userNameList } = adminUserStore
  const { timesheetList, haveData, choosedDate } = adminReportStore
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const [expandedRows, setExpandedRows] = useState([])

  const location = useLocation()
  const history = useHistory()

  const searchParams = new URLSearchParams(location.search)
  const projectParam = searchParams.get('project')
  const usersParam = searchParams.getAll('user')
  const periodParam = searchParams.get('period')
  const dateParam = searchParams.get('date')

  function getValidationSchema() {
    const schema = yup.object({
      selectedProject: yup.object().required(Messages.fieldRequired),
      selectedUser: yup.array().required(Messages.fieldRequired),
    })
    return schema
  }

  const methods = useForm<IOverviewFilterFormData>({
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })

  const { getValues, reset } = methods

  function handleFormResetAndFetchData() {
    const projectOptions = generateProjectOptions(projectNameList)
    const userOptions = [...createOptionsOfReactSelectFromDB(userNameList)]

    const selectedProjectOption = projectOptions.find((option) => option.value === projectParam)
    const selectedUserOptions = getSelectedUserOptions(userOptions)

    const periodOption = workingPeriodOptions.find((option) => option.value === periodParam) || workingPeriodOptions[1]

    const dateValue = dateParam ? dayjs(dateParam).toDate() : methods.getValues('date')

    reset({
      selectedProject: selectedProjectOption || null,
      selectedUser: selectedUserOptions,
      period: periodOption,
      date: dateValue,
    })

    if (selectedProjectOption) {
      const requestData = {
        projectId: selectedProjectOption.value,
        usersId: selectedUserOptions.map((user) => user.value),
        period: periodOption.value,
        date: dayjs(dateValue).format('YYYY-MM-DD'),
      }
      adminReportStore.getOverviewAnalytics(requestData)
    }
  }

  function getSelectedUserOptions(userOptions) {
    const selectedUserOptions = []
    if (usersParam.length > 0) {
      usersParam.forEach((id) => {
        const userOption = userOptions.find((option) => option.value === id)
        if (userOption) {
          selectedUserOptions.push(userOption)
        }
      })
    }
    return selectedUserOptions
  }

  const { selectedProject } = getValues()

  function selectScrollElement(elementId: string) {
    if (elementId) {
      const element = document.getElementById(elementId)
      if (element) {
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }
  }

  function getExpandedRows(timesheetId: string) {
    const getArrayOfSelectedItems = getValidArray(timesheetList).map((timesheet) => {
      return timesheet.id === timesheetId
    })
    return getArrayOfSelectedItems
  }

  function viewAnalyticHandler(data: IOverviewFilterFormData) {
    const { selectedProject, selectedUser, period, date } = data
    const newUserNameList = getValidArray(selectedUser).filter((item) => {
      return item.value !== 'all'
    })
    const usersIdData = newUserNameList.map((item) => item.value)
    const requestData = {
      projectId: selectedProject.value,
      usersId: usersIdData,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
    }
    adminReportStore.getOverviewAnalytics(requestData)

    const params = new URLSearchParams()
    params.set('project', selectedProject.value)
    if (usersIdData.length > 0) {
      usersIdData.forEach((userId) => params.append('user', userId))
    }
    params.set('period', period.value)
    params.set('date', dayjs(date).format('YYYY-MM-DD'))
    history.push({ search: params.toString() })
  }

  function getSelectedItemTimesheetFromCalendar(timesheetId: string) {
    const newExpandedRows = getExpandedRows(timesheetId)
    setExpandedRows(newExpandedRows)
    selectScrollElement(timesheetId)
  }

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(ETimesheetSubTitle.OVERVIEW)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  useEffect(() => {
    if (projectNameList.length > 0 && userNameList.length > 0) {
      handleFormResetAndFetchData()
    }
  }, [location.search, projectNameList, userNameList])

  useEffect(() => {
    if (haveData && selectedProject) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])

  useEffect(() => {
    adminProjectStore.getAllNameProjectList(EConditionGetList.ALL)
    adminUserStore.getAllNameUserList(EConditionGetList.ACTIVE)
  }, [])

  return (
    <VStack width="full">
      <FormProvider {...methods}>
        <OverviewFilterForm
          projectNameList={projectNameList}
          userNameList={userNameList}
          viewAnalyticHandler={viewAnalyticHandler}
          isAnalysisLoading={adminReportStore.isViewAnalyticsLoading}
        />
        <LoadingChakra isLoading={adminReportStore.isViewAnalyticsLoading}>
          <>
            {isValidAnalysis && (
              <>
                <OverviewCalendar
                  selectItemTimesheetHandler={getSelectedItemTimesheetFromCalendar}
                  choosedDate={choosedDate}
                />
                <OverviewReport expandedRows={expandedRows} />
                <TaskTypeAnalytics />
                <OverviewHistory />
              </>
            )}
            {!isValidAnalysis && (
              <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
                <HStack width="inherit" padding={'16px'} minHeight={'80vh'}>
                  <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
                </HStack>
              </HStack>
            )}
          </>
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(TimesheetOverview)
