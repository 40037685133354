import { useEffect, useState } from 'react'
import { HStack, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { backgroundWhite } from 'theme/globalStyles'
import * as yup from 'yup'
import { IOption } from 'constants/common'
import { EConditionGetList, ELeaveManagementSubTitle, ERoleOfUser } from 'constants/enum'
import { Messages } from 'constants/index'
import { isValidArray } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import LeaveCalendarFilterForm from './components/LeaveCalendarFilterForm'
import Wallchart from './components/Wallchart'
import { ICalendarFilterFormData, IPeriod, workingPeriodOptions } from './interfaces'

const LeaveCalendar = () => {
  const { layoutStore } = useStores()
  const userRole = window.localStorage.getItem('userRole')
  const { userProfileStore, userProjectStore, adminProjectStore, userLeaveRequestStore, adminLeaveRequestStore } =
    useStores()
  let projectNameList = []
  if (userRole === ERoleOfUser.ADMIN) {
    projectNameList = adminProjectStore.projectNameList
  } else {
    projectNameList = userProjectStore.projectNameList
  }
  let currentLeaveRequestStore
  if (userRole === ERoleOfUser.ADMIN) {
    currentLeaveRequestStore = adminLeaveRequestStore
  } else {
    currentLeaveRequestStore = userLeaveRequestStore
  }
  const { isLoadingList, leaveRequestsByProjects } = currentLeaveRequestStore
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const methods = useForm<ICalendarFilterFormData>({
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })
  const formValues = methods.watch()

  function getValidationSchema() {
    const schema = yup.object({
      selectedProject: yup.array().required(Messages.fieldRequired),
    })
    return schema
  }

  async function viewCalendarHandler() {
    const transformedFormData = {
      projectIds: formValues.selectedProject?.map((project) => project.value),
      date: dayjs(formValues.date).format('YYYY-MM-DD'),
    }
    try {
      if (userRole === ERoleOfUser.ADMIN) {
        await adminLeaveRequestStore.adminGetLeaveRequestsByProjects(transformedFormData)
      } else {
        await userLeaveRequestStore.userGetLeaveRequestsByProjects(transformedFormData)
      }
      setIsValidAnalysis(true)
    } catch (error) {
    } finally {
      setIsValidAnalysis(true)
    }
  }

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(ELeaveManagementSubTitle.CALENDAR)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  useEffect(() => {
    function fetchActiveProjects() {
      if (userRole === ERoleOfUser.ADMIN) {
        adminProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
      } else {
        userProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
      }
    }
    fetchActiveProjects()
  }, [userRole, userProjectStore, adminProjectStore])

  useEffect(() => {
    function fetchProfileDetail() {
      userProfileStore.getProfileDetail()
    }
    fetchProfileDetail()
  }, [userProfileStore])

  useEffect(() => {
    if (isValidArray(projectNameList)) {
      const defaultOption: IOption[] = [...createOptionsOfReactSelectFromDB(projectNameList)]
      methods.setValue('selectedProject', defaultOption)
    }
  }, [projectNameList])

  useEffect(() => {
    if (isValidArray(formValues.selectedProject)) {
      viewCalendarHandler()
    }
  }, [formValues.selectedProject])

  return (
    <VStack width="full">
      <FormProvider {...methods}>
        <LeaveCalendarFilterForm
          isLoading={isLoadingList}
          viewCalendarHandler={viewCalendarHandler}
          projectNameList={projectNameList}
        />
        <LoadingChakra isLoading={isLoadingList}>
          {isValidAnalysis && leaveRequestsByProjects.length > 0 ? (
            <Wallchart
              selectedDate={formValues.date}
              period={formValues.period as IPeriod}
              isSupervisor={userProfileStore.isSupervisor ?? false}
              leaveRequestsByProjects={leaveRequestsByProjects}
            />
          ) : (
            <HStack width="full" background={backgroundWhite} minHeight="80vh">
              <EmptyContentBox text={Messages.requireApplyFilterCalendar} position="bottom" />
            </HStack>
          )}
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(LeaveCalendar)
