import { ChangeEvent, useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import { deleteFileCv, uploadFileCv } from 'API/upload'
import { IUploadFileResponse } from 'API/upload/constants'
import { getCvFileAPI } from 'API/user'
import { Document, Page, pdfjs } from 'react-pdf'
import { toast } from 'react-toastify'
import { EUploadFileType } from 'types/upload'
import { EProfileTab } from 'containers/Profile/constants'
import EmptyCV from 'containers/Profile/ProfileDetail/components/CurriculumVitae/components/EmptyCV'
import { useStores } from 'utils/hooks/useStores'
import ButtonGroupUploadCV from './components/ButtonGroupUploadCV/Index'
import { ICurriculumVitae } from './interfaces'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const CurriculumVitaeDetail = (props: ICurriculumVitae) => {
  const [numPages, setNumPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [disabledPre, setDisabledPre] = useState(false)
  const [disabledNext, setDisabledNext] = useState(false)
  const [cvFile, setCVFile] = useState<File | null>(null)
  const { isShowButton, cvFileName, cvFilePreview, hideButton } = props
  const { layoutStore } = useStores()

  const title: string = `${EProfileTab.CURRICULUM_VITAE} User Detail`

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(title)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  useEffect(() => {
    checkDisabledNavBtn()
  }, [pageNumber, numPages])

  function checkDisabledNavBtn() {
    let isDisabledPre = true
    let isDisabledNext = true
    if (numPages > 1) {
      if (pageNumber === 1) {
        isDisabledNext = false
      } else if (pageNumber === numPages) {
        isDisabledPre = false
      } else {
        isDisabledPre = false
        isDisabledNext = false
      }
    }
    setDisabledPre(isDisabledPre)
    setDisabledNext(isDisabledNext)
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  function clickNextPageHandler() {
    setPageNumber(pageNumber + 1)
  }

  function clickPrePageHandler() {
    setPageNumber(pageNumber - 1)
  }

  function toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  async function getCVFileHandler() {
    try {
      if (cvFileName) {
        const response: ISuccessResponse<BlobPart> = await getCvFileAPI(cvFileName)
        const cv: BlobPart = response.data
        const result: any = await toBase64(new Blob([cv], { type: 'application/pdf' }))
        setCVFile(result)
      }
      if (cvFilePreview) {
        setCVFile(cvFilePreview)
      }
    } catch (error) {
      handleError(error, 'src/components/CurriculumVitaeDetail/index.tsx', 'getCVFileHandler')
    }
  }

  async function handleChangeFile(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    try {
      const file: File = event?.target?.files[0]
      const formData = new FormData()
      formData.append('myFile', file)
      const response: ISuccessResponse<IUploadFileResponse> = await uploadFileCv(formData, EUploadFileType.CV)
      if (!!event?.target?.files?.[0]) {
        !!event?.target?.files?.[0] && setCVFile(event?.target?.files?.[0])
      }
      const messages: string = response.data.messages
      toast.success(messages)
    } catch (error) {
      toast.error('Upload CV failed')
      handleError(error, 'src/components/CurriculumVitaeDetail/index.tsx', 'handleChangeFile')
    }
  }

  async function handleDeleteCV(): Promise<void> {
    try {
      await deleteFileCv(EUploadFileType.CV)
      setCVFile(null)
      if (hideButton) {
        hideButton()
      }
      toast.success('Delete CV successfully')
    } catch (error) {
      toast.error('Delete CV failed')
      handleError(error, 'src/components/CurriculumVitaeDetail/index.tsx', 'handleDeleteCV')
    }
  }

  useEffect(() => {
    getCVFileHandler()
  }, [cvFileName])

  return (
    <Box alignContent={'center'}>
      {!(isShowButton && cvFile) ? (
        ''
      ) : (
        <ButtonGroupUploadCV
          handleChangeFile={handleChangeFile}
          handleDeleteCV={handleDeleteCV}
          isDisable={cvFilePreview ? false : true}
          file={cvFilePreview}
        />
      )}
      {cvFile ? (
        <div className={`box-body`}>
          <Document
            file={cvFile}
            onLoadSuccess={onDocumentLoadSuccess}
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}>
            <Page size="A4" orientation={'portrait'} pageNumber={pageNumber} />
          </Document>
          {numPages && (
            <div className="pagination-cv-page">
              <div className="pagination-sub">
                <span className={`nav-button ${disabledPre ? 'disabled' : ''}`} onClick={clickPrePageHandler}>
                  <span className="double-arrow">«</span>
                </span>
                <p>
                  Page {pageNumber} of {numPages}
                </p>
                <span className={`nav-button ${disabledNext ? 'disabled' : ''}`} onClick={clickNextPageHandler}>
                  <span className="double-arrow">»</span>
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <EmptyCV />
      )}
    </Box>
  )
}

export default CurriculumVitaeDetail
