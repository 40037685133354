import { useEffect, useState } from 'react'
import { VStack } from '@chakra-ui/layout'
import { ReactComponent as CloseQuickAccessIcon } from 'assets/images/icon/closeQuickAccessIcon.svg'
import { ReactComponent as DashboardIcon } from 'assets/images/icon/dashboardQuickAccessIcon.svg'
import { ReactComponent as HeatmapIcon } from 'assets/images/icon/heatmapQuickAccessIcon.svg'
// TODO: Use when implement filter by query string in related page
// import { ReactComponent as DiagramIcon } from 'assets/images/icon/diagramQuickAccessIcon.svg'
// import { ReactComponent as HistoryIcon } from 'assets/images/icon/historyQuickAccessIcon.svg'
// import { ReactComponent as PaymentIcon } from 'assets/images/icon/paymentQuickAccessIcon.svg'
// import { ReactComponent as ProjectsIcon } from 'assets/images/icon/projectsQuickAccessIcon.svg'
import { ReactComponent as QuickAccessIcon } from 'assets/images/icon/quickAccessIcon.svg'
import { get } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { ERoleOfUser } from 'constants/enum'
import { frontendRoutes } from 'constants/routes'
import AvatarBubble from './component/AvatarBubble'
import QuickAccessItem from './component/QuickAccessItem'
const USER_DETAIL_PATH = '/user-page/detail/:id'

export interface QuickAccessBubbleProps {
  currentPath: string
}

const QuickAccessBubble = (props: QuickAccessBubbleProps) => {
  const { currentPath } = props
  const [isOpen, setIsOpen] = useState(false)
  const [isShowQuickAccessBubble, setIsShowQuickAccessBubble] = useState(false)
  const userIdInQuickAcessBubble = localStorage.getItem('userIdInQuickAcessBubble')
  const [userId, setUserId] = useState(userIdInQuickAcessBubble)
  const history = useHistory()
  const params = useParams()
  const queryParams = new URLSearchParams(history.location.search)
  const isUserDetailPage = currentPath === USER_DETAIL_PATH
  const isUserDashboardPage = currentPath === frontendRoutes.userDashboardPage.detail.id.value
  const isAdmin = window.localStorage.getItem('userRole') === ERoleOfUser.ADMIN

  function toggleQuickAccessList() {
    setIsOpen(!isOpen)
  }

  function handleQuickAccessToUserDashboard() {
    const pathname = `${frontendRoutes.userDashboardPage.detail.value}/${userId}/?isShowQuickAccessBubble=true`
    history.push(pathname, { userId })
  }

  function handleQuickAccessToHeatmap() {
    const pathname = `${frontendRoutes.adminDashboardPage.heatmap.value}?typeOfFilter=filter%20by%20user&user=${userId}&isShowQuickAccessBubble=true`
    history.push(pathname, { userId })
  }

  useEffect(() => {
    setIsShowQuickAccessBubble(JSON.parse(queryParams.get('isShowQuickAccessBubble')))
  }, [history.location])

  useEffect(() => {
    if (isUserDetailPage || isUserDashboardPage) {
      setUserId(get(params, 'id', ''))
      localStorage.setItem('userIdInQuickAcessBubble', get(params, 'id', ''))
    }
  }, [history.location])

  if (!isShowQuickAccessBubble || !isAdmin) {
    return null
  }
  return (
    <VStack
      position="fixed"
      zIndex="100"
      bottom={{ sm: '18px', lg: '30px' }}
      right={{ sm: '16px', lg: '30px' }}
      gap={'8px'}>
      {isOpen && (
        <VStack gap="0">
          {!(currentPath === frontendRoutes.userDashboardPage.detail.id.value) && (
            <QuickAccessItem label="Dashboard" icon={DashboardIcon} onClick={handleQuickAccessToUserDashboard} />
          )}
          // TODO: Use when implement filter by query string in related page
          {/* <QuickAccessItem label="Projects" icon={ProjectsIcon} />
            <QuickAccessItem label="Diagram" icon={DiagramIcon} /> */}
          {!(currentPath === frontendRoutes.adminDashboardPage.heatmap.value) && (
            <QuickAccessItem label="Heatmap" icon={HeatmapIcon} onClick={handleQuickAccessToHeatmap} />
          )}
          // TODO: Use when implement filter by query string in related page
          {/* <QuickAccessItem label="History" icon={HistoryIcon} />
            <QuickAccessItem label="Payment" icon={PaymentIcon} /> */}
        </VStack>
      )}
      <VStack>
        {!isOpen && <QuickAccessItem label="Quick Access" icon={QuickAccessIcon} onClick={toggleQuickAccessList} />}
        {isOpen && <QuickAccessItem icon={CloseQuickAccessIcon} onClick={toggleQuickAccessList} />}
      </VStack>
      {!isUserDetailPage && <AvatarBubble userId={userId} />}
    </VStack>
  )
}
export default QuickAccessBubble
