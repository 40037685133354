import { useState } from 'react'
import { VStack, Select, Stack, Box } from '@chakra-ui/react'
import InfoBox from 'components/InfoBox'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { ChartListForTimeAndMaterialProject, ChartListForMonthlyFixedScopeProject } from 'constants/defaultOptionList'
import { EChartType } from 'constants/enum'
import { EPaymentCategoryFromClientOfProject } from 'constants/enum'
import { useStores } from 'utils/hooks/useStores'
import { getFormattedNumber } from 'utils/numberUtils'
import { ReactComponent as Salary } from '../../../../assets/images/svg/black-salary.svg'
import { ReactComponent as WorkingTime } from '../../../../assets/images/svg/clock.svg'
import { ReactComponent as Profit } from '../../../../assets/images/svg/profit.svg'
import { ReactComponent as Revenue } from '../../../../assets/images/svg/revenue.svg'
import { ReactComponent as Developer } from '../../../../assets/images/svg/user-profile.svg'
import { renderChart } from '../../container'

const StatisticContentForAdmin = () => {
  const { adminDashboardStore } = useStores()
  const [selectedChart, setSelectedChart] = useState(EChartType.TIME_TOTAL_CHART)
  const [expandedChart, setExpandedChart] = useState(false)
  const { chartIndicators, projectData, options, revenueChartData } = adminDashboardStore
  const paymentCategoryFromClient = projectData?.projectPaymentCategoryFromClient
  let chartListOptions = []
  let formattedProjectData = toJS(projectData)

  switch (paymentCategoryFromClient) {
    case EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL:
      chartListOptions = ChartListForTimeAndMaterialProject
      break
    case EPaymentCategoryFromClientOfProject.MONTHLY_FIXED_SCOPE:
      chartListOptions = ChartListForMonthlyFixedScopeProject
      break
    default:
      break
  }
  const expandChartHandler = () => {
    setExpandedChart(true)
  }

  const selectChartHandler = (event) => {
    setSelectedChart(event.target.value)
    setExpandedChart(false)
  }

  const reduceChartHandler = () => {
    setExpandedChart(false)
  }

  return (
    <VStack width={'full'}>
      <VStack width="inherit">
        <Stack justifyContent={'space-around'} width={'inherit'} direction={{ sm: 'column', lg: 'row' }}
          overflowX={'auto'}
          sx={{
            '&::-webkit-scrollbar': {
              width: '3px',
              height: '3px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#d9d9d9',
              borderRadius: '10px',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'thin',
          }}>
          <InfoBox
            title="Revenue"
            value={getFormattedNumber(formattedProjectData.revenue, 1)}
            isShowPercent={false}
            key="revenue"
            icon={<Revenue width="30px" height="30px" style={{ alignSelf: 'flex-start' }} />}
          />
          <InfoBox
            title="Salary"
            value={getFormattedNumber(formattedProjectData.salary, 1)}
            isShowPercent={false}
            key="salary"
            icon={<Salary width="30px" height="30px" style={{ alignSelf: 'flex-start' }} />}
          />
          <InfoBox
            title="Profit"
            value={getFormattedNumber(formattedProjectData.profit, 1)}
            isShowPercent={false}
            key="profit"
            icon={<Profit width="30px" height="30px" style={{ alignSelf: 'flex-start' }} />}
          />
          <InfoBox
            title="Human Resources"
            value={Number(projectData.workingDeveloper).toFixed(1)}
            isShowPercent={false}
            icon={<Developer width="30px" height="30px" style={{ alignSelf: 'flex-start' }} />}
          />
          <InfoBox
            title="Working Time Total"
            value={Number(projectData.workingHours).toFixed(1)}
            isShowPercent={false}
            key="workingTimeTotal"
            icon={<WorkingTime width="30px" height="30px" style={{ alignSelf: 'flex-start' }} />}
          />
        </Stack>
      </VStack>
      <VStack background="white" width={'inherit'}>
        {chartIndicators && (
          <Stack className="chart-section" width="full">
            <Box padding="20px">
              <Select placeholder="Select Chart" onChange={selectChartHandler} value={selectedChart} width={'inherit'}>
                {chartListOptions &&
                  chartListOptions.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    )
                  })}
              </Select>
            </Box>
            <Box className="chart">
              {renderChart(
                selectedChart,
                expandedChart,
                options,
                expandChartHandler,
                reduceChartHandler,
                chartIndicators,
                projectData,
                revenueChartData?.forTimeAndMaterialProject,
                revenueChartData?.forMonthlyFixedScopeProject
              )}
            </Box>
          </Stack>
        )}
      </VStack>
    </VStack>
  )
}

export default observer(StatisticContentForAdmin)
