import { useEffect } from 'react'
import { Container, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import LabelForm from 'components/LabelForm'
import { backgroundGrey100 } from 'theme/globalStyles'
import { IUser } from 'types/user'
import { useStores } from 'utils/hooks/useStores'
import { TitlePages } from '../../../../constants'
import { getValidDevelopers } from '../ProjectDetailForm/utils'
import AvatarDevGroup from './components/avatarDevGroup'
import styles from './index.module.scss'
const ProjectDetailForm = (props) => {
  const { methods } = props
  const { getValues } = methods
  const listDeveloper = getValues('developerDetailOnProject')
  const validDevelopers: IUser[] = getValidDevelopers(listDeveloper)
  const untilNow = getValues('untilNow')
  const { layoutStore } = useStores()

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(TitlePages.PROJECT_DETAIL)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])
  return (
    <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
      <VStack
        background={{ sm: backgroundGrey100, base: 'white', lg: 'white' }}
        padding={{ md: 0, lg: 6 }}
        borderRadius={{ md: 8 }}
        gap="12px"
        overflow="hidden">
        <VStack
          width="full"
          padding={{ lg: '0px 8px' }}
          background="white"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          borderRadius={{ sm: 0, md: '8px' }}>
          <Text alignSelf={'flex-start'} className={styles.headerBox}>
            General Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="name" label="Project Name" readonly={true} />
            <LabelForm name="companyName" label="Company Name" readonly={true} />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="companyWebsite" label="Company Website" isHighligh={true} readonly={true} />
            <LabelForm name="companyPhone" label="Company Phone Number" isHighligh readonly={true} />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="country" label="Country" readonly={true} />
            <LabelForm name="companyPhone" label="Address Detail" readonly={true} />
          </SimpleGrid>
        </VStack>
        <VStack
          width="full"
          padding={{ lg: '0px 8px' }}
          background="White"
          paddingLeft={{ lg: '0px', md: '10px', sm: '10px' }}
          borderRadius={{ md: '8px' }}>
          <Text alignSelf={'flex-start'} margin-top="unset" className={`${styles.headerBox} ${styles.border}`}>
            Working Information
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="startedDate" label="Start Date" readonly={true} />
            {!untilNow && <LabelForm name="endedDate" label="End Date" readonly={true} />}
            {untilNow && <LabelForm name="untilNowProject" label="Until Now" readonly={true} />}
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="commitedHours" label="Commited Hours (hrs/week)" readonly={true} />
            <LabelForm name="technology" label="Technology" readonly={true} isTagListFormat />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 6 }} width="full">
            <LabelForm name="neededDeveloperNumber" label="Needed Developer Number" readonly={true} />
            <AvatarDevGroup name="listDeveloper" data={validDevelopers} label="Working Developers" />
          </SimpleGrid>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
            <LabelForm name="partnerId" label="Belongs To Partner" readonly={true} />
            <LabelForm name="type" label="Project Type" isTagFormat readonly={true} isTagListFormat />
          </SimpleGrid>
        </VStack>
      </VStack>
    </Container>
  )
}
export default ProjectDetailForm
