import { Search2Icon } from '@chakra-ui/icons'
import { Flex, HStack, Input, InputGroup, InputLeftElement, useMediaQuery, Tooltip, Box } from '@chakra-ui/react'
import Button from 'components/Button'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { backgroundGrey200 } from 'theme/globalStyles'
import { maxMobileSize } from 'constants/common'
import { ERoleOfUser } from 'constants/enum'
interface IHeaderSectionProps {
  handleCreate: () => void
  toggleOpen: () => void
  toggleExport: () => void
  changeName: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const history = useHistory()
  const { handleCreate, toggleOpen, changeName } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const initialFilter = queryString.parse(history.location.search)
  const name = initialFilter?.normalizedFullName
  const isAdmin = window.localStorage.getItem('userRole') === ERoleOfUser.ADMIN

  return (
    <Flex justifyContent="space-between" flexWrap="wrap" paddingX={isMobile ? 4 : 0} paddingTop={isMobile ? 4 : 0}>
      <HStack marginBottom={6}>
        <InputGroup
          border={`1px solid ${backgroundGrey200}`}
          borderRadius="6px"
          background="white"
          width={isMobile ? '160px' : '400px'}>
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="gray.400" />
          </InputLeftElement>
          <Input
            type="search"
            placeholder={isMobile ? 'Search' : 'Search Users by name...'}
            onChange={changeName}
            border="none"
            defaultValue={name}
          />
        </InputGroup>
        <Button leftIcon="filter.svg" variant="outline" onClick={toggleOpen}>
          {!isMobile && 'Filter'}
        </Button>
      </HStack>
      {isAdmin && (
        <HStack marginBottom={6}>
          <Tooltip
            label="Feature coming soon"
            hasArrow={true}
            sx={{
              fontSize: 'md',
              px: 4,
              py: 2,
              borderRadius: 'md',
              boxShadow: 'lg',
            }}>
            <Box>
              <Button leftIcon="export.svg" variant="outline" disabled>
                {!isMobile && 'Export'}
              </Button>
            </Box>
          </Tooltip>
          <Button variant="solid" leftIcon="add-white.svg" onClick={handleCreate}>
            {!isMobile && 'Create New User'}
          </Button>
        </HStack>
      )}
    </Flex>
  )
}

export default HeaderSection
