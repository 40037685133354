import { useEffect, useState } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { IOption } from 'constants/common'
import { EConditionGetList, ETimesheetSubTitle, ETypeOfFilter } from 'constants/enum'
import { allValue } from 'constants/index'
import { Messages } from 'constants/index'
import { EFilterType } from 'containers/Dashboard/enum'
import { getArrayValueFromParsedQuery, getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { generateProjectOptions, generateUserOptions } from 'utils/user'
import ExportDataModal from '../components/ExportDataModal'
import SupervisorFilterForm from '../components/SupervisorFilterForm'
import SupervisorReport from '../components/SupervisorReportSection'
import { workingPeriodOptions, IReportFilterWithProjectFormData, IReportFilterWithUserFormData } from '../constants'
import { IFilter } from './interface'

const Supervisor = () => {
  const { layoutStore } = useStores()
  const history = useHistory()

  const { userProjectStore, adminUserStore, userReportStore, userProfileStore } = useStores()
  const { isSupervisor } = userProfileStore
  const { projectNameListForSupervisor } = userProjectStore
  const { userNameListOfSupervisor } = adminUserStore
  const { haveData, isGetSupervisorReportLoading, reportListDataOfSupervisor } = userReportStore

  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const [isOpenExportDataModal, setIsOpenExportDataModal] = useState(false)
  const [typeOfFilter, setTypeOfFilter] = useState<ETypeOfFilter>(ETypeOfFilter.FILTER_BY_PROJECT)

  const methods = useForm({
    defaultValues: {
      isFilterByProject: true,
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })

  const { getValues, setValue } = methods

  function openExportDataModalHandler() {
    setIsOpenExportDataModal(true)
  }

  function exportClickHandler() {
    const { selectedProjectsToExport, selectedUser, period, date } = getValues()
    const newUserNameList = getValidArray(selectedUser).filter((item) => {
      return item.value !== 'all'
    })
    const usersIdData = newUserNameList.map((item) => item.value)
    const newProjectNameList = getValidArray(selectedProjectsToExport).filter((item) => {
      return item.value !== 'all'
    })
    const projectIdsData = newProjectNameList.map((item) => item.value)
    const requestData = {
      projectsId: projectIdsData,
      usersId: usersIdData,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
    }
    userReportStore.getExportDataForSupervisor(requestData)
    closeExportDataModalHandler()
  }

  function closeExportDataModalHandler() {
    setIsOpenExportDataModal(false)
  }

  const queryStringInURL = history.location.search
  const parseQueryString = queryString.parse(queryStringInURL)

  const initialFilter = {
    ...parseQueryString,
    projectsId: getArrayValueFromParsedQuery(parseQueryString, 'projectsId'),
    usersId: getArrayValueFromParsedQuery(parseQueryString, 'usersId'),
  }

  const [filter, setFilter] = useState<IFilter>(initialFilter ?? {})

  function reportWithProjectHandler(data: IReportFilterWithProjectFormData): void {
    const { selectedProject, selectedUser, period, date, isFilterByProject } = data
    const projectsId = [selectedProject?.value]
    const newUserNameList = getValidArray(selectedUser).filter((item) => {
      return item.value !== allValue
    })
    const usersId = newUserNameList.map((item) => item.value)
    const requestData = {
      projectsId,
      usersId,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
      filterType: isFilterByProject ? EFilterType.FILTER_BY_PROJECT : EFilterType.FILTER_BY_USER,
    }
    setFilter(requestData)
  }

  function reportWithUserHandler(data: IReportFilterWithUserFormData): void {
    const { selectedProject, selectedUser, period, date, isFilterByProject } = data
    const usersId = [selectedUser?.value]
    const newProjectNameList = getValidArray(selectedProject).filter((item) => {
      return item.value !== allValue
    })
    const projectsId = newProjectNameList.map((item) => item.value)
    const requestData = {
      projectsId,
      usersId,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
      filterType: isFilterByProject ? EFilterType.FILTER_BY_PROJECT : EFilterType.FILTER_BY_USER,
    }
    setFilter(requestData)
  }

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(ETimesheetSubTitle.SUPERVISOR)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  useEffect(() => {
    if (filter?.filterType === EFilterType.FILTER_BY_PROJECT) {
      setTypeOfFilter(ETypeOfFilter.FILTER_BY_PROJECT)
      const projectOptions: IOption[] = generateProjectOptions(projectNameListForSupervisor)
      const selectedProjectOption = projectOptions.find((option) => option.value === filter?.projectsId[0])
      setValue('selectedProject', selectedProjectOption)
      const userOptions: IOption[] = generateUserOptions(userNameListOfSupervisor)
      const selectedUserOptions = userOptions.filter((option) => filter?.usersId?.includes(option.value))
      setValue('selectedUser', selectedUserOptions)
      setValue('isFilterByProject', true)
      history.push({
        search: queryString.stringify(filter),
      })
      userReportStore.getSupervisorReport(filter)
    }
    if (filter?.filterType === EFilterType.FILTER_BY_USER) {
      setTypeOfFilter(ETypeOfFilter.FILTER_BY_USER)
      const userOptions: IOption[] = generateUserOptions(userNameListOfSupervisor)
      const selectedUserOption = userOptions.find((option) => option.value === filter?.usersId[0])
      setValue('selectedUser', selectedUserOption)
      const projectOptions: IOption[] = generateUserOptions(projectNameListForSupervisor)
      const selectedProjectOptions = projectOptions.filter((option) => filter?.projectsId?.includes(option.value))
      setValue('selectedProject', selectedProjectOptions)
      setValue('isFilterByProject', false)
      history.push({
        search: queryString.stringify(filter),
      })
      userReportStore.getSupervisorReport(filter)
    }
  }, [filter, projectNameListForSupervisor, userNameListOfSupervisor])

  useEffect(() => {
    userProjectStore.getNameProjectListForSupervisor(EConditionGetList.ACTIVE)
    adminUserStore.getAllNameUserListOfSupervisor(EConditionGetList.ACTIVE)
  }, [])

  useEffect(() => {
    if (haveData) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])
  if (!isSupervisor) {
    return null
  }
  return (
    <VStack width="full">
      <FormProvider {...methods}>
        <SupervisorFilterForm
          projectNameList={projectNameListForSupervisor}
          isAnalysisLoading={isGetSupervisorReportLoading}
          userNameList={userNameListOfSupervisor}
          reportWithProjectHandler={reportWithProjectHandler}
          reportWithUserHandler={reportWithUserHandler}
          exportDataHandler={openExportDataModalHandler}
          typeOfFilter={typeOfFilter}
          setTypeOfFilter={setTypeOfFilter}
        />
        <LoadingChakra isLoading={isGetSupervisorReportLoading}>
          {isValidAnalysis &&
            getValidArray(reportListDataOfSupervisor).map((reportData) => {
              return <SupervisorReport reportData={reportData} />
            })}
          {!isValidAnalysis && (
            <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
              <HStack width="inherit" padding={'16px'} minHeight={'80vh'}>
                <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
              </HStack>
            </HStack>
          )}
        </LoadingChakra>
        <ExportDataModal
          isOpen={isOpenExportDataModal}
          projectNameList={projectNameListForSupervisor}
          closeHandler={closeExportDataModalHandler}
          exportClickHandler={exportClickHandler}
        />
      </FormProvider>
    </VStack>
  )
}

export default observer(Supervisor)
