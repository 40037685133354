import { ModalFooter } from '@chakra-ui/modal'
import Button from 'components/Button'
import { borderGray200 } from 'theme/globalStyles'
import { ILeaveRequestModalFooterProps } from './interface'

const LeaveRequestModalFooter = (props: ILeaveRequestModalFooterProps) => {
  const { isEdit, isLoading, onCancel } = props
  return (
    <ModalFooter borderTop="1px solid" borderColor={borderGray200}>
      <Button
        disabled={isLoading}
        isLoading={isLoading}
        variant="outline"
        onClick={onCancel}
        customStyles={{ marginRight: '16px' }}>
        Cancel
      </Button>
      <Button disabled={isLoading} isLoading={isLoading} variant="solid" type="submit">
        {isEdit ? 'Save' : 'Submit'}
      </Button>
    </ModalFooter>
  )
}

export default LeaveRequestModalFooter
