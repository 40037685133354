import { useEffect, useState } from 'react'
import { FormControl } from '@chakra-ui/form-control'
import { Box, SimpleGrid } from '@chakra-ui/layout'
import { FormErrorMessage, FormLabel, HStack, InputGroup, Text, VStack } from '@chakra-ui/react'
import DatePicker from 'components/DatePicker'
import Editor from 'components/Editor'
import FormInput from 'components/FormInput'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { Controller, useWatch } from 'react-hook-form'
import { textGrey700, textBlue600 } from 'theme/globalStyles'
import { ELeaveType, ETimeSelection } from 'constants/enum'
import { Messages } from 'constants/index'
import { stripHtmlTags } from 'utils/commonUtils'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import styles from '../../styles.module.scss'
import { ILeaveRequestFormProps } from './interface'

const LeaveRequestForm = (props: ILeaveRequestFormProps) => {
  const { methods, selectedLeaveRequest, leaveTypeOptions } = props
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    clearErrors,
  } = methods

  const [selectedLeaveType, setSelectedLeaveType] = useState(selectedLeaveRequest?.leaveTypeId)
  const leaveType = useWatch({ control, name: 'type' })
  const timeOptions = createOptionsOfSelect([ETimeSelection.EIGHT_AM, ETimeSelection.ONE_PM, ETimeSelection.FIVE_PM])

  const timeOptionsForStartTime = timeOptions?.map((item) => ({
    ...item,
    isDisabled: item.value === ETimeSelection.FIVE_PM,
  }))

  const timeOptionsForEndTime = timeOptions?.map((item) => ({
    ...item,
    isDisabled: item.value === ETimeSelection.EIGHT_AM,
  }))

  function handleLeaveTypeChange(value: string) {
    setSelectedLeaveType(value)
    if (value !== ELeaveType.REMOTE) {
      clearErrors('commitTime')
      methods.setValue('commitTime', '')
    }
  }

  useEffect(() => {
    if (leaveType) {
      handleLeaveTypeChange(leaveType.label)
    }
  }, [leaveType])

  useEffect(() => {
    if (isSubmitSuccessful) {
      setSelectedLeaveType(ELeaveType.SICK)
    }
  }, [isSubmitSuccessful])

  return (
    <Box padding={4}>
      <SimpleGrid columns={1} gap="24px">
        <FormControl>
          <SingleSelect
            name="type"
            optionsData={leaveTypeOptions}
            label="Leave Type"
            placeHolder="Choose leave type..."
            isRequire
          />
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.commitTime)}>
          <FormInput
            name="commitTime"
            label="Commit time (remote leave only)"
            placeholder="Enter your commit time"
            disabled={selectedLeaveType !== ELeaveType.REMOTE}
            isRequired={selectedLeaveType === ELeaveType.REMOTE}
            type="number"
          />
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.startDate || errors?.startTime)}>
          <FormLabel color={textGrey700} fontWeight={400} marginBottom={0}>
            Start Date
          </FormLabel>
          <VStack alignItems="flex-start">
            <HStack
              height={10}
              marginTop="20px !important"
              width="100%"
              marginBottom={Boolean(errors?.startDate?.message || errors?.startTime?.message) ? '15px' : '0'}>
              <HStack width="50%">
                <Controller
                  control={control}
                  name="startDate"
                  rules={{ required: Messages.requireStartDate }}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      isRequired={true}
                    />
                  )}
                />
              </HStack>
              <HStack width="50%">
                <InputGroup>
                  <SingleSelect
                    name="startTime"
                    placeHolder="Choose time..."
                    optionsData={timeOptionsForStartTime}
                    displayError={false}
                  />
                </InputGroup>
              </HStack>
            </HStack>
            <FormErrorMessage>{errors?.startDate?.message || errors?.startTime?.message}</FormErrorMessage>
          </VStack>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.endDate || errors?.endTime)}>
          <FormLabel color={textGrey700} fontWeight={400} marginBottom={0}>
            End Date
          </FormLabel>
          <VStack alignItems="flex-start">
            <HStack
              height={10}
              marginTop="20px !important"
              width="100%"
              marginBottom={Boolean(errors?.endDate?.message || errors?.endTime?.message) ? '15px' : '0'}>
              <HStack width="50%">
                <Controller
                  control={control}
                  name="endDate"
                  rules={{ required: 'End date is required' }}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : undefined}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      isRequired={true}
                    />
                  )}
                />
              </HStack>
              <HStack width="50%">
                <InputGroup>
                  <SingleSelect
                    name="endTime"
                    placeHolder="Choose time..."
                    optionsData={timeOptionsForEndTime}
                    displayError={false}
                  />
                </InputGroup>
              </HStack>
            </HStack>
            <FormErrorMessage>{errors?.endDate?.message || errors?.endTime?.message}</FormErrorMessage>
          </VStack>
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.content)}>
          <FormLabel
            color={textGrey700}
            fontWeight={400}
            display={'flex'}
            columnGap={4}
            marginRight={0}
            marginBottom={0}
            alignItems={'center'}
            className={styles.wrapTextInMobile}>
            Reason
            <Text color={textBlue600} fontSize={'sm'} margin={0} padding={0}>
              Your reason must be greater than 20 characters and less than 200 characters
            </Text>
          </FormLabel>
          <Controller
            control={control}
            name="content"
            rules={{
              validate: {
                minLength: (value) => stripHtmlTags(value).length >= 20 || Messages.minReasonLength,
                maxLength: (value) => stripHtmlTags(value).length <= 200 || Messages.maxReasonLength,
              },
            }}
            render={({ field }) => <Editor {...field} placeholder="Enter your reason" isRequired />}
          />
        </FormControl>
      </SimpleGrid>
    </Box>
  )
}

export default LeaveRequestForm
