import { useEffect } from 'react'
import { FormControl, FormLabel, Stack, Radio, RadioGroup, FormErrorMessage } from '@chakra-ui/react'
import { capitalize, get } from 'lodash'
import { observer } from 'mobx-react'
import { useFormContext, useWatch, Controller } from 'react-hook-form'
import { getValidArray } from 'utils/commonUtils'

export interface IGroupRadio {
  name: string
  label?: any
  isEdit?: boolean
  data?: string[]
  defaultValue?: string
  onChange?: (value: string | null) => void
  disabled?: boolean
}

const GroupRadio = (props: IGroupRadio) => {
  const { name, label, data, onChange, defaultValue, disabled } = props
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()

  const currentValue: string = useWatch({ control, name, defaultValue })

  useEffect(
    function handleWatchChange() {
      onChange?.(watch(name))
    },
    [watch(name), onChange]
  )

  return (
    <FormControl
      id={name}
      width="full"
      padding={{ sm: '0px', md: '10px', lg: '0' }}
      isDisabled={disabled}
      isInvalid={!!get(errors, name, false)}>
      <FormLabel fontWeight={'normal'} mb={2} color="gray.700" paddingBottom="12px">
        {label}
      </FormLabel>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <RadioGroup {...field} value={currentValue ?? ''}>
            <Stack direction="row">
              {getValidArray(data).map((item) => (
                <Radio key={item} value={item} colorScheme="blue" width="50%">
                  {capitalize(item)}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
      <FormErrorMessage>{get(errors, `${name}.message`, '')}</FormErrorMessage>
    </FormControl>
  )
}

export default observer(GroupRadio)
