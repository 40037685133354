import { ILeaveRequestByProjects } from 'types/leaveRequest'
import { ELeaveRequestStatus } from 'constants/enum'
import { getValidArray } from 'utils/commonUtils'

export function transformDataToGroupsAndItems(leaveRequests: ILeaveRequestByProjects[]) {
  leaveRequests = getValidArray(leaveRequests)

  const userMap = new Map()
  leaveRequests.forEach((request) => {
    if (request.createdByUser) {
      userMap.set(request.createdByUser.fullName, {
        fullName: request.createdByUser.fullName,
        partnerCategory: request.createdByUser.partnerCategory,
        totalLeaveRequests: request.createdByUser.totalLeaveRequests,
        ...(request.createdByUser.remainLeaveDays !== undefined && {
          remainingDays: request.createdByUser.remainLeaveDays,
        }),
      })
    }
  })

  const groups = Array.from(userMap.values()).map((userInfo, index) => ({
    id: index + 1,
    title: userInfo.fullName,
    ...(userInfo.remainingDays !== undefined && {
      remainingDays: userInfo.remainingDays,
    }),
    partnerCategory: userInfo.partnerCategory,
    totalLeaveRequests: userInfo.totalLeaveRequests,
  }))

  const userIndexMap = new Map(groups.map((group) => [group.title, group.id]))

  const items = leaveRequests
    .filter((request) => request.status !== ELeaveRequestStatus.REJECTED)
    .map((request) => ({
      id: request._id,
      group: userIndexMap.get(request.createdByUser?.fullName) || 1,
      startTime: new Date(request.startTime),
      endTime: new Date(request.endTime),
      itemProps: {
        'data-tip': JSON.stringify({
          name: request.createdByUser?.fullName || '',
          type: request.leaveType?.name || '',
          status: request.status,
          approvalAuthority: request.decidedByUser?.fullName,
        }),
      },
    }))

  return {
    groups,
    items,
  }
}

export function adjustTimeRange(startTime: Date, endTime: Date) {
  const startHour = startTime.getHours()
  const endHour = endTime.getHours()

  let adjustedStart = new Date(startTime)
  let adjustedEnd = new Date(endTime)

  adjustedStart.setHours(0, 0, 0, 0)
  adjustedEnd.setHours(0, 0, 0, 0)

  if (startHour === 13 && endHour === 17) {
    adjustedStart.setHours(12)
    adjustedEnd.setHours(23, 59, 59)
  } else if (startHour === 8 && endHour === 13) {
    adjustedStart.setHours(0)
    adjustedEnd.setHours(11, 59, 59)
  } else if (startHour === 8 && endHour === 17) {
    adjustedStart.setHours(0)
    adjustedEnd.setHours(23, 59, 59)
  } else if (startHour === 13 && endHour === 13) {
    adjustedStart.setHours(12)
    adjustedEnd.setHours(11, 59, 59)
  }
  return {
    startTime: adjustedStart.getTime(),
    endTime: adjustedEnd.getTime(),
  }
}

export function preventScrollEvents(element: HTMLElement | null) {
  if (!element) return () => {}
  element.style.outline = 'none'

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      e.preventDefault()
    }
  }

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleScroll = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }

  element.addEventListener('keydown', handleKeyDown)
  element.addEventListener('wheel', handleWheel, { passive: false })
  element.addEventListener('scroll', handleScroll, { passive: false })

  return () => {
    element.removeEventListener('keydown', handleKeyDown)
    element.removeEventListener('wheel', handleWheel)
    element.removeEventListener('scroll', handleScroll)
  }
}
