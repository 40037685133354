import { Box, HStack, VStack } from '@chakra-ui/layout'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal'
import { Input, Text } from '@chakra-ui/react'
import TagColorCell from 'components/Table/DesktopTable/components/TagColorCell'
import { borderGray200 } from 'theme/globalStyles'
import { ITechnology } from 'types/technology'
import { ITechnologyModalProps } from './interface'

const TechnologyModal = (props: ITechnologyModalProps) => {
  const { isOpen, closeHandler, selectedUser, matchingTechnologies } = props
  return (
    <Modal isOpen={isOpen} onClose={closeHandler} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent height={'fit-content !important'}>
        <Box background="white" width="100%">
          <ModalHeader borderBottom="1px solid" borderColor={borderGray200} fontWeight={500}>
            Technology
          </ModalHeader>
          <ModalCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
        </Box>
        <ModalBody overflowX="hidden" padding="4">
          <VStack width="100%" gap="6">
            <VStack width="100%" maxWidth="100%" align="flex-start" gap="2" paddingX="4">
              <Text margin="0">User</Text>
              <Input
                boxSizing="border-box"
                margin="0"
                size="md"
                width="100%"
                maxWidth="100%"
                backgroundColor="gray.100"
                padding="4"
                disabled
                value={selectedUser?.fullName}
              />
            </VStack>
            <VStack width="100%" backgroundColor="gray.100" borderRadius="6px" padding="4" gap="3">
              <Box
                width="100%"
                display="grid"
                gridTemplateColumns={matchingTechnologies.length > 5 ? 'repeat(2, 1fr)' : '1fr'}
                gap="3">
                {matchingTechnologies?.map((tech: ITechnology) => {
                  return (
                    <HStack key={tech.value} width="100%" gap="3">
                      <TagColorCell color={tech.tagColor} />
                      <Text>{tech.value}</Text>
                    </HStack>
                  )
                })}
              </Box>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default TechnologyModal
