import React, { useEffect, useState } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import Box from 'components/Box'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { EConditionGetList, ETimesheetSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import UserFilterForm from '../components/UserFilterForm'
import UserReportSection from '../components/UserReportSection'
import { workingPeriodOptions, IReportFilterWithProjectFormData } from '../constants'

const UserReport = () => {
  const { layoutStore } = useStores()
  const { userProjectStore, userReportStore } = useStores()
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const { projectNameList } = userProjectStore
  const { haveUserReportData, isReportLoading, reportListDataOfUser } = userReportStore

  const methods = useForm({
    defaultValues: {
      isFilterByProject: true,
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })

  function reportWithProjectHandler(data: IReportFilterWithProjectFormData): void {
    const { selectedProject, period, date } = data
    const projectId = selectedProject?.value

    const requestData = {
      projectId,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
    }
    userReportStore.getUserReport(requestData)
  }

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(ETimesheetSubTitle.REPORT)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  useEffect(() => {
    userProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
  }, [])

  useEffect(() => {
    if (haveUserReportData) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveUserReportData])

  return (
    <VStack width="full" spacing={'24px'}>
      <FormProvider {...methods}>
        <UserFilterForm
          projectNameList={projectNameList}
          isAnalysisLoading={isReportLoading}
          reportWithProjectHandler={reportWithProjectHandler}
        />
        <LoadingChakra isLoading={isReportLoading}>
          {isValidAnalysis &&
            getValidArray(reportListDataOfUser).map((reportData) => {
              return <UserReportSection reportData={reportData} />
            })}
          {!isValidAnalysis && (
            <Box>
              <HStack width="inherit">
                <HStack width="inherit" padding={'16px'} minHeight={'80vh'}>
                  <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
                </HStack>
              </HStack>
            </Box>
          )}
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(UserReport)
