import { useEffect, useState } from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import GroupChips from 'components/GroupChips'
import GroupRadio from 'components/GroupRadio'
import MultipleSelect from 'components/MultipleSelect'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { get, set } from 'lodash'
import queryString from 'query-string'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { textBlue500 } from 'theme/globalStyles'
import { MonthOfYear } from 'constants/defaultOptionList'
import { EDocumentStatusLabel } from 'constants/enum'
import { ICreatableOption, IInternalDocument, IPartner } from 'constants/schema'
import { getArrayValueFromParsedQuery } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB, getOptionsSelected } from 'utils/dropdownOptionUtils'

interface IFilterProps {
  partners?: IPartner[]
  doctypes?: ICreatableOption[]
  openModalFilterForm?: boolean
  setOpenFilterForm: (status: boolean) => void
  filterSubmit?: (data: any) => void
}

const FilterForm = (props: IFilterProps) => {
  const { openModalFilterForm, setOpenFilterForm, filterSubmit, partners, doctypes } = props
  const history = useHistory()
  const methods = useForm()
  const { handleSubmit, reset, control, setValue } = methods
  const URLSearchParams = history.location.search
  const initialFilter = queryString.parse(URLSearchParams, { parseBooleans: true })
  const selectedStatus = get(initialFilter, 'status', EDocumentStatusLabel.PUBLISHED)

  const partnerOptions = createOptionsOfReactSelectFromDB(partners)
  const typeOptions = createOptionsOfReactSelectFromDB(doctypes)

  const month = getArrayValueFromParsedQuery(initialFilter, 'month') || []
  const year = get(initialFilter, 'year', null)
  const selectedPartner = getArrayValueFromParsedQuery(initialFilter, 'partnerId')
  const selectedType = getArrayValueFromParsedQuery(initialFilter, 'typeId')

  const selectMonth = getOptionsSelected(month, MonthOfYear)
  const selectedPartnerOptions = getOptionsSelected(selectedPartner, partnerOptions)
  const selectedTypeOptions = getOptionsSelected(selectedType, typeOptions)

  function closeModal(): void {
    setOpenFilterForm(false)
  }

  function resetForm() {
    reset({
      month: null,
      year: null,
      partnerId: [],
      typeId: [],
      status: EDocumentStatusLabel.PUBLISHED,
    })
  }

  useEffect(() => {
    reset({
      status: selectedStatus,
      month: selectMonth.length > 0 ? selectMonth[0] : null,
      year: year ? new Date(year) : null,
      partnerId: selectedPartnerOptions,
      typeId: selectedTypeOptions,
    })
  }, [])

  const onSubmit = (data: IInternalDocument) => {
    const filterData = { ...data }
    set(filterData, 'month', (filterData?.month as any)?.value || null)
    !filterData.month && delete filterData.month
    !filterData.year && delete filterData.year
    filterSubmit(filterData)
  }

  return (
    <HStack spacing={4} alignSelf="flex-start" marginBottom={6} display={{ base: 'none', md: 'flex' }}>
      <Drawer isOpen={openModalFilterForm} placement="right" onClose={closeModal} size={'lg'}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form className="full-form" onSubmit={handleSubmit(onSubmit)}>
            <DrawerContent>
              <DrawerCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
              <DrawerHeader>Filter</DrawerHeader>
              <div className="box-divider" />
              <DrawerBody paddingTop={6}>
                <VStack marginBottom={5}>
                  <FormControl>
                    <SimpleGrid width="full" marginBottom={5}>
                      <GroupRadio
                        name="status"
                        data={Object.values(EDocumentStatusLabel)}
                        label="Status"
                        defaultValue={selectedStatus as EDocumentStatusLabel}
                      />
                    </SimpleGrid>
                    <FormLabel fontWeight="400">Year</FormLabel>
                    <Controller
                      name="year"
                      control={control}
                      render={({ field }) => {
                        return (
                          <DatePicker
                            selectedDate={field.value ? dayjs(field.value).toDate() : null}
                            placement="top"
                            showYearPicker={true}
                            onChange={(date) => {
                              field.onChange(date || null)
                            }}
                          />
                        )
                      }}
                    />
                  </FormControl>
                </VStack>
                <VStack marginBottom={5}>
                  <SingleSelect
                    name="month"
                    label="Month"
                    optionsData={MonthOfYear}
                    placeHolder={'- Select month of year -'}
                    isClearable={true}
                  />
                </VStack>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <MultipleSelect
                      name="partnerId"
                      label="Belongs to Partners"
                      placeholder="Search partner by name or ID"
                      options={partnerOptions}
                      selectedEntityName="Payment Category"
                    />
                    <VStack alignSelf={'flex-start'}>
                      <GroupChips fieldName="partnerId" />
                    </VStack>
                  </VStack>
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <MultipleSelect
                      name="typeId"
                      label="Document Type"
                      placeholder="- Select Type -"
                      options={typeOptions}
                      selectedEntityName="Payment Category"
                    />
                    <VStack alignSelf={'flex-start'}>
                      <GroupChips fieldName="typeId" />
                    </VStack>
                  </VStack>
                </SimpleGrid>
              </DrawerBody>
              <div className="box-divider" />
              <DrawerFooter justifyContent={'space-between'}>
                <Button variant="ghost" onClick={resetForm} customStyles={{ color: textBlue500 }}>
                  Reset
                </Button>
                <HStack>
                  <Button variant="outline" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button variant="solid" type="submit">
                    Apply Filter
                  </Button>
                </HStack>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
