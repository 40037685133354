import { useEffect, useState } from 'react'
import { FormControl, FormLabel, HStack, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { getListCreatableOptionsAPI } from 'API/creatableOption'
import { ICreatableOptionListWithFilterResponse } from 'API/creatableOption/constants'
import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import { IFormattedInternalDocument } from 'API/internalDocument/constants'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import FormInput from 'components/FormInput'
import GroupRadio from 'components/GroupRadio'
import SingleSelect from 'components/SingleSelect'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EConditionGetList, ECreatableOptionScope } from 'constants/enum'
import { Messages } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { ICreatableOption, IInternalDocument, IPartner } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { handleDatePickerChange } from 'utils/dateFormatUtils'
import { createOptionsOfReactSelectFromDB, createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import { useStores } from '../../../utils/hooks/useStores'
import { initialValues, InternalDocumentScope, InternalDocumentStatus, validationSchema } from './constant'
import InternalDocumentDetail from './InternalDocumentDetail'
import styles from './index.module.scss'

const InternalDocumentCreateForm = ({
  dirty,
  isSubmitting,
  submitHandler,
  match,
  values,
  partners,
  doctypes,
  isLoading,
  defaultValues,
  onCancelClick,
}) => {
  const id: string = get(match, 'params.id', '')
  const disabled: boolean = get(values, 'isDeleted', false)
  const history = useHistory()
  let title: string = 'New Document'
  if (id) {
    if (!disabled || get(defaultValues, 'number', '')) {
      title = get(defaultValues, 'number', '')
    } else {
      title = 'New Document'
    }
  }

  const { layoutStore } = useStores()
  const statusOptions = createOptionsOfSelect(InternalDocumentStatus)
  const typeOptions = createOptionsOfReactSelectFromDB(doctypes)
  const partnerOptions = createOptionsOfReactSelectFromDB(partners)

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods

  const closeCreateInternalDocumentForm = () => {
    reset()
    onCancelClick()
  }

  useEffect(() => {
    reset({
      ...defaultValues,
      status: get(defaultValues, 'status')
        ? { label: capitalize(get(defaultValues, 'status', '')), value: get(defaultValues, 'status', '') }
        : null,
      typeId: get(defaultValues, 'typeId').value
        ? { label: capitalize(get(defaultValues, 'typeId.label', '')), value: get(defaultValues, 'typeId.value', '') }
        : '',
    })
  }, [id, defaultValues])

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(title)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore, title])

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => submitHandler(data))}>
        <VStack p={6} paddingInline={{ base: 6, lg: 6 }} paddingStart={{ base: '27px' }} spacing="30px">
          <Stack
            direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }}
            className={styles.formHeaderSection}
            padding={{ base: 4, lg: 4 }}>
            <Text fontSize={'lg'} color="gray.700" margin={0} alignSelf="center">
              {title}
            </Text>
            <HStack>
              <Button variant="outline" onClick={closeCreateInternalDocumentForm}>
                Cancel
              </Button>
              <Button
                variant="solid"
                type="submit"
                isLoading={isLoading}
                disabled={!isDirty || isSubmitting || disabled}>
                Save
              </Button>
            </HStack>
          </Stack>
          <VStack
            background="white"
            padding={{ base: 6, lg: 6 }}
            width="full"
            borderRadius="8px"
            marginTop="24px !important">
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="number" label="Doc’s number*" placeholder="Enter Partner number" disabled={disabled} />
              <GroupRadio name="scope" data={InternalDocumentScope} label="Scope*" disabled={disabled} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <SingleSelect
                placeHolder="-Select Category-"
                name="typeId"
                label="Document Type"
                optionsData={typeOptions}
                isDisabled={disabled}
              />
              <SingleSelect
                placeHolder="-Select Status-"
                name="status"
                label="Status"
                optionsData={statusOptions}
                isDisabled={disabled}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormControl isDisabled={disabled}>
                <FormLabel fontWeight="400">Start Date</FormLabel>
                <Controller
                  name="startDate"
                  control={methods.control}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : new Date()}
                      onChange={(date) => handleDatePickerChange(field, date)}
                      disabled={disabled}
                    />
                  )}
                />
              </FormControl>
              <FormControl isDisabled={disabled}>
                <FormLabel fontWeight="400">End Date</FormLabel>
                <Controller
                  name="endDate"
                  control={methods.control}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : new Date()}
                      onChange={(date) => handleDatePickerChange(field, date)}
                      disabled={disabled}
                    />
                  )}
                />
              </FormControl>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="description" label="Description " placeholder="Enter Description" disabled={disabled} />
              <FormInput type="number" name="amount" label="Amount" placeholder="Enter Amount" disabled={disabled} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="storageURL" isWebsite label="Storage URL" disabled={disabled} />
              <FormControl isDisabled={disabled}>
                <FormLabel fontWeight="400">Publish Date</FormLabel>
                <Controller
                  name="publishDate"
                  control={methods.control}
                  render={({ field }) => (
                    <DatePicker
                      selectedDate={field.value ? dayjs(field.value).toDate() : new Date()}
                      onChange={(date) => handleDatePickerChange(field, date)}
                      disabled={disabled}
                    />
                  )}
                />
              </FormControl>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput
                name="equipmentInfo"
                label="Equipment Information"
                placeholder="Enter Equipment information"
                disabled={disabled}
              />
              <FormInput
                name="invoiceInfo"
                label="Lookup E-invoice"
                placeholder="Enter E-invoice"
                disabled={disabled}
              />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <FormInput name="result" label="Result" placeholder="Enter Result" disabled={disabled} />
              <FormInput name="note" label="Note" placeholder="Enter Note" disabled={disabled} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 2 }} gap={{ base: 6, lg: 8 }} width="full">
              <SingleSelect
                placeHolder="- Select Partner -"
                name="partnerId"
                label="Belong to Partner*"
                optionsData={partnerOptions}
                isDisabled={disabled}
              />
            </SimpleGrid>
          </VStack>
        </VStack>
      </form>
    </FormProvider>
  )
}

const InternalDocumentCreate = (props) => {
  const [internalDocument, setInternalDocument] = useState(initialValues)
  const [partners, setPartners] = useState([])
  const [doctypes, setDoctypes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const { adminInternalDocumentStore, adminPartnerStore } = useStores()

  const { match, history } = props
  const id = get(match, 'params.id', '')

  function handleCancelEdit() {
    setIsEdit(false)
    history.push(frontendRoutes.internalDocument.list.value)
  }

  useEffect(() => {
    if (id) {
      getDetailInternalDocumentHandler(id)
    }
    getListPartnersHandler()
    getListDoctypesHandler()
    return () => {
      window.sessionStorage.removeItem('subPageTitle')
    }
  }, [id])

  async function submitHandler(values: IInternalDocument) {
    try {
      setIsLoading(true)
      const parsedValues = { ...values, month: Number(values.month) }
      if (values?.typeId) {
        parsedValues.typeId = get(values, 'typeId.value', '')
      }
      if (values?.status) {
        parsedValues.status = get(values, 'status.value', '')
      }
      if (values?.startDate) {
        parsedValues.startDate = new Date(values.startDate)
      }
      if (values?.endDate) {
        parsedValues.endDate = new Date(values.endDate)
      }
      if (values?.publishDate) {
        parsedValues.publishDate = new Date(values.publishDate)
      }
      if (values?.partnerId) {
        parsedValues.partnerId = get(values, 'partnerId.value', '')
      }
      if (!isNaN(Number(values?.amount))) {
        parsedValues.amount = Number(values?.amount)
      }
      if (!parsedValues.partnerId) {
        delete parsedValues.partnerId
      }
      if (!parsedValues.typeId) {
        parsedValues.typeId = ''
      }
      if (!parsedValues.status) {
        parsedValues.status = ''
      }
      if (id) {
        await adminInternalDocumentStore.updateInternalDocument(parsedValues, id)
        toast.success(Messages.updateInternalDocumentSuccess)
      } else {
        await adminInternalDocumentStore.createInternalDocument(parsedValues)
        toast.success(Messages.createInternalDocumentSuccess)
      }
      setIsLoading(false)
      history.push(frontendRoutes.internalDocument.list.value)
    } catch {
      setIsLoading(false)
    }
  }

  async function getDetailInternalDocumentHandler(internalDocumentId: string) {
    try {
      await adminInternalDocumentStore.getInternalDocumentDetail(internalDocumentId)
      const internalDocument: IFormattedInternalDocument = adminInternalDocumentStore.internalDocumentDetail
      const partner: IPartner = getValidArray(internalDocument?.partner)?.[0]
      const typeData: ICreatableOption = getValidArray(internalDocument?.typeData)?.[0]
      setInternalDocument({
        ...internalDocument,
        typeId: { label: typeData?.value ?? '', value: typeData?._id ?? '' },
        partnerId: { label: partner?.fullName ?? '', value: partner?._id ?? '' },
      })
    } catch {}
  }

  async function getListPartnersHandler() {
    try {
      await adminPartnerStore.getAllNamePartnerList(EConditionGetList.ACTIVE)
      setPartners(adminPartnerStore.partnerNameList)
    } catch {}
  }

  async function getListDoctypesHandler() {
    try {
      const response: ISuccessResponse<ICreatableOptionListWithFilterResponse> = await getListCreatableOptionsAPI({
        filter: {
          scope: ECreatableOptionScope.INTERNAL_DOCUMENT,
        },
      })
      setDoctypes(response?.data?.creatableOptions ?? [])
    } catch (error) {
      handleError(error, 'src/containers/InternalDocument/InternalDocumentForm/index.tsx', 'getListDoctypesHandler')
    }
  }

  return (
    <div className="internalDocument-create-page" style={{ marginTop: 10 }}>
      {!id || (id && isEdit) ? (
        <InternalDocumentCreateForm
          partners={partners}
          doctypes={doctypes}
          isLoading={isLoading}
          submitHandler={submitHandler}
          defaultValues={internalDocument}
          onCancelClick={handleCancelEdit}
          {...props}
        />
      ) : (
        <InternalDocumentDetail defaultValues={internalDocument} onClick={() => setIsEdit(true)} />
      )}
    </div>
  )
}

export default observer(InternalDocumentCreate)
