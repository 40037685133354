import React, { useEffect, useState } from 'react'
import { handleError } from 'API/error'
import { IFilterPaymentsPayload } from 'API/payment/constants'
import EmptyContentBox from 'components/EmptyContentBox'
import FormModel from 'components/FormModel'
import LoadingChakra from 'components/LoadingChakra'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useLocation, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IPaymentList } from 'types/payment'
import * as Yup from 'yup'
import { EConditionGetList, EStatusOfPaymentRequest } from 'constants/enum'
import { Messages } from 'constants/index'
import { isValidArray, getValidArray, getLastDayOfPreviousMonth, formatDate } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import FilterFormOfPayment from '../components/FilterFormPaymentOfAdmin'
import PaymentTable from '../components/PaymentTable'
import ShowRequestPaymentListModal from '../components/ShowRequestPaymentListModal'

const validationSchema = Yup.object().shape({
  date: Yup.string().required(Messages.fieldRequired),
})

interface IPaymentAdminProps {}

const PaymentAdmin = (props: IPaymentAdminProps) => {
  const { adminPaymentStore, adminProjectStore, adminUserStore } = useStores()
  const history = useHistory()
  const location = useLocation()
  const date: string = getLastDayOfPreviousMonth()
  const [isOpenRequestPaymentTable, setShowRequestPaymentTable] = useState(false)
  const [dataOfRequestPaymentTable, setDataOfRequestPaymentTable] = useState([])
  const [initialValues, setInitialValues] = useState(null)
  const [dateFilter, setDateFilter] = useState(date)

  let query = useQuery()
  const projectId = query.get('project')
  const userId = query.get('user')
  const categoryId = query.get('category')
  const dateId = query.get('date')
  const includedRequest = query.get('includedRequest')
  const { payments, paymentCategoryOptions, isPaymentLoading } = adminPaymentStore
  const { projectNameList } = adminProjectStore
  const { userNameList } = adminUserStore

  const nonEmptyPayments: IPaymentList[] = getValidArray(payments).filter((payment: IPaymentList) =>
    isValidArray(payment?.paymentData)
  )

  const emptyPayments: IPaymentList[] = getValidArray(payments).filter(
    (payment: IPaymentList) => !isValidArray(payment?.paymentData)
  )

  useEffect(() => {
    if (dateId && dateId !== dateFilter) {
      setDateFilter(dateId)
    }
  }, [dateId])

  function useQuery() {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }

  useEffect(() => {
    async function fetchInitialData() {
      try {
        await Promise.all([
          adminUserStore.getAllNameUserList(EConditionGetList.ALL),
          adminProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE),
          adminPaymentStore.getPaymentCategoryOptionsList(),
        ])
      } catch (error) {
        handleError(error, 'src/containers/Payment/PaymentAdmin/index.tsx', 'fetchInitialData')
      }
    }
    fetchInitialData()
  }, [])

  function getFilteredData(id, items) {
    const selectedItem = items.find((item) => item.id === id)
    return {
      value: id,
      label: selectedItem?.name ?? '',
    }
  }

  function generateFilter() {
    let filter = {}

    if (userId) {
      const filterObject = getFilteredData(userId, userNameList)
      Object.assign(filter, { user: filterObject })
    }

    if (projectId) {
      const filterObject = getFilteredData(projectId, projectNameList)
      Object.assign(filter, { project: filterObject })
    }

    if (categoryId) {
      const choosedCategoryData = paymentCategoryOptions.find((item) => item._id === categoryId)
      let filterCategory = {
        value: categoryId,
        label: choosedCategoryData?.value ?? '',
      }
      Object.assign(filter, { category: filterCategory })
    }

    return {
      ...filter,
      date: dateFilter,
      includedRequest,
    }
  }

  useEffect(() => {
    if (userNameList?.length > 0 && projectNameList?.length > 0 && paymentCategoryOptions?.length > 0) {
      const parsedFilter = generateFilter()
      setInitialValues(parsedFilter)
    }
  }, [userNameList, projectNameList, paymentCategoryOptions])

  useEffect(() => {
    if (initialValues) {
      fetchPayments(initialValues)
    }
  }, [initialValues])

  async function fetchPayments(filterValues): Promise<void> {
    try {
      const project = get(filterValues, 'project.value', '')
      const user = get(filterValues, 'user.value', '')
      const category = get(filterValues, 'category.value', '')
      const date = formatDate(get(filterValues, 'date'))
      const includedRequest = get(filterValues, 'includedRequest')
      const queryString = {}

      if (project) {
        Object.assign(queryString, { project })
      }

      if (user) {
        Object.assign(queryString, { user })
      }

      if (category) {
        Object.assign(queryString, { category })
      }

      if (date) {
        Object.assign(queryString, { date })
      }

      if (includedRequest) {
        Object.assign(queryString, { includedRequest })
      }

      const parseFilterInFilterSubmit: IFilterPaymentsPayload = {
        ...filterValues,
        project,
        user,
        category,
      }

      const params = new URLSearchParams(queryString)
      history.replace({ pathname: location.pathname, search: params.toString() })
      await adminPaymentStore.filterPayments(parseFilterInFilterSubmit)
    } catch (error) {
      handleError(error, 'src/containers/Payment/PaymentAdmin/index.tsx', 'fetchPayments')
    }
  }

  async function onSubmit(values): Promise<void> {
    await fetchPayments(values)
  }

  async function handleEditRequestPayment(requestId: string, value: EStatusOfPaymentRequest): Promise<void> {
    try {
      await adminPaymentStore.editRequestPayment(requestId, value)
      closeShowRequestPaymentTableHandler()
      await onSubmit(initialValues)
      toast.success('Edit request payment successfully')
    } catch (error) {
      toast.error('Edit request payment failed')
      handleError(error, 'src/containers/Payment/PaymentAdmin/index.tsx', 'handleEditRequestPayment')
    }
  }

  async function handleEditPayment(id: string, field: string, currentValue: boolean): Promise<void> {
    try {
      await adminPaymentStore.editPayment(id, field, currentValue)
      await onSubmit(initialValues)
      toast.success('Edit payment successfully')
    } catch (error) {
      toast.error('Edit payment failed')
      handleError(error, 'src/containers/Payment/PaymentAdmin/index.tsx', 'handleEditPayment')
    }
  }

  function triggerShowRequestPaymentTable(data) {
    setShowRequestPaymentTable(!isOpenRequestPaymentTable)
    setDataOfRequestPaymentTable(data)
  }

  function closeShowRequestPaymentTableHandler() {
    setShowRequestPaymentTable(false)
  }

  return (
    <div className="timesheet-page container">
      {initialValues ? (
        <div>
          <div>
            <FormModel
              initialValues={initialValues}
              schema={validationSchema}
              handleSubmit={onSubmit}
              component={
                <FilterFormOfPayment
                  paymentCategories={paymentCategoryOptions}
                  users={userNameList}
                  projects={projectNameList}
                  {...props}
                />
              }
            />
          </div>
          <LoadingChakra isLoading={isPaymentLoading}>
            {getValidArray(nonEmptyPayments).length === 0 && getValidArray(emptyPayments).length === 0 ? (
              <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
            ) : (
              <div className="timesheet-content">
                <div className="container-fluid">
                  {isValidArray(nonEmptyPayments) && (
                    <PaymentTable
                      paymentList={nonEmptyPayments}
                      editHandler={handleEditPayment}
                      isAdmin
                      triggerShowRequestPaymentTable={triggerShowRequestPaymentTable}
                    />
                  )}
                </div>
              </div>
            )}
          </LoadingChakra>
          <ShowRequestPaymentListModal
            isOpen={isOpenRequestPaymentTable}
            onClose={closeShowRequestPaymentTableHandler}
            data={dataOfRequestPaymentTable}
            editRequestPaymentHandler={handleEditRequestPayment}
          />
        </div>
      ) : (
        <LoadingChakra isLoading={true}>
          <div />
        </LoadingChakra>
      )}
    </div>
  )
}

export default observer(PaymentAdmin)
