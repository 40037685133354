import { Text } from '@chakra-ui/react'
import capitalize from 'lodash/capitalize'
import { ITableHeader } from 'types/table'
import * as Yup from 'yup'
import { EDocumentStatusLabel } from 'constants/enum'
import { IInternalDocument, IPartner, ICreatableOption } from 'constants/schema'

export const headerDesktop: ITableHeader[] = [
  {
    Header: 'Number',
    accessor: 'number',
  },
  {
    Header: 'Scope',
    accessor: 'scope',
  },
  {
    Header: 'Type',
    accessor: 'typeId',
  },
  {
    Header: 'Belongs To Partner',
    accessor: 'partnerInfo',
    Cell: ({ value }: { value: string }) => {
      const [fullName, category] = value ? value.split(' | ') : ['', '']
      return (
        <Text as="span" lineHeight="20px" color="gray.700" fontWeight="normal" fontSize="14px">
          {`${fullName} | `}
          <Text display="inline" color="gray.500" fontWeight="500" fontSize="14px">
            {capitalize(category)}
          </Text>
        </Text>
      )
    },
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export const headerTabletAndMobile: ITableHeader[] = [
  {
    Header: 'Belongs To Partner',
    accessor: 'partnerInfo', //name + category
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'actions',
  },
]

export const initialValues = {}
export const validation = Yup.object().shape({
  year: Yup.number().min(1900).max(2100),
  month: Yup.number().min(1).max(12),
})
export interface Props {
  history: any
  match: any
  handleCloseConfirmDialog?: () => void
  handleOpenSnackbar?: ({ variant, message }) => void
}
export interface IDocumentFilter {
  page?: number
  partnerId?: string[]
  typeId?: string[]
  scope?: string[]
  year?: number
  month?: number
  number?: string
  status?: EDocumentStatusLabel
}
export interface States {
  internalDocuments: IInternalDocument[]
  count: number
  filter?: IDocumentFilter
  partners?: IPartner[]
  doctypes?: ICreatableOption[]
  open?: boolean
  exportNow?: boolean
  isLoading?: boolean
}

export function getHeaderList(isMobile: boolean): ITableHeader[] {
  return isMobile ? headerTabletAndMobile : headerDesktop
}
