import React, { useState } from 'react'
import { HStack, FormControl, VStack } from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import MultipleSelect from 'components/MultipleSelect'
import SingleSelect from 'components/SingleSelect'
import SwitchControl from 'components/SwitchControl/SwitchControlWithTwoLabel'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { IOption } from 'constants/common'
import { ETypeOfFilter } from 'constants/enum'
import { getValidArray } from 'utils/commonUtils'
import { generateProjectOptions, generateUserOptions } from 'utils/user'
import { workingPeriodOptions } from '../../constants'
import { ISuperviseFilterFormProps } from '../../constants'

const SuperviseFilterForm = (props: ISuperviseFilterFormProps) => {
  const {
    projectNameList,
    userNameList,
    exportDataHandler,
    reportWithUserHandler,
    reportWithProjectHandler,
    isAnalysisLoading,
    typeOfFilter,
    setTypeOfFilter,
  } = props
  const { control, setValue, handleSubmit, getValues } = useFormContext()
  const formValues = getValues()
  const { selectedProject, selectedUser, isFilterByProject } = formValues
  const [isAnalyticsViewed, setIsAnalyticsViewed] = useState(false)
  const isButtonDisabled =
    typeOfFilter === ETypeOfFilter.FILTER_BY_PROJECT
      ? !formValues.selectedProject || getValidArray(formValues.selectedUser).length === 0
      : !formValues.selectedUser || getValidArray(formValues.selectedProject).length === 0
  let newUserNameList = userNameList
  let newProjectNameList = projectNameList
  if (typeOfFilter === ETypeOfFilter.FILTER_BY_PROJECT) {
    if (selectedProject) {
      newUserNameList = getValidArray(userNameList).filter((user) => {
        const projectIds = user?.projects
        return projectIds.includes(selectedProject.value)
      })
    }
  }
  if (typeOfFilter === ETypeOfFilter.FILTER_BY_USER) {
    if (selectedUser) {
      const selectedUserId = selectedUser.value
      const userData = getValidArray(userNameList).find((user) => {
        return user.id === selectedUserId
      })
      newProjectNameList = getValidArray(projectNameList).filter((project) => {
        const projectIdsData = userData?.projects
        return projectIdsData?.includes(project.id)
      })
    }
  }
  const projectOptions: IOption[] = generateProjectOptions(newProjectNameList)
  const userOptions: IOption[] = generateUserOptions(newUserNameList)
  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }

  function changeFilterTypeHandler(value: boolean) {
    setValue('selectedUser', null)
    setValue('selectedProject', null)
    setValue('isFilterByProject', !value)
    if (value) {
      setTypeOfFilter(ETypeOfFilter.FILTER_BY_USER)
    } else {
      setTypeOfFilter(ETypeOfFilter.FILTER_BY_PROJECT)
    }
  }

  function handleInputDropdownChange(selectedValue: IOption | null, field: 'selectedProject' | 'selectedUser') {
    setIsAnalyticsViewed(false)
    setValue(field, selectedValue)
    resetDependentField(field, selectedValue)
  }

  function resetDependentField(field: 'selectedProject' | 'selectedUser', selectedValue: IOption | null) {
    if (field === 'selectedProject') {
      setValue('selectedProject', selectedValue)
      setValue('selectedUser', [])
    } else if (field === 'selectedUser') {
      setValue('selectedUser', selectedValue)
      setValue('selectedProject', [])
    }
  }

  function renderFilterOption() {
    if (typeOfFilter === ETypeOfFilter.FILTER_BY_PROJECT) {
      return (
        <React.Fragment>
          <InputDropdown
            name="selectedProject"
            placeHolder="Select Project"
            optionsData={projectOptions}
            isClearable={false}
            onChangeValue={(value) => handleInputDropdownChange(value, 'selectedProject')}
          />
          <MultipleSelect
            name="selectedUser"
            placeholder="Select Users"
            options={userOptions}
            selectedEntityName="Users"
            paramKey="usersId"
          />
        </React.Fragment>
      )
    }
    return (
      <React.Fragment>
        <InputDropdown
          name="selectedUser"
          placeHolder="Select User"
          optionsData={userOptions}
          isClearable={false}
          onChangeValue={(value) => handleInputDropdownChange(value, 'selectedUser')}
        />
        <MultipleSelect
          name="selectedProject"
          placeholder="Select Projects"
          options={projectOptions}
          selectedEntityName="Projects"
          paramKey="projectsId"
        />
      </React.Fragment>
    )
  }

  function handleButtonClick() {
    const data = getValues()

    if (!data.selectedProject || !data.selectedUser) {
      setValue('selectedUser', null)
      setValue('selectedProject', null)
      return
    }

    setIsAnalyticsViewed(true)
    handleSubmit(isFilterByProject ? reportWithProjectHandler : reportWithUserHandler)()
  }

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }} paddingBottom={'10px'}>
      <VStack width="inherit" padding={'16px'}>
        <SwitchControl
          name="isFilterByProject"
          leftLabel="Filter By: User"
          rightLabel="Project"
          handleOnChange={() => changeFilterTypeHandler(isFilterByProject)}
        />
        <HStack width="inherit">
          <HStack width="inherit">
            {renderFilterOption()}
            <SingleSelect name="period" placeHolder="Select Period" optionsData={workingPeriodOptions} />
            <FormControl>
              <Controller
                name="date"
                control={control}
                render={({ field: { value } }) => (
                  <DatePicker
                    selectedDate={value}
                    onChange={pickDate}
                    style={{
                      border: '1px solid #4D5DFB',
                      borderColor: '#4D5DFB',
                    }}
                  />
                )}
              />
            </FormControl>
          </HStack>
          <HStack padding={'16px'}>
            <Button variant="outline" leftIcon="export.svg" height="44px" onClick={exportDataHandler}>
              Export Data
            </Button>

            <Button
              rightIcon="arrow-right-line-white.svg"
              height="44px"
              isLoading={isAnalysisLoading}
              disabled={isAnalysisLoading || isButtonDisabled}
              onClick={handleButtonClick}>
              View Analytics
            </Button>
          </HStack>
        </HStack>
      </VStack>
    </HStack>
  )
}

export default SuperviseFilterForm
