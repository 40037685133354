import { useEffect, useState } from 'react'
import { ArrowBackIcon } from '@chakra-ui/icons'
import { Stack, Container, HStack, Text, VStack, useMediaQuery } from '@chakra-ui/react'
import { handleError } from 'API/error'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import LoadingChakra from 'components/LoadingChakra'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import get from 'lodash/get'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaSave } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IRowActionItem } from 'types/table'
import { IProfileDetailOfDetailForm } from 'types/user'
import { PartnerCategory, PartnerType } from 'constants/defaultOptionList'
import { actionOnDataTable } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { IPartner } from 'constants/schema'
import { createOptionsOfSelect } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import { TitlePages } from '../../../constants'
import DetailPartnerForm from './components/DetailPartnerForm'
import EditPartnerForm from './components/EditPartnerForm'
import styles from './index.module.scss'

const PartnerDetail = () => {
  const { layoutStore } = useStores()
  const methods = useForm()
  const { handleSubmit, reset } = methods
  const params = useParams()
  const history = useHistory()
  const partnerId = get(params, 'id', '')
  const { adminPartnerStore } = useStores()
  const { partnerDetail, isLoadingPartnerDetail } = adminPartnerStore
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openArchiveModal, setOpenArchiveModal] = useState<boolean>(false)
  const [isTablet] = useMediaQuery('(max-width: 820px)')
  const partnerCategoryOptions = createOptionsOfSelect(PartnerCategory)

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(TitlePages.PARTNER_DETAIL)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  useEffect(() => {
    adminPartnerStore.getPartnerDetail(partnerId, isEdit)
    adminPartnerStore.getOptionList()
  }, [isEdit])

  useEffect(() => {
    if (partnerDetail) {
      reset(partnerDetail)
    }
  }, [partnerDetail])

  async function submitHandler(data: IPartner): Promise<void> {
    try {
      const messages: string = await adminPartnerStore.updatePartner(data, partnerId)
      toast.success(messages)
      setIsEdit(false)
      await adminPartnerStore.getPartnerDetail(partnerId, false)
    } catch (error) {
      toast.error('Update partner failed!')
      handleError(error, 'src/containers/Partner/PartnerDetail/index.tsx', 'submitHandler')
    }
  }
  function toggleOpenDeleteModal() {
    setOpenDeleteModal(!openDeleteModal)
  }
  function toggleOpenArchiveModal() {
    setOpenArchiveModal(!openArchiveModal)
  }
  function callOpenDialogConfirm(user: IProfileDetailOfDetailForm, nameOfEvent: string) {
    set(user, 'shortName', user?.fullName ?? '')
    if (nameOfEvent === actionOnDataTable.DELETE) {
      toggleOpenDeleteModal()
    } else {
      toggleOpenArchiveModal()
    }
  }
  async function handleClickOKConfirmedDelete(id: string): Promise<void> {
    try {
      const messages: string = await adminPartnerStore.deletePartner(partnerId)
      toast.success(messages)
      history.push(frontendRoutes.partnerPage.list.value)
    } catch (error) {
      toast.error('Delete partner failed!')
      handleError(error, 'src/containers/Partner/PartnerDetail/index.tsx', 'handleClickOKConfirmedDelete')
    }
  }
  async function handleClickOKOfUnarchiveConfirm(id: string): Promise<void> {
    try {
      const messages: string = await adminPartnerStore.unArchivePartner(partnerId)
      toast.success(messages)
      history.push(frontendRoutes.partnerPage.list.value)
    } catch (error) {
      toast.error('Unarchive partner failed!')
      handleError(error, 'src/containers/Partner/PartnerDetail/index.tsx', 'handleClickOKOfUnarchiveConfirm')
    }
  }
  async function handleClickOKOfArchiveConfirm(id: string): Promise<void> {
    try {
      const messages: string = await adminPartnerStore.archivePartner(partnerId)
      toast.success(messages)
      history.push(frontendRoutes.partnerPage.list.value)
    } catch (error) {
      toast.error('Archive partner failed!')
      handleError(error, 'src/containers/Partner/PartnerDetail/index.tsx', 'handleClickOKOfArchiveConfirm')
    }
  }
  function editHandler() {
    setIsEdit(true)
  }
  function cancelHandler() {
    setIsEdit(false)
  }
  const actions: IRowActionItem[] = [
    {
      name: !!partnerDetail?.isArchived ? 'Unarchive' : 'Archive',
      handler: () => callOpenDialogConfirm(partnerDetail, actionOnDataTable.ARCHIVE),
    },
  ]
  actions.push({
    name: 'Delete',
    customClassNames: ['isDanger'],
    handler: () => callOpenDialogConfirm(partnerDetail, actionOnDataTable.DELETE),
  })

  return (
    <VStack>
      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={handleSubmit((data) => submitHandler(data))}>
          <VStack>
            <Container background="white" width="full" maxWidth="unset" padding={0} borderRadius={8}>
              <Stack
                direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'row' }}
                margin={{ base: 6, lg: 6 }}
                height="full"
                background="white"
                justifyContent="space-between"
                alignItems="center">
                <Text fontSize={{ xl: 'lg', lg: 'lg', md: 'lg', sm: 'sm' }} color="gray.700" margin={0}>
                  {partnerDetail?.fullName}
                </Text>
                <HStack justifyContent={{ sm: 'flex-end' }}>
                  {!isEdit ? (
                    <Button variant="solid" onClick={editHandler} leftIcon="edit-white.svg" leftIconSize={16}>
                      {!isTablet ? 'Edit Partner' : ''}
                    </Button>
                  ) : (
                    <>
                      <Button variant="outline" onClick={cancelHandler}>
                        {!isTablet ? 'Cancel' : <ArrowBackIcon />}
                      </Button>
                      <Button variant="solid" type="submit">
                        {!isTablet ? 'Save' : <FaSave />}
                      </Button>
                    </>
                  )}
                  <MoreDropdown isBackground actions={actions} />
                </HStack>
              </Stack>
            </Container>

            <LoadingChakra isLoading={isLoadingPartnerDetail}>
              <HStack width="100%" marginTop="24px !important" justifyContent="space-between" alignItems="flex-start">
                <VStack width="100%">
                  {isEdit ? (
                    <EditPartnerForm
                      methods={methods}
                      partnersType={PartnerType}
                      patnerCategoryOptions={partnerCategoryOptions}
                    />
                  ) : (
                    <DetailPartnerForm />
                  )}
                </VStack>
              </HStack>
            </LoadingChakra>
          </VStack>
        </form>
        <ConfirmModal
          data={partnerDetail}
          isOpen={openDeleteModal}
          closeHandler={toggleOpenDeleteModal}
          OKClickHandler={handleClickOKConfirmedDelete}
          title={'Partner'}
          content={'Full Name'}
          type={EModalType.DELETE}
        />
        <ConfirmModal
          data={partnerDetail}
          isOpen={openArchiveModal}
          closeHandler={toggleOpenArchiveModal}
          OKClickHandler={partnerDetail?.isArchived ? handleClickOKOfUnarchiveConfirm : handleClickOKOfArchiveConfirm}
          title={'partner'}
          content={'Full name'}
          type={EModalType.ARCHIVE}
        />
      </FormProvider>
    </VStack>
  )
}
export default observer(PartnerDetail)
