import { useEffect, useState } from 'react'
import {
  SimpleGrid,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  HStack,
  VStack,
} from '@chakra-ui/react'
import Button from 'components/Button'
import GroupChips from 'components/GroupChips'
import GroupRadio from 'components/GroupRadio'
import MultipleSelect from 'components/MultipleSelect'
import { get } from 'lodash'
import queryString from 'query-string'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { textBlue500 } from 'theme/globalStyles'
import { ICreatableOption } from 'types/creatableOption'
import { ECreatableOptionTypes, ERoleOfUser, EUserStatusLabel } from 'constants/enum'
import { getValidArray, getArrayValueFromParsedQuery } from 'utils/commonUtils'
import { getOptionsSelected } from 'utils/dropdownOptionUtils'
import { createOptionsOfReactSelectFromDB, createOptionsBadgeImageOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { IFilterForm } from './interface'

const FilterForm = (props: IFilterForm) => {
  const { openModalFilterForm, setOpenFilterForm, technologyList, creatableOptionList, filterSubmit } = props
  const history = useHistory()
  const methods = useForm()
  const { handleSubmit, reset, getValues, setValue } = methods
  const [selectedStatus, setSelectedStatus] = useState(EUserStatusLabel.ACTIVE)

  const options = getValidArray(creatableOptionList)

  const titleOptions = createOptionsBadgeImageOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.TITLE_OF_USER)
  )
  const levelOptions = createOptionsBadgeImageOfReactSelectFromDB(
    options
      .filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.LEVEL_OF_USER)
      .sort((previous: ICreatableOption, current: ICreatableOption) => (previous?.order || 0) - (current?.order || 0))
  )
  const paymentCategoryOptions = createOptionsOfReactSelectFromDB(
    options.filter((option: ICreatableOption) => option?.type === ECreatableOptionTypes.PAYMENT_CATEGORY_OF_USER)
  )
  const technologiesOptions = createOptionsOfReactSelectFromDB(technologyList)

  const URLSearchParams = history.location.search
  const filterValues = queryString.parse(URLSearchParams, { parseBooleans: true })

  const isActive = get(filterValues, 'isActive', true)
  const isArchived = get(filterValues, 'isArchived', false)
  const isDeleted = get(filterValues, 'isDeleted', false)

  const selectedRole = get(filterValues, 'role', ERoleOfUser.ALL)
  const selectedPaymentCategoryIds = getArrayValueFromParsedQuery(filterValues, 'paymentCategoryId')
  const selectedTechnologyIds = getArrayValueFromParsedQuery(filterValues, 'technologies')
  const selectedLevelIds = getArrayValueFromParsedQuery(filterValues, 'levelId')
  const selectedTitleIds = getArrayValueFromParsedQuery(filterValues, 'titleId')

  const selectedPaymentCategoryOptions = getOptionsSelected(selectedPaymentCategoryIds, paymentCategoryOptions)
  const selectedTechnologyOptions = getOptionsSelected(selectedTechnologyIds, technologiesOptions)
  const selectedLevelOptions = getOptionsSelected(selectedLevelIds, levelOptions)
  const selectedTitleOptions = getOptionsSelected(selectedTitleIds, titleOptions)

  function handleStatusChange(value: EUserStatusLabel) {
    setSelectedStatus(value)
    const statusConfigs = {
      [EUserStatusLabel.ACTIVE]: { isActive: true, isArchived: false, isDeleted: false },
      [EUserStatusLabel.ARCHIVED]: { isActive: false, isArchived: true, isDeleted: false },
      [EUserStatusLabel.DELETED]: { isActive: false, isArchived: true, isDeleted: true },
    }

    Object.entries(statusConfigs[value] ?? {}).forEach(([key, value]) => {
      methods.setValue(key, value)
    })
  }

  function closeModal(): void {
    setOpenFilterForm(false)
  }

  function resetForm() {
    reset({
      isActive: true,
      isArchived: false,
      isDeleted: false,
      titleId: [],
      levelId: [],
      paymentCategoryId: [],
      technologies: [],
    })
  }
  useEffect(() => {
    reset({
      isActive: isActive,
      isArchived: isArchived,
      isDeleted: isDeleted,
      role: selectedRole,
      paymentCategoryId: selectedPaymentCategoryOptions,
      technologies: selectedTechnologyOptions,
    })
    setValue('titleId', selectedTitleOptions)
    setValue('levelId', selectedLevelOptions)
  }, [creatableOptionList, technologyList])
  return (
    <HStack spacing={4} alignSelf="flex-start" marginBottom={6} display={{ base: 'none', md: 'flex' }}>
      <Drawer isOpen={openModalFilterForm} placement="right" onClose={closeModal} size={'lg'}>
        <DrawerOverlay />
        <FormProvider {...methods}>
          <form className="full-form" onSubmit={handleSubmit((data) => filterSubmit(data))}>
            <DrawerContent>
              <DrawerCloseButton color={'gray'} background="none" cursor="pointer" mt="2" />
              <DrawerHeader>Filter</DrawerHeader>
              <div className="box-divider" />
              <DrawerBody paddingTop={6}>
                <SimpleGrid width="full" marginBottom={5}>
                  <GroupRadio
                    name="status"
                    data={Object.values(EUserStatusLabel)}
                    label="Status"
                    defaultValue={selectedStatus}
                    onChange={handleStatusChange}
                  />
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <GroupRadio name="role" data={Object.values(ERoleOfUser)} label="Role" defaultValue={selectedRole} />
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <MultipleSelect
                      name="titleId"
                      label="Title"
                      placeholder="Select Title"
                      options={titleOptions}
                      selectedEntityName="Title"
                    />
                    <VStack alignSelf={'flex-start'}>
                      <GroupChips fieldName={'titleId'} isBadgeImage={true} />
                    </VStack>
                  </VStack>
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <MultipleSelect
                      name="levelId"
                      label="Level"
                      placeholder="Select Level"
                      options={levelOptions}
                      selectedEntityName="Level"
                    />
                    <VStack alignSelf={'flex-start'}>
                      <GroupChips fieldName={'levelId'} isBadgeImage={true} />
                    </VStack>
                  </VStack>
                </SimpleGrid>
                <SimpleGrid width="full" marginBottom={5}>
                  <VStack>
                    <MultipleSelect
                      name="paymentCategoryId"
                      label="Payment Category"
                      placeholder="Select Payment Category"
                      options={paymentCategoryOptions}
                      selectedEntityName="Payment Category"
                    />
                    <VStack alignSelf={'flex-start'}>
                      <GroupChips fieldName="paymentCategoryId" />
                    </VStack>
                  </VStack>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1 }} gap={{ base: 6, lg: 8 }} width="full">
                  <VStack>
                    <MultipleSelect
                      name="technologies"
                      label="Technology"
                      placeholder="Select Technology"
                      options={technologiesOptions}
                      selectedEntityName="Payment Technology"
                    />
                    <VStack alignSelf={'flex-start'}>
                      <GroupChips fieldName="technologies" />
                    </VStack>
                  </VStack>
                </SimpleGrid>
              </DrawerBody>
              <div className="box-divider" />
              <DrawerFooter justifyContent={'space-between'}>
                <Button variant="ghost" onClick={resetForm} customStyles={{ color: textBlue500 }}>
                  Reset
                </Button>
                <HStack>
                  <Button variant="outline" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button variant="solid" type="submit">
                    Apply Filter
                  </Button>
                </HStack>
              </DrawerFooter>
            </DrawerContent>
          </form>
        </FormProvider>
      </Drawer>
    </HStack>
  )
}

export default FilterForm
