import { useState, useEffect } from 'react'
import { Box, useMediaQuery, Text } from '@chakra-ui/react'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import ConfirmYesNoModal from 'components/ConfirmYesNoModal'
import Table from 'components/Table'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import { History } from 'history'
import { debounce, get } from 'lodash'
import set from 'lodash/set'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { backgroundGrey200 } from 'theme/globalStyles'
import { IRowActionItem } from 'types/table'
import { ITechnology } from 'types/technology'
import { IUserFilter } from 'types/user'
import { maxMobileSize, maxTabletSize } from 'constants/common'
import { ERoleOfUser } from 'constants/enum'
import { actionOnDataTable, limitItemPerPage } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { IUser } from 'constants/schema'
import { getArrayValueFromParsedQuery, removeAccents, getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { getUserStatus } from 'utils/user'
import FilterForm from '../components/FilterForm'
import TechnologyModal from '../components/TechnologyModal'
import UnarchiveUserModal from '../components/UnarchiveUserModal'
import HeaderSection from './components/HeaderSection'
import UserSummary from './components/UserSummary'
import { getHeaderList, tabletHeaderList } from './constant'
import { formatFilterData, generateUserPath } from './container'
import styles from './userList.module.scss'

const UserList = () => {
  const [isOpenFilterForm, setIsOpenFilterForm] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState(false)
  const [isOpenActiveModal, setIsOpenActiveModal] = useState(false)
  const [isOpenSummaryModal, setIsOpenSummaryModal] = useState(false)
  const [isOpenUnarchiveModal, setIsOpenUnarchiveModal] = useState(false)
  const [isOpenConfirmYesNoModal, setIsOpenConfirmYesNoModal] = useState(false)
  const [isOpenTechnologyModal, setIsOpenTechnologyModal] = useState(false)
  const [exportNow, setExportNow] = useState(false)
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null)
  const [matchingTechnologies, setMatchingTechnologies] = useState<ITechnology[] | null>([])
  const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([])
  const [isMobile] = useMediaQuery(maxMobileSize)
  const [isTablet] = useMediaQuery(maxTabletSize)
  const history: History = useHistory()
  const { adminUserStore, adminProjectStore } = useStores()
  const { userList, creatableOptionList, technologyList, countPagination } = adminUserStore
  const URLSearchParams = history.location.search
  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })
  const isAdmin = window.localStorage.getItem('userRole') === ERoleOfUser.ADMIN

  if (!URLSearchParams) {
    initialFilter.isActive = true
    initialFilter.isArchived = false
    initialFilter.isDeleted = false
  }
  initialFilter.titleId = getArrayValueFromParsedQuery(initialFilter, 'titleId')
  initialFilter.levelId = getArrayValueFromParsedQuery(initialFilter, 'levelId')
  initialFilter.paymentCategoryId = getArrayValueFromParsedQuery(initialFilter, 'paymentCategoryId')
  initialFilter.technologies = getArrayValueFromParsedQuery(initialFilter, 'technologies')
  const [filter, setFilter] = useState<IUserFilter>(initialFilter ?? {})

  function toggleOpenFilterForm() {
    setIsOpenFilterForm(!isOpenFilterForm)
  }

  function toggleOpenSummaryModal() {
    setIsOpenSummaryModal(!isOpenSummaryModal)
  }

  function toggleExportNow() {
    setExportNow(!exportNow)
  }

  function toggleOpenDeleteModal() {
    setIsOpenDeleteModal(!isOpenDeleteModal)
  }

  function toggleOpenArchiveModal() {
    setIsOpenArchiveModal(!isOpenArchiveModal)
  }

  function toggleOpenActiveModal() {
    setIsOpenActiveModal(!isOpenActiveModal)
  }

  function toggleConfirmYesNoModal() {
    setIsOpenConfirmYesNoModal(!isOpenConfirmYesNoModal)
  }

  function toggleOpenUnarchiveModal() {
    setIsOpenUnarchiveModal(!isOpenUnarchiveModal)
  }

  function toggleOpenSummaryModalHandler() {
    setIsOpenSummaryModal(!isOpenSummaryModal)
  }

  function toggleOpenTechnologyModal() {
    setIsOpenTechnologyModal(!isOpenTechnologyModal)
  }

  const handleCreate = () => history.push(frontendRoutes.userPage.create.value)

  function filterSubmitHandler(data: IUserFilter) {
    const formattedFilter = formatFilterData(data)
    initialFilter.normalizedFullName && set(formattedFilter, 'normalizedFullName', initialFilter.normalizedFullName)
    history.push({ search: queryString.stringify(formattedFilter) })
    setFilter(formattedFilter)
    toggleOpenFilterForm()
  }

  function callOpenDialogConfirm(user: IUser, nameOfEvent: string) {
    set(user, 'shortName', user?.fullName ?? '')
    switch (nameOfEvent) {
      case actionOnDataTable.DELETE:
        setSelectedUser(user)
        toggleOpenDeleteModal()
        break
      case actionOnDataTable.EDIT:
        console.log('The future is coming soon')
        break
      case actionOnDataTable.ARCHIVE:
        setSelectedUser(user)
        if (user?.isArchived) {
          adminProjectStore.getRemovedProjectsListOfUser(user?.id ?? '')
          toggleOpenUnarchiveModal()
          break
        }
        toggleOpenArchiveModal()
        break
      case actionOnDataTable.ACTIVE:
        setSelectedUser(user)
        toggleOpenActiveModal()
        break
      default:
        toast.error('Action not found')
    }
  }

  const changeName = debounce((event: { target: { value: string } }) => {
    const normalizedFullName = removeAccents(event?.target?.value ?? '')
    const changedFilter = {
      ...filter,
      normalizedFullName,
      page: 1,
    }
    history.push({ search: queryString.stringify(changedFilter) })
    setFilter(changedFilter)
  }, 1000)

  async function handleClickOKConfirmedDelete(id: string) {
    await adminUserStore.deleteUser(id)
    toggleOpenDeleteModal()
    adminUserStore.getUserListWithPagination(history, filter)
  }

  async function handleClickOKOfArchiveConfirm(userId: string) {
    await adminUserStore.archivedUser(userId)
    toggleOpenArchiveModal()
    adminUserStore.getUserListWithPagination(history, filter)
  }

  async function handleClickOKOfActiveConfirm(userId: string) {
    await adminUserStore.activeUser(userId)
    toggleOpenActiveModal()
    adminUserStore.getUserListWithPagination(history, filter)
  }

  async function handleClickOkOfDeactivateConfirm(userId: string) {
    await adminUserStore.deactivateUser(userId)
    toggleOpenActiveModal()
    adminUserStore.getUserListWithPagination(history, filter)
  }

  async function handleConfirmInfo(selectedProjectIds?: string[]) {
    setSelectedProjectIds(selectedProjectIds ?? [])
    toggleOpenUnarchiveModal()
    toggleConfirmYesNoModal()
  }

  async function handleClickOKOfUnarchiveConfirm() {
    await adminUserStore.unArchivedUser(selectedUser._id, selectedProjectIds)
    toggleConfirmYesNoModal()
    adminUserStore.getUserListWithPagination(history)
  }

  const pageIndex: number = Number(get(filter, 'page', 1))
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: limitItemPerPage,
    tableLength: countPagination,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page }
      setFilter(changedFilter)
      history.push({
        pathname: frontendRoutes.userPage.list.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })
      adminUserStore.getUserListWithPagination(history, filter)
    },
  }

  const dataInTable = getValidArray(userList).map((user) => {
    const isDeleted: boolean = user?.isDeleted ?? false
    const isActive: boolean = user?.isActive ?? false
    const isArchived: boolean = user?.isArchived ?? false
    const pathname = generateUserPath(user, frontendRoutes.userPage.detail.id.value)
    const dashBoard = generateUserPath(user, frontendRoutes.userDashboardPage.detail.id.value)
    const actionDetailHandler = () => history.push(pathname)

    function formatTechnologies(technologies: string[]) {
      if (!technologies || technologies.length === 0) {
        return ''
      }
      if (technologies.length === 1) {
        return technologies[0]
      }
      return `${technologies[0]}, +${technologies.length - 1} others`
    }

    const actions: IRowActionItem[] = []
    if (!isDeleted) {
      actions.push({
        name: 'View Dashboard',
        handler: () => history.push(`${dashBoard}?isShowQuickAccessBubble=true`),
      })
      actions.push({
        name: !!isArchived ? 'Unarchive' : 'Archive',
        handler: () => callOpenDialogConfirm(user, actionOnDataTable.ARCHIVE),
      })
      if (isActive) {
        actions.push({
          name: 'Deactivate User',
          handler: () => callOpenDialogConfirm(user, actionOnDataTable.ACTIVE),
        })
      }
      if (!isArchived && !isActive) {
        actions.push({
          name: 'Activate User',
          handler: () => callOpenDialogConfirm(user, actionOnDataTable.ACTIVE),
        })
      }
      actions.push({
        name: 'Delete User',
        customClassNames: ['isDanger'],
        handler: () => callOpenDialogConfirm(user, actionOnDataTable.DELETE),
      })
    }

    let status = getUserStatus(user)
    const actionsTablet: IRowActionItem[] = []
    !isDeleted &&
      actionsTablet.push(
        {
          name: 'Detail',
          handler: () => actionDetailHandler(),
        },
        {
          name: 'View Dashboard',
          handler: () => history.push(dashBoard),
        },
        {
          name: !!isArchived ? 'Unarchive' : 'Archive',
          handler: () => callOpenDialogConfirm(user, actionOnDataTable.ARCHIVE),
        },
        {
          name: isActive ? 'Deactivate' : 'Activate',
          handler: () => callOpenDialogConfirm(user, actionOnDataTable.ACTIVE),
        },
        {
          name: 'Delete',
          customClassNames: ['isDanger'],
          handler: () => callOpenDialogConfirm(user, actionOnDataTable.DELETE),
        }
      )

    if (!isAdmin) {
      return {
        ...user,
        status,
        titleId: user?.titleName,
        technology: (
          <Text
            className={styles.textHover}
            onClick={() => {
              setSelectedUser(user)
              setMatchingTechnologies(
                technologyList.filter((tech: ITechnology) => user?.technologies?.includes(tech.value))
              )
              toggleOpenTechnologyModal()
            }}>
            {formatTechnologies(user?.technologies || [])}
          </Text>
        ),
        actions: <MoreDropdown isDetail detailActionHandler={actionDetailHandler} actions={[]} />,
        actionsTablet: [],
      }
    }

    return {
      ...user,
      status,
      titleId: user?.titleName,
      technology: (
        <Text
          className={styles.textHover}
          onClick={() => {
            setSelectedUser(user)
            setMatchingTechnologies(
              technologyList.filter((tech: ITechnology) => user?.technologies?.includes(tech.value))
            )
            toggleOpenTechnologyModal()
          }}>
          {formatTechnologies(user?.technologies || [])}
        </Text>
      ),
      actions: (
        <MoreDropdown
          isDetail
          isSummary
          detailActionHandler={actionDetailHandler}
          summaryActionHandler={() => toggleOpenSummaryModalHandler(user)}
          actions={actions}
        />
      ),
      actionsTablet: actionsTablet,
    }
  })

  useEffect(() => {
    adminUserStore.getTechnologyList()
    adminUserStore.getOptionList()
  }, [])

  useEffect(() => {
    adminUserStore.getUserListWithPagination(history, filter)
  }, [filter])

  return (
    <Box>
      <Box
        className={styles.userContainer}
        background={isMobile ? 'none' : 'white'}
        paddingY={isMobile ? 2 : 4}
        paddingX={isMobile ? 0 : 4}
        borderRadius="6px"
        border={isMobile ? 'none' : `1px solid ${backgroundGrey200}`}>
        <HeaderSection
          handleCreate={handleCreate}
          toggleOpen={toggleOpenFilterForm}
          toggleExport={toggleExportNow}
          changeName={changeName}
        />
        <FilterForm
          openModalFilterForm={isOpenFilterForm}
          setOpenFilterForm={toggleOpenFilterForm}
          creatableOptionList={creatableOptionList}
          filterSubmit={filterSubmitHandler}
          technologyList={technologyList}
        />
        <Table
          headerList={getHeaderList(isTablet)}
          isLoading={adminUserStore.isUserListLoading}
          headerTabletList={tabletHeaderList}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          hasBottomHeader={!isMobile}
          exportNow={exportNow}
          toggleExport={toggleExportNow}
        />
        <ConfirmModal
          data={selectedUser}
          isOpen={isOpenDeleteModal}
          closeHandler={toggleOpenDeleteModal}
          OKClickHandler={handleClickOKConfirmedDelete}
          title={'User'}
          content={'Full Name'}
          type={EModalType.DELETE}
        />
        <ConfirmModal
          data={selectedUser}
          isOpen={isOpenArchiveModal}
          closeHandler={toggleOpenArchiveModal}
          OKClickHandler={handleClickOKOfArchiveConfirm}
          title={'User'}
          content={'Full Name'}
          type={EModalType.ARCHIVE}
        />
        <ConfirmModal
          data={selectedUser}
          isOpen={isOpenActiveModal}
          closeHandler={toggleOpenActiveModal}
          OKClickHandler={selectedUser?.isActive ? handleClickOkOfDeactivateConfirm : handleClickOKOfActiveConfirm}
          title={'User'}
          content={'Full Name'}
          type={EModalType.ACTIVE}
        />
        <UnarchiveUserModal
          isOpen={isOpenUnarchiveModal}
          closeHandler={toggleOpenUnarchiveModal}
          saveHandler={handleConfirmInfo}
        />
        <ConfirmYesNoModal
          isOpen={isOpenConfirmYesNoModal}
          title="Confirm Unarchive User"
          actionText="Are you sure you want to unarchive this user?"
          closeHandler={toggleConfirmYesNoModal}
          OKClickHandler={handleClickOKOfUnarchiveConfirm}
        />
        <TechnologyModal
          selectedUser={selectedUser}
          matchingTechnologies={matchingTechnologies}
          isOpen={isOpenTechnologyModal}
          closeHandler={toggleOpenTechnologyModal}
        />
        <UserSummary isOpen={isOpenSummaryModal} handleCloseModal={toggleOpenSummaryModal} userData={selectedUser} />
      </Box>
    </Box>
  )
}
export default observer(UserList)
