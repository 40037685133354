import { useState, useEffect } from 'react'
import { HStack } from '@chakra-ui/layout'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { ERoleOfUser } from 'constants/enum'
import SubTabItem from 'containers/Profile/components/SubTabItem'
import { useStores } from 'utils/hooks/useStores'
import { EContractProjectRatingSubTab } from '../../../constants'
import { EIdItemLink } from '../MenuDetail/MenuItem/constants'
import Section from '../Section'
import FixedProjectRating from './FixedProjectRating'
import HourlyProjectRating from './HourlyProjectRating'

const ProjectRating = () => {
  const { adminUserStore } = useStores()
  const { isLoadNewProjectRates } = adminUserStore
  const params = useParams()
  const userId = get(params, 'id', '')
  const isAdmin = window.localStorage.getItem('userRole') === ERoleOfUser.ADMIN
  const [currentTab, setCurrentTab] = useState<EContractProjectRatingSubTab>(
    EContractProjectRatingSubTab.HOURLY_PROJECT_RATING
  )
  useEffect(() => {
    if (isAdmin) {
      adminUserStore.getProjectRatings(userId)
    }
  }, [])
  useEffect(() => {
    if (isAdmin) {
      if (isLoadNewProjectRates) {
        adminUserStore.getProjectRatings(userId)
      }
    }
  }, [isLoadNewProjectRates])

  return (
    <Section title="Project Ratings" id={EIdItemLink.PROJECT_RATINGS}>
      <HStack
        padding={{ lg: '0px 0px', md: '0px 10px', sm: '0px 10px' }}
        spacing={{ base: 6, lg: 8 }}
        justify="space-between"
        backgroundColor="white"
        mx={8}
        borderRadius="8px">
        <HStack spacing={3} alignSelf="flex-start" flex={2} margin={'20px 0'}>
          <SubTabItem
            tab={EContractProjectRatingSubTab.HOURLY_PROJECT_RATING}
            isActive={currentTab === EContractProjectRatingSubTab.HOURLY_PROJECT_RATING}
            setCurrentTab={setCurrentTab}
          />
          <SubTabItem
            tab={EContractProjectRatingSubTab.FIXED_PROJECT_RATING}
            isActive={currentTab === EContractProjectRatingSubTab.FIXED_PROJECT_RATING}
            setCurrentTab={setCurrentTab}
          />
        </HStack>
      </HStack>
      <HStack
        padding={{ lg: '0px 0px', md: '5px', sm: '5px' }}
        flex={5}
        alignItems="flex-start"
        width={{ lg: 'auto', md: 'auto', sm: 'auto' }}
        justifyContent="center">
        {currentTab === EContractProjectRatingSubTab.HOURLY_PROJECT_RATING && <HourlyProjectRating />}
        {currentTab === EContractProjectRatingSubTab.FIXED_PROJECT_RATING && <FixedProjectRating />}
      </HStack>
    </Section>
  )
}

export default observer(ProjectRating)
