import AdminAnnouncementStore from './admin/announcementStore'
import AdminContractStore from './admin/contractStore'
import AdminCountryStore from './admin/countryStore'
import AdminCreatableOptionStore from './admin/creatableOptionStore'
import AdminCurrencyStore from './admin/currencyStore'
import AdminDashboardStore from './admin/dashboardStore'
import AdminDiagramStore from './admin/diagramStore'
import AdminInternalDocumentStore from './admin/internalDocumentStore'
import AdminLeaveRequestStore from './admin/leaveRequestStore'
import AdminLeaveTypeStore from './admin/leaveTypeStore'
import AdminPartnerStore from './admin/partnerStore'
import AdminPaymentStore from './admin/paymentStore'
import AdminPolicyStore from './admin/policyStore'
import AdminProjectStore from './admin/projectStore'
import AdminProjectTypeStore from './admin/projectTypeStore'
import AdminReportStore from './admin/reportStore'
import AdminTaskTypeStore from './admin/taskTypeStore'
import AdminTechnologyStore from './admin/technologyStore'
import AdminTimesheetHistoryStore from './admin/timesheetHistoryStore'
import AdminUserStore from './admin/userStore'
import AuthStore from './authStore'
import LayoutStore from './layoutStore'
import UserAnnouncementStore from './user/announcementStore'
import UserContractStore from './user/contractStore'
import UserHumanResourcesStore from './user/humanResourcesStore'
import UserLeaveRequestStore from './user/leaveRequestStore'
import LeaveTypeStore from './user/leaveTypeStore'
import UserPaymentStore from './user/paymentStore'
import UserPolicyStore from './user/policyStore'
import UserProfileStore from './user/profileStore'
import UserProjectStore from './user/projectStore'
import UserReportStore from './user/reportStore'
import UserStaffDocumentOfHRStore from './user/staffDocumentOfHRStore'
import UserTechnologyStore from './user/technologyStore'
import UserTimesheetStore from './user/timesheetStore'
import UserTodoOfHRStore from './user/todoOfHRStore'

class RootStore {
  // ADMIN stores
  adminPaymentStore: AdminPaymentStore
  adminUserStore: AdminUserStore
  adminProjectStore: AdminProjectStore
  adminContractStore: AdminContractStore
  adminPartnerStore: AdminPartnerStore
  adminDiagramStore: AdminDiagramStore
  adminTechnologyStore: AdminTechnologyStore
  adminProjectTypeStore: AdminProjectTypeStore
  adminTaskTypeStore: AdminTaskTypeStore
  adminCountryStore: AdminCountryStore
  adminReportStore: AdminReportStore
  adminCurrencyStore: AdminCurrencyStore
  adminCreatableOptionStore: AdminCreatableOptionStore
  adminDashboardStore: AdminDashboardStore
  adminPolicyStore: AdminPolicyStore
  adminLeaveRequestStore: AdminLeaveRequestStore
  adminAnnouncementStore: AdminAnnouncementStore
  adminLeaveTypeStore: AdminLeaveTypeStore
  adminTimesheetHistoryStore: AdminTimesheetHistoryStore
  adminInternalDocumentStore: AdminInternalDocumentStore
  adminTimesheetHistoryStore: AdminTimesheetHistoryStore
  // USER stores
  userPaymentStore: UserPaymentStore
  userProfileStore: UserProfileStore
  userProjectStore: UserProjectStore
  userContractStore: UserContractStore
  userTimesheetStore: UserTimesheetStore
  userReportStore: UserReportStore
  userPolicyStore: UserPolicyStore
  userAnnouncementStore: UserAnnouncementStore
  userTechnologyStore: UserTechnologyStore
  userLeaveRequestStore: UserLeaveRequestStore
  leaveTypeStore: LeaveTypeStore
  userTodoOfHRStore: UserTodoOfHRStore
  userHumanResourcesStore: UserHumanResourcesStore
  userStaffDocumentOfHRStore: UserStaffDocumentOfHRStore
  // Common stores
  authStore: AuthStore
  layoutStore: LayoutStore

  constructor() {
    // ADMIN stores
    this.adminPaymentStore = new AdminPaymentStore(this)
    this.adminUserStore = new AdminUserStore(this)
    this.adminProjectStore = new AdminProjectStore(this)
    this.adminContractStore = new AdminContractStore(this)
    this.adminPartnerStore = new AdminPartnerStore(this)
    this.adminDashboardStore = new AdminDashboardStore(this)
    this.adminDiagramStore = new AdminDiagramStore(this)
    this.adminTechnologyStore = new AdminTechnologyStore(this)
    this.adminProjectTypeStore = new AdminProjectTypeStore(this)
    this.adminTaskTypeStore = new AdminTaskTypeStore(this)
    this.adminCountryStore = new AdminCountryStore(this)
    this.adminReportStore = new AdminReportStore(this)
    this.adminCurrencyStore = new AdminCurrencyStore(this)
    this.adminCreatableOptionStore = new AdminCreatableOptionStore(this)
    this.adminPolicyStore = new AdminPolicyStore(this)
    this.adminLeaveRequestStore = new AdminLeaveRequestStore(this)
    this.adminAnnouncementStore = new AdminAnnouncementStore(this)
    this.adminLeaveTypeStore = new AdminLeaveTypeStore(this)
    this.adminTimesheetHistoryStore = new AdminTimesheetHistoryStore(this)
    this.adminInternalDocumentStore = new AdminInternalDocumentStore(this)
    this.adminTimesheetHistoryStore = new AdminTimesheetHistoryStore(this)
    // USER stores
    this.userPaymentStore = new UserPaymentStore(this)
    this.userProfileStore = new UserProfileStore(this)
    this.userProjectStore = new UserProjectStore(this)
    this.userContractStore = new UserContractStore(this)
    this.userTimesheetStore = new UserTimesheetStore(this)
    this.userReportStore = new UserReportStore(this)
    this.userPolicyStore = new UserPolicyStore(this)
    this.userAnnouncementStore = new UserAnnouncementStore(this)
    this.userTechnologyStore = new UserTechnologyStore(this)
    this.userLeaveRequestStore = new UserLeaveRequestStore(this)
    this.leaveTypeStore = new LeaveTypeStore(this)
    this.userTodoOfHRStore = new UserTodoOfHRStore(this)
    this.userHumanResourcesStore = new UserHumanResourcesStore(this)
    this.userStaffDocumentOfHRStore = new UserStaffDocumentOfHRStore(this)
    // Common stores
    this.authStore = new AuthStore(this)
    this.layoutStore = new LayoutStore(this)
  }
}

export default RootStore

export const rootStore = new RootStore()
