import { makeAutoObservable } from 'mobx'
import { frontendRoutes } from 'constants/routes'
import RootStore from './rootStore'

class LayoutStore {
  rootStore: RootStore
  pageTitle: string = ''
  subPageTitle: string = ''

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public setPageTitle(title: string): void {
    const isQRCodePage = title === 'Qr Code Generator'
    const pageTitle =
      title?.toLowerCase() === frontendRoutes.announcementPage.announcementAdmin.value.split('/')[1]
        ? 'Announcement'
        : isQRCodePage
        ? 'QR Code Generator'
        : title
    this.pageTitle = pageTitle
  }

  public setSubPageTitle(title: string): void {
    this.subPageTitle = title
  }
}

export default LayoutStore
