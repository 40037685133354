import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessAggregateResponse } from 'API/interface'
import {
  ILeaveRequest,
  IAdminLeaveRequestAggregatePayload,
  IUserLeaveRequestAggregatePayload,
  IUserLeaveRequestsGetByProjectsPayload,
  ILeaveRequestByProjects,
  IAdminLeaveRequestsGetByProjectsPayload,
} from 'types/leaveRequest'
import { ILeaveRequestFormData } from 'types/leaveRequest'

export async function userCreateLeaveRequestAPI(payload: ILeaveRequestFormData): Promise<void> {
  try {
    const url = APIRouters.leaveRequest.user.value
    return apiV2.post(url, payload)
  } catch (error) {
    throw error
  }
}

export async function userUpdateLeaveRequestAPI(id: string, payload: ILeaveRequestFormData): Promise<void> {
  try {
    await apiV2.put(APIRouters.leaveRequest.user.id.value(id), payload)
  } catch (error) {
    throw error
  }
}

export async function userDeleteLeaveRequestAPI(id: string): Promise<string> {
  try {
    const url = APIRouters.leaveRequest.user.id.value(id)
    const response = await apiV2.delete(url)
    return response?.data?.message
  } catch (error) {
    throw error
  }
}

export async function userAggregateLeaveRequestAPI(
  payload: IUserLeaveRequestAggregatePayload
): Promise<ISuccessAggregateResponse<ILeaveRequest[]>> {
  try {
    const url = APIRouters.leaveRequest.user.aggregate.value
    const response = await apiV2.post(url, payload)
    return response?.data
  } catch (error) {
    throw error
  }
}

export async function userGetLeaveRequestsByProjectsAPI(
  payload: IUserLeaveRequestsGetByProjectsPayload
): Promise<ISuccessAggregateResponse<ILeaveRequestByProjects[]>> {
  try {
    const url = APIRouters.leaveRequest.user.projects.value
    const response = await apiV2.post(url, payload)
    return response?.data
  } catch (error) {
    throw error
  }
}

export async function adminGetLeaveRequestsByProjectsAPI(
  payload: IAdminLeaveRequestsGetByProjectsPayload
): Promise<ISuccessAggregateResponse<ILeaveRequestByProjects[]>> {
  try {
    const url = APIRouters.leaveRequest.admin.projects.value
    const response = await apiV2.post(url, payload)
    return response?.data
  } catch (error) {
    throw error
  }
}

export async function adminAggregateLeaveRequestAPI(
  payload: IAdminLeaveRequestAggregatePayload
): Promise<ISuccessAggregateResponse<ILeaveRequest[]>> {
  try {
    const url = APIRouters.leaveRequest.admin.aggregate.value
    const response = await apiV2.post(url, payload)
    return response?.data
  } catch (error) {
    throw error
  }
}

export async function adminReviewLeaveRequestAPI(id: string, payload: Pick<ILeaveRequest, 'status'>): Promise<void> {
  try {
    const url = APIRouters.leaveRequest.admin.review.id.value(id)
    return apiV2.put(url, payload)
  } catch (error) {
    throw error
  }
}

export async function adminCommentLeaveRequestAPI(id: string, payload: Pick<ILeaveRequest, 'comment'>): Promise<void> {
  try {
    const url = APIRouters.leaveRequest.admin.comment.id.value(id)
    return apiV2.put(url, payload)
  } catch (error) {
    throw error
  }
}
