import { ICountry } from 'API/country/constants'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { ITechnology } from 'types/technology'
import { IProject } from 'constants/schema'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedDateWith_MMMDDYYYY } from 'utils/dateFormatUtils'
import { getFormattedDateTimeInEnglish } from 'utils/dateFormatUtils'
import { headerFileExcel } from '../constant'

export function getProjectTechnologies(listTechnologyId: string[], technologiesList: ITechnology[]): ITechnology[] {
  const projectTechnologies: ITechnology[] = getValidArray(listTechnologyId).map((technologyId: string) => {
    const validItem: ITechnology = technologiesList.find((technology) => {
      return technology.id === technologyId
    })
    return validItem
  })
  return projectTechnologies
}

export function combineStartedDateEndedDate(startedDate: Date, endedDate: Date, isUntilNow: boolean): string {
  return isUntilNow === true
    ? getFormattedDateWith_MMMDDYYYY(startedDate ?? '') + ' - Now'
    : getFormattedDateWith_MMMDDYYYY(startedDate ?? '') + ' - ' + getFormattedDateWith_MMMDDYYYY(endedDate ?? '')
}

export async function createFileExcel(filteredProjectList: IProject[], countryList: ICountry[]): Promise<void> {
  const projectsWithFormattedCountry = getValidArray(filteredProjectList).map((project) => {
    if (project.country) {
      const countryItem = countryList.find((item) => item.id === project.country)
      if (countryItem) {
        project.country = countryItem.value
      }
    }
    return project
  })
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Projects')

  headerFileExcel.forEach((headerRow, index) => {
    const row = worksheet.addRow(headerRow)
    row.eachCell((cell, colNumber) => {
      cell.font = { bold: true }
      cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFF99' },
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }

      if (index === 0) {
        if (colNumber === 1) worksheet.mergeCells(1, 1, 1, 7)
        if (colNumber === 8) worksheet.mergeCells(1, 8, 1, 9)
      }
    })
  })

  projectsWithFormattedCountry.forEach((item) => {
    const rowData = [
      item.name || 'N/A',
      item.isActive ? 'Active' : item.isArchived ? 'Archived' : 'Deleted',
      item.companyName || 'N/A',
      item.companyWebsite || 'N/A',
      item.companyPhone || 'N/A',
      item.country || 'N/A',
      item.address || 'N/A',
      item.customer || 'N/A',
      item.customerEmail || 'N/A',
      item.customerPhone || 'N/A',
    ]
    const row = worksheet.addRow(rowData)

    row.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'left', wrapText: true }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
    })
  })

  worksheet.columns.forEach((column) => {
    let maxLength = 0
    column.eachCell({ includeEmpty: true }, (cell) => {
      const cellLength = cell.value ? cell.value.toString().length : 0
      if (cellLength > maxLength) maxLength = cellLength
    })
    column.width = maxLength > 50 ? 50 : Math.max(maxLength, 10)
  })

  const fileName = `Projects_${getFormattedDateTimeInEnglish(new Date())}.xlsx`

  const buffer = await workbook.xlsx.writeBuffer()
  saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), fileName)
}
