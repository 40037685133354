import { useEffect, useState } from 'react'
import { VStack, HStack, Box } from '@chakra-ui/react'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import { set } from 'lodash'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { IPagination } from 'types/table'
import { EConditionGetList, EDashboardSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { limitItemPerPage } from 'constants/index'
import { useStores } from 'utils/hooks/useStores'
import SkillContentForAdmin from '../../components/SkillContentForAdmin'
import SkillFilterForm from '../../components/SkillFilterForm'
import { ISkillFilterFormData, ISkillSummaryFilter } from '../../constant'

const Skill = () => {
  const history = useHistory()
  const location = useLocation()
  const { layoutStore } = useStores()
  const { adminProjectStore, adminDashboardStore, adminUserStore } = useStores()
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const { projectNameList } = adminProjectStore
  const { isLoadingSkill, haveData, countTotalSkill } = adminDashboardStore
  const { creatableOptionList } = adminUserStore
  const methods = useForm<ISkillFilterFormData>({})

  const [filter, setFilter] = useState<ISkillSummaryFilter>({
    page: 1,
    perPage: limitItemPerPage,
  })

  function submitFilterHandler(data: ISkillFilterFormData) {
    const { selectedProject, selectedLevel, selectedTitle } = data
    const formatedData = {
      page: 1,
    }
    if (selectedProject) {
      set(formatedData, 'project', selectedProject.value)
    }
    if (selectedLevel) {
      set(formatedData, 'level', selectedLevel.value)
    }
    if (selectedTitle) {
      set(formatedData, 'title', selectedTitle.value)
    }
    history.push({ search: queryString.stringify(formatedData) })
  }

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(EDashboardSubTitle.SKILL)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  useEffect(() => {
    const queryStringOnUrl = location.search
    if (queryStringOnUrl) {
      const parsedQueryString: ISkillSummaryFilter = queryString.parse(queryStringOnUrl, { parseNumbers: true })
      setFilter(parsedQueryString)
    } else {
      setFilter({
        page: 1,
      })
    }
  }, [location])

  useEffect(() => {
    adminDashboardStore.getAdminSkillSummaryData(filter)
  }, [filter])

  const pagination: IPagination = {
    includePagination: true,
    pageIndex: filter.page,
    pageSize: limitItemPerPage,
    tableLength: countTotalSkill,
    gotoPage: (page: number) => {
      history.push({ search: queryString.stringify({ ...filter, page }) })
    },
  }

  useEffect(() => {
    adminProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
    adminUserStore.getOptionList()
  }, [])

  useEffect(() => {
    if (haveData) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])
  return (
    <VStack width={'full'}>
      <FormProvider {...methods}>
        <SkillFilterForm
          filter={filter}
          creatableOptionList={creatableOptionList}
          isLoading={isLoadingSkill}
          projectNameList={projectNameList}
          submitHandler={submitFilterHandler}
        />
        <LoadingChakra isLoading={isLoadingSkill}>
          <Box width="inherit" background="white">
            {isValidAnalysis && <SkillContentForAdmin pagination={pagination} />}
            {!isValidAnalysis && (
              <HStack width="full" background="white" minHeight={'80vh'}>
                <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
              </HStack>
            )}
          </Box>
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(Skill)
