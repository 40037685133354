import React, { useEffect, useState } from 'react'
import { VStack, HStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import EmptyContentBox from 'components/EmptyContentBox'
import LoadingChakra from 'components/LoadingChakra'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import { EConditionGetList, EDashboardSubTitle } from 'constants/enum'
import { Messages } from 'constants/index'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { useStores } from 'utils/hooks/useStores'
import StatisticContentForAdmin from '../../components/StatisticContentForAdmin'
import StatisticFilterFormForAdmin from '../../components/StatisticFilterFormForAdmin'
import { IOption, IStatisticFilterFormData, workingPeriodOptions } from '../../constant'
import { IStatisticFilter } from './interface'

const Statistic = () => {
  const { layoutStore } = useStores()
  const history = useHistory()
  const { adminProjectStore, adminDashboardStore } = useStores()
  const [isValidAnalysis, setIsValidAnalysis] = useState(false)
  const { projectNameList } = adminProjectStore
  const { isLoadingStatistic, haveData } = adminDashboardStore

  const projectOptions: IOption[] = createOptionsOfReactSelectFromDB(projectNameList)

  const methods = useForm<IStatisticFilterFormData>({
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      period: workingPeriodOptions[1],
      date: !Boolean(JSON.parse(localStorage.getItem('isPickPastMonth')))
        ? dayjs().toDate()
        : dayjs().subtract(1, 'month').endOf('month').toDate(),
    },
  })

  const { getValues, setValue } = methods
  const { selectedProject } = getValues()

  const queryStringInURL = history.location.search
  const parseQueryString = queryString.parse(queryStringInURL)
  const initialFilter: IStatisticFilter = {}
  if (queryStringInURL) {
    initialFilter.project = String(parseQueryString?.project)
    initialFilter.period = String(parseQueryString?.period)
    initialFilter.date = String(parseQueryString?.date)
  }

  const [filter, setFilter] = useState<IStatisticFilter>(initialFilter ?? {})

  function getValidationSchema() {
    const schema = yup.object({
      selectedProject: yup.object().required(Messages.fieldRequired),
    })
    return schema
  }

  function viewAnalyticHandler(data: IStatisticFilterFormData) {
    const { selectedProject, period, date } = data
    setFilter({
      project: selectedProject.value,
      period: period.value,
      date: dayjs(date).format('YYYY-MM-DD'),
    })
  }

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(EDashboardSubTitle.STATISTIC)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  useEffect(() => {
    if (filter.project) {
      const selectedProjectOption = projectOptions.find((option) => option.value === filter.project)
      setValue('selectedProject', selectedProjectOption)
      history.push({
        search: queryString.stringify(filter),
      })
      adminDashboardStore.getAdminStatisticData(filter)
    }
  }, [filter, projectNameList])

  useEffect(() => {
    adminProjectStore.getAllNameProjectList(EConditionGetList.ACTIVE)
  }, [])

  useEffect(() => {
    if (haveData && selectedProject) {
      setIsValidAnalysis(true)
    } else {
      setIsValidAnalysis(false)
    }
  }, [haveData])
  return (
    <VStack width={'full'}>
      <FormProvider {...methods}>
        <StatisticFilterFormForAdmin
          isLoading={isLoadingStatistic}
          projectOptions={projectOptions}
          viewAnalyticHandler={viewAnalyticHandler}
        />
        <LoadingChakra isLoading={isLoadingStatistic}>
          {isValidAnalysis && <StatisticContentForAdmin />}
          {!isValidAnalysis && (
            <HStack width="full" background="white" minHeight={'80vh'}>
              <EmptyContentBox text={Messages.emptyBoxFilterMessage} position="bottom" />
            </HStack>
          )}
        </LoadingChakra>
      </FormProvider>
    </VStack>
  )
}

export default observer(Statistic)
