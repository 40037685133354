import { useEffect, useState } from 'react'
import { HStack, FormControl } from '@chakra-ui/react'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import MultipleSelect from 'components/MultipleSelect'
import dayjs from 'dayjs'
import { useFormContext, Controller } from 'react-hook-form'
import { IOption } from 'constants/common'
import { isValidArray } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { generateProjectOptions } from 'utils/user'
import { workingPeriodOptions } from '../../constants'
import { IOverviewFilterFormProps } from '../../constants'

const OverviewFilterForm = (props: IOverviewFilterFormProps) => {
  const { projectNameList, userNameList, viewAnalyticHandler, isAnalysisLoading } = props
  const { control, setValue, handleSubmit, getValues } = useFormContext()
  const [isValidForm, setIsValidForm] = useState(false)
  const formValues = getValues()
  const { selectedProject, selectedUser } = formValues
  let newUsers = []
  if (selectedProject) {
    newUsers = userNameList.filter((user) => {
      const projectIds = user?.projects
      return projectIds.includes(selectedProject.value)
    })
  } else {
    newUsers = userNameList?.map((item) => item)
  }

  const projectOptions: IOption[] = generateProjectOptions(projectNameList)
  const userOptions: IOption[] = [...createOptionsOfReactSelectFromDB(newUsers)]
  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }

  function resetDependentField(value: IOption | null) {
    setValue('selectedProject', value)
    setValue('selectedUser', [])
  }

  useEffect(() => {
    const isValidSelectedUser = isValidArray(selectedUser)
    const isValid = selectedProject && isValidSelectedUser
    setIsValidForm(isValid)
  }, [formValues])

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }} paddingY={'16px'} borderRadius="sm">
      <HStack width="inherit" paddingLeft="16px">
        <InputDropdown
          name="selectedProject"
          placeHolder="Select Projects"
          optionsData={projectOptions}
          isClearable={false}
          onChangeValue={resetDependentField}
        />
        <MultipleSelect
          name="selectedUser"
          placeholder="Select Users"
          options={userOptions}
          selectedEntityName="Users"
          paramKey="user"
        />
        <InputDropdown
          name="period"
          placeHolder="Select Period"
          optionsData={workingPeriodOptions}
          isClearable={false}
        />
        <FormControl background="white">
          <Controller
            name="date"
            control={control}
            render={({ field: { value } }) => (
              <DatePicker
                selectedDate={value}
                onChange={pickDate}
                style={{
                  border: '1px solid #4D5DFB',
                }}
              />
            )}
          />
        </FormControl>
      </HStack>
      <HStack paddingRight="16px">
        <Button
          height="44px"
          rightIcon="arrow-right-line-white.svg"
          isLoading={isAnalysisLoading}
          disabled={!isValidForm || isAnalysisLoading}
          onClick={handleSubmit(viewAnalyticHandler)}>
          View Analytics
        </Button>
      </HStack>
    </HStack>
  )
}

export default OverviewFilterForm
