import React from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { HStack, VStack, Text, Box, Link, Heading } from '@chakra-ui/react'
import { IReportData } from 'API/report/constants'
import Icon from 'components/Icon'
import SummaryFilterInfoBox from 'components/SummaryFilterInfoBox'
import Table from 'components/Table/CustomExpandedTable'
import HighlightTable from 'components/Table/HighlightTable'
import { observer } from 'mobx-react'
import ReactQuill from 'react-quill'
import { textGrey700 } from 'theme/globalStyles'
import { Messages } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import { getFormattedTimeDate } from 'utils/dateFormatUtils'
import { getFormattedNumber } from 'utils/numberUtils'
import { handleUpdateDescription, calculateLateLoggingTime, formatDurationRow } from '../../../utils'

interface IUserReportSectionProps {
  reportData: IReportData
}

const UserReportSection = (props: IUserReportSectionProps) => {
  const { reportData } = props
  const { timesheetList, highlightData, developerName, startDateOfFilter, endDateOfFilter } = reportData
  const expandedRowObj = {
    expanded: {},
  }
  function getUserReportHeaderList() {
    const headers = [
      {
        Header: 'TASK NAME',
        accessor: 'taskName',
        // id: "expander",
        Cell: ({ row }) => <span {...row.getToggleRowExpandedProps()}>{row.original.taskName}</span>,
      },
      {
        Header: 'HOURLY RATE',
        accessor: 'defaultRating',
      },
      {
        Header: 'START TIME',
        accessor: 'startTime',
      },
      {
        Header: 'END TIME',
        accessor: 'endTime',
      },
      {
        Header: 'DURATION (HRS)',
        accessor: 'duration',
        Cell: ({ row }) => (
          <span className={`duration ${row.original?.durationStatus}`} {...row.getToggleRowExpandedProps()}>
            {row.original?.duration}
          </span>
        ),
      },
      {
        Header: 'SALARY',
        accessor: 'salary',
      },
      {
        Header: 'LATE LOGGING TIME',
        accessor: 'lateLoggingTime',
        Cell: ({ row }) => (
          <span
            className={`late-logging-time-status ${row.original?.lateLoggingTimeStatus}`}
            {...row.getToggleRowExpandedProps()}>
            {row.original?.lateLoggingTime}
          </span>
        ),
      },
    ]
    return headers
  }
  const tableData = getValidArray(timesheetList).map((timesheet) => {
    const lateLoggingTimeData = calculateLateLoggingTime(timesheet)
    const durationStatusData = formatDurationRow(timesheet.workingTime)
    return {
      ...timesheet,
      taskTypes: timesheet.taskTypesName.map((taskType) => taskType).join(', '),
      duration: getFormattedNumber(timesheet.workingTime, 1),
      durationStatus: durationStatusData,
      salary: getFormattedNumber(timesheet.salary, 1),
      startTime: getFormattedTimeDate(timesheet.startTime),
      endTime: getFormattedTimeDate(timesheet.endTime),
      lateLoggingTime: lateLoggingTimeData.delayDate,
      lateLoggingTimeStatus: lateLoggingTimeData.status,
    }
  })

  const subTable = React.useCallback(({ row }) => {
    return (
      <Box backgroundColor={'blue.50'} padding={'16px'} width={'inherit'}>
        <Text fontWeight={500} marginTop={0}>
          Description
        </Text>
        <ReactQuill
          className="task-description"
          value={handleUpdateDescription(row.original.description)}
          theme="bubble"
          readOnly
        />
        {row.original.taskLink && (
          <Box>
            <Link href={row.original.taskLink} isExternal>
              Task link <ExternalLinkIcon mx="2px" />
            </Link>
          </Box>
        )}
        {row.original.taskTypes && (
          <Box>
            <Text fontWeight={500} marginTop={0}>
              Task Types
            </Text>
            {row.original.taskTypes}
          </Box>
        )}
      </Box>
    )
  }, [])

  return (
    <HStack width="inherit" background="white" margin={{ base: 2, lg: '16px' }}>
      <VStack width="-webkit-fill-available" padding={'16px'} alignItems={'flex-start'}>
        <SummaryFilterInfoBox
          developerName={developerName}
          projectName={highlightData.projectName}
          startDate={startDateOfFilter}
          endDate={endDateOfFilter}
        />
        <Box maxWidth={'830px'}>
          <Heading as="h3" color={textGrey700} fontSize={18} fontWeight={500} mt={0} mb={'16px'}>
            Summary
          </Heading>
          <HighlightTable data={highlightData} />
        </Box>
        <Box width={'inherit'}>
          <Heading as="h3" color={textGrey700} fontSize={18} fontWeight={500}>
            Timesheet List
          </Heading>
          <HStack mb={4}>
            <Icon iconName="information-blue-fill-circle.svg" size={20} />{' '}
            <Text> {Messages.pressToExpandTimesheetDetailGuide} </Text>
          </HStack>
          <VStack width={'inherit'} maxHeight={'500px'} overflowY={'scroll'}>
            <Table
              tableData={tableData}
              headerList={getUserReportHeaderList()}
              subComponent={subTable}
              expandRows={true}
              expandedRowObj={[expandedRowObj]}
            />
          </VStack>
        </Box>
      </VStack>
    </HStack>
  )
}

export default observer(UserReportSection)
