import { useEffect, useMemo, useState } from 'react'
import { HStack, SimpleGrid, Stack, Text, useMediaQuery, VStack } from '@chakra-ui/react'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import { EModalType } from 'components/ConfirmModal/enums'
import LabelForm from 'components/LabelForm'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import dayjs from 'dayjs'
import { History } from 'history'
import { set } from 'lodash'
import { observer } from 'mobx-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { IRowActionItem } from 'types/table'
import { frontendRoutes } from 'constants/routes'
import { IInternalDocument } from 'constants/schema'
import { useStores } from 'utils/hooks/useStores'
import { actionOnDataTable, Messages, TitlePages } from '../../../../constants/index'
import styles from '../index.module.scss'

const InternalDocumentDetail = ({ onClick, defaultValues }) => {
  const title = 'Document Detail'
  const { layoutStore } = useStores()
  const history: History = useHistory()
  const [isTablet] = useMediaQuery('(max-width: 820px)')
  const { adminInternalDocumentStore } = useStores()
  const { isLoadingInternalDocumentList } = adminInternalDocumentStore
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [selectedInternalDocument, setSelectedInternalDocument] = useState<IInternalDocument | null>(null)
  const [isOpenArchiveModal, setIsOpenArchiveModal] = useState<boolean>(false)
  const [isOpenUnarchivedModal, setIsOpenUnarchivedModal] = useState<boolean>(false)
  const methods = useForm({
    defaultValues,
  })
  const { handleSubmit, reset } = methods

  const actions: IRowActionItem[] = [
    {
      name: !!defaultValues?.isArchived ? 'Unarchive' : 'Archive',
      handler: () => callOpenDialogConfirm(defaultValues, actionOnDataTable.ARCHIVE),
    },
  ]
  actions.push({
    name: 'Delete',
    customClassNames: ['isDanger'],
    handler: () => {
      callOpenDialogConfirm(defaultValues, actionOnDataTable.DELETE)
    },
  })

  function toggleOpenDeleteModal() {
    console.log(selectedInternalDocument)
    setIsOpenDeleteModal(!isOpenDeleteModal)
  }
  function toggleOpenArchiveModal() {
    setIsOpenArchiveModal(!isOpenArchiveModal)
  }
  function toggleOpenUnarchivedModal() {
    setIsOpenUnarchivedModal(!isOpenUnarchivedModal)
  }
  function callOpenDialogConfirm(internalDocument: IInternalDocument, nameOfEvent: string) {
    set(internalDocument, 'shortName', internalDocument?.number ?? '')

    switch (nameOfEvent) {
      case actionOnDataTable.DELETE:
        setSelectedInternalDocument(internalDocument)
        toggleOpenDeleteModal()
        break
      case actionOnDataTable.ARCHIVE:
        setSelectedInternalDocument(internalDocument)
        if (internalDocument?.isArchived) {
          toggleOpenUnarchivedModal()
          break
        }
        toggleOpenArchiveModal()
        break
      case actionOnDataTable.UNARCHIVED:
        setSelectedInternalDocument(internalDocument)
        toggleOpenUnarchivedModal()
        break
      default:
        toast.error('Action not found')
    }
  }

  async function handleClickOKConfirmedDelete(id: string) {
    await adminInternalDocumentStore.deleteInternalDocument(id)
    toggleOpenDeleteModal()
    history.push(frontendRoutes.internalDocument.list.value)
    toast.success(Messages.deleteInternalDocumentSuccess)
  }
  const formattedDefaultValues = useMemo(
    () => ({
      ...defaultValues,
      startDate: defaultValues.startDate ? dayjs(defaultValues.startDate).format('DD/MM/YYYY') : '',
      endDate: defaultValues.endDate ? dayjs(defaultValues.endDate).format('DD/MM/YYYY') : '',
      publishDate: defaultValues.publishDate ? dayjs(defaultValues.publishDate).format('DD/MM/YYYY') : '',
      typeId: defaultValues.typeId ? defaultValues.typeId.label || defaultValues.typeId.value : '',
      partnerId: defaultValues.partnerId ? defaultValues.partnerId.label || defaultValues.partnerId.value : '',
    }),
    [defaultValues]
  )
  useEffect(() => {
    reset(formattedDefaultValues)
  }, [formattedDefaultValues, reset])

  useEffect(() => {
    function setSubPageTitle() {
      layoutStore.setSubPageTitle(TitlePages.INTERNAL_DOCUMENT_DETAIL)
    }
    setSubPageTitle()
    return function cleanup() {
      layoutStore.setSubPageTitle('')
    }
  }, [layoutStore])

  return (
    <FormProvider {...methods}>
      <form onSubmit={() => {}}>
        <VStack p={6} paddingInline={{ base: 6, lg: 6 }} paddingStart={{ base: '27px' }} spacing="30px">
          <Stack
            direction={{ xl: 'row', lg: 'row', md: 'row', sm: 'column' }}
            className={styles.formHeaderSection}
            padding={{ base: 4, lg: 4 }}>
            <Text fontSize={'lg'} color="gray.700" margin={0} alignSelf="center">
              {defaultValues.number}
            </Text>
            <HStack>
              <Button variant="solid" onClick={onClick} leftIcon="edit-white.svg" leftIconSize={16}>
                {!isTablet ? 'Edit Document' : ''}
              </Button>
              <MoreDropdown isBackground actions={actions} />
            </HStack>
          </Stack>
          <VStack
            background="white"
            padding={{ base: 6, lg: 6 }}
            width="full"
            borderRadius="8px"
            marginTop="24px !important">
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
              <LabelForm name="number" label="Doc’s number*" readonly={true} />
              <LabelForm name="scope" label="Scope*" readonly={true} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
              <LabelForm name="typeId" label="Document Type" readonly={true} />
              <LabelForm name="status" label="Status" readonly={true} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
              <LabelForm name="startDate" label="Start Date" type="date" readonly={true} />
              <LabelForm name="endDate" label="End Date" type="date" readonly={true} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
              <LabelForm name="description" label="Description" readonly={true} />
              <LabelForm name="amount" label="Amount" type="number" readonly={true} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
              <LabelForm name="storageURL" label="Storage URL" readonly={true} isHighligh />
              <LabelForm name="publishDate" label="Publish Date" type="date" readonly={true} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
              <LabelForm name="equipmentInfo" label="Equipment Information" readonly={true} />
              <LabelForm name="invoiceInfo" label="Lookup E-invoice" type="date" readonly={true} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
              <LabelForm name="result" label="Result" readonly={true} />
              <LabelForm name="note" label="Note" readonly={true} />
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={8} width="full">
              <LabelForm name="partnerId" label="Belong to Partner*" readonly={true} isHighligh />
            </SimpleGrid>
          </VStack>
        </VStack>
      </form>
      <ConfirmModal
        isLoading={isLoadingInternalDocumentList}
        data={selectedInternalDocument}
        isOpen={isOpenDeleteModal}
        closeHandler={toggleOpenDeleteModal}
        OKClickHandler={handleClickOKConfirmedDelete}
        title={'Internal Documents'}
        content={'Number'}
        type={EModalType.DELETE}
      />
    </FormProvider>
  )
}
export default observer(InternalDocumentDetail)
