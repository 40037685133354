import { useEffect, useState } from 'react'
import { HStack, FormControl, Box, Text } from '@chakra-ui/react'
import { ReactComponent as HeatmapIcon } from 'assets/images/svg/heatmap.svg'
import { ReactComponent as WallchartIcon } from 'assets/images/svg/wallchart.svg'
import Button from 'components/Button'
import DatePicker from 'components/DatePicker'
import InputDropdown from 'components/Dropdown/InputDropdown'
import MultipleSelect from 'components/MultipleSelect'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useFormContext, Controller } from 'react-hook-form'
import {
  backgroundGrey100,
  backgroundGrey200,
  borderSecondary,
  textGrey600,
  textSecondary,
  textWhite,
  transparent,
} from 'theme/globalStyles'
import { IOption } from 'constants/common'
import { isValidArray } from 'utils/commonUtils'
import { createOptionsOfReactSelectFromDB } from 'utils/dropdownOptionUtils'
import { ECalendarViewMode } from '../../enums'
import { workingPeriodOptions } from '../../interfaces'
import { ICalendarFilterFormProps } from '../../interfaces'

const LeaveCalendarFilterForm = (props: ICalendarFilterFormProps) => {
  const [activeView, setActiveView] = useState(ECalendarViewMode.WALLCHART)
  const { projectNameList, viewCalendarHandler, isLoading } = props
  const { control, setValue, handleSubmit, getValues } = useFormContext()
  const [isValidForm, setIsValidForm] = useState(false)
  const formValues = getValues()
  const { selectedProject } = formValues
  const projectOptions: IOption[] = [...(createOptionsOfReactSelectFromDB(projectNameList) || [])]

  function pickDate(date: Date): void {
    setValue('date', date)
    localStorage.setItem('isPickPastMonth', String(dayjs(date).month() < dayjs().month()))
  }

  useEffect(() => {
    function validateFormByProject() {
      const isValidSelectedProject = isValidArray(selectedProject)
      setIsValidForm(isValidSelectedProject)
    }
    validateFormByProject()
  }, [formValues])

  return (
    <HStack width="inherit" background={backgroundGrey100} margin={'0 0 12px'}>
      <HStack className="calender-filter-form" width="inherit" justifyContent="space-between">
        <HStack spacing={0} bg={backgroundGrey200} borderRadius="full" p="4px">
          <Box
            onClick={() => setActiveView(ECalendarViewMode.WALLCHART)}
            backgroundColor={activeView === ECalendarViewMode.WALLCHART ? textSecondary : transparent}
            color={activeView === ECalendarViewMode.WALLCHART ? textWhite : textGrey600}
            cursor="pointer"
            borderRadius="full"
            px={3.5}
            py={1.5}
            fontWeight={500}>
            <HStack>
              <WallchartIcon color={activeView === ECalendarViewMode.WALLCHART ? textWhite : textGrey600} />
              <Text>Wallchart</Text>
            </HStack>
          </Box>
          <Box
            // TODO: add heatmap view: implement this feature in the future
            // onClick={() => setActiveView(ECalendarViewMode.HEATMAP)}
            backgroundColor={activeView === ECalendarViewMode.HEATMAP ? textSecondary : transparent}
            color={activeView === ECalendarViewMode.HEATMAP ? textWhite : textGrey600}
            cursor="pointer"
            borderRadius="full"
            px={3.5}
            py={1.5}
            fontWeight={500}>
            <HStack>
              <HeatmapIcon color={activeView === ECalendarViewMode.HEATMAP ? textWhite : textGrey600} />
              <Text>Heatmap</Text>
            </HStack>
          </Box>
        </HStack>
        <HStack>
          <MultipleSelect
            name="selectedProject"
            placeholder="Project"
            options={projectOptions}
            selectedEntityName="Projects"
          />
          <InputDropdown
            name="period"
            placeHolder="Period"
            optionsData={workingPeriodOptions}
            isClearable={false}
            borderColor={borderSecondary}
          />
          <FormControl background="white">
            <Controller
              name="date"
              control={control}
              render={({ field: { value } }) => (
                <DatePicker
                  selectedDate={value}
                  onChange={pickDate}
                  style={{
                    borderColor: borderSecondary,
                    minWidth: '100px',
                  }}
                />
              )}
            />
          </FormControl>
          <Button
            variant="solid"
            isLoading={isLoading}
            customStyles={{ minWidth: '100px' }}
            rightIcon="arrow-right-line-white.svg"
            disabled={!isValidForm || isLoading}
            onClick={handleSubmit(viewCalendarHandler)}>
            View
          </Button>
        </HStack>
      </HStack>
    </HStack>
  )
}

export default observer(LeaveCalendarFilterForm)
